import React from "react";
import { Form, Input } from "antd";
import { UserOutlined } from "@ant-design/icons";

const OperatorFirstName = (props) => {
  // -------------------------- VAR --------------------------
  const { disabled } = props;
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- EFFECT --------------------------
  // -------------------------- FUNCTION --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      name="firstName"
      rules={[
        {
          required: true,
          message: "Please input first name",
        },
        {
          whitespace: true,
          message: "Invalid",
        },
        {
          pattern: /^[A-Za-z]+$/,
          message: "Alphabets only",
        },
        {
          max: 32,
          message: "Max length must be 32 characters",
        },
      ]}
    >
      <Input
        showCount
        maxLength={32}
        prefix={<UserOutlined className="form-item-icon" />}
        placeholder="First Name"
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default OperatorFirstName;
