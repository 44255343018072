export const TYPES = {
  LOADING: "client/LOADING",
  CLIENT_LIST_SUCCESS: "client/CLIENT_LIST_SUCCESS",
  CLIENT_LIST_SUCCESS_BY_ID: "client/CLIENT_LIST_SUCCESS_BY_ID",
  GET_PERMISSIONS_SUCCESS: "client/GET_PERMISSIONS_SUCCESS",
  GET_CLIENT_SUCCESS: "client/GET_CLIENT_SUCCESS",
  SELECT_CLIENT_SUCCESS: "client/SELECT_CLIENT_SUCCESS",
  SELECT_CLIENT_ADD: "client/SELECT_CLIENT_ADD",
  CLIENT_ADD_SUCCESS: "client/CLIENT_ADD_SUCCESS",
};
