import React from "react";
//outsource
import dayjs from "dayjs";
import { Row, Col, DatePicker } from "antd";
//components

const DatePickerMoment = ({ value, ...props }) => {
  // -------------------------- VAR ----------------------------
  const date = value ? dayjs(value?.toDate()) : null;
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX -------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  // -------------------------- MAIN ---------------------------
  return <DatePicker {...props} value={date} />;
};
export default DatePickerMoment;
