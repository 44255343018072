import { getAuth, signOut } from "firebase/auth";
import {
  collection,
  where,
  getFirestore,
  getDocs,
  query,
  onSnapshot,
  setDoc,
  serverTimestamp,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { app } from "../../config/config";
import { TYPES } from "./history.type";
import { REF } from "../ref";
import { FULL_PERMISSION } from "../string";
import { MASTER_EMAIL } from "../../config/config";

const firestore = getFirestore(app);
const auth = getAuth(app);

// -------------------------- Snap List  --------------------------
const subCommissionHistory = [];
export const snapCommissionHistory = () => (dispatch) => {
  const uid = auth?.currentUser?.uid;
  const email = auth?.currentUser?.email;
  const cRef = collection(firestore, REF.COMMISSION_HISTORY);
  const dRef = doc(cRef, uid);
  if (email === MASTER_EMAIL) {
    const data = {
      firstName: "Master",
      lastName: "Account",
      permissions: FULL_PERMISSION,
      active: true,
    };
    getCommissionHistoryDataSuccess(dispatch, data);
    // getPermissionsSuccess(dispatch, data);
    return;
  }
  const unSub = onSnapshot(dRef, (snapshot) => {
    if (snapshot) {
      const data = snapshot.data();
      if (data === undefined) {
        signOut(auth);
      } else {
        getCommissionHistoryDataSuccess(dispatch, data);
        // getPermissionsSuccess(dispatch, data);
      }
    }
  });
  subCommissionHistory.push(unSub);
};

export const unSnapCommissionHistory = () => (dispatch) => {
  subCommissionHistory.forEach((subscriber) => {
    subscriber();
  });
  subCommissionHistory.length = 0;
};

export const resetCommissionHistory = (callback) => (dispatch) => {
  getCommissionHistoryDataSuccess(dispatch, null);
};
// -------------------------- Operation List  --------------------------
// Lấy dữ liệu commission history
export const firestoreGetCommissionHistoryList = (callback) => (dispatch) => {
  const cRef = collection(firestore, REF.COMMISSION_HISTORY);
  getDocs(cRef).then((snapshot) => {
    if (snapshot) {
      const data = snapshot.docs.map((doc) => doc.data());
      console.log("commission history", data);
      getCommissionHistoryListSuccess(dispatch, data);
    }
    if (callback) {
      callback();
    }
  });
};

// Theo dõi dữ liệu commission history
const subList = [];
export const snapCommissionHistoryList = (callback) => (dispatch) => {
  const cRef = collection(firestore, REF.COMMISSION_HISTORY);
  // const c1 = where("role", "==", "operator");
  // const qRef = query(cRef, c1);
  const unSub = onSnapshot(cRef, (snapshot) => {
    if (snapshot) {
      const data = snapshot.docs.map((doc) => doc.data());
      getCommissionHistoryListSuccess(dispatch, data);
      if (callback) {
        callback();
      }
    }
  });
  subList.push(unSub);
};

export const unSnapCommissionHistoryList = () => (dispatch) => {
  subList.forEach((subscriber) => {
    subscriber();
  });
  subList.length = 0;
};

// -------------------------- Operation Add  --------------------------

export const addCommissionHistory =
  ({ commissionHistory }, callback) =>
  (dispatch) => {
    console.log({ commissionHistory });
    // const email = commissionHistory?.email;
    // const clientId = commissionHistory?.clientId;
    const cRef = collection(firestore, REF.COMMISSION_HISTORY);
    // const c1 = where("role", "==", "operator");
    // const c2 = where("username", "==", username);
    // const qRef = query(cRef, c2);
    getDocs(cRef)
      .then((snapshot) => {
        if (snapshot) {
          const commissionHistoryList = snapshot.docs.map((doc) => doc.data());
          console.log({ commissionHistoryList });
          // if (commissionHistoryList.length === 0) {
          const id = doc(cRef).id;
          const data = {
            ...commissionHistory,
            id,
            createdAt: serverTimestamp(),
            // role: "operator",
          };

          const dRef = doc(cRef, id);
          setDoc(dRef, data, { merge: true }).then(() => {
            if (callback) {
              callback({
                status: 200,
                data,
              });
            }
          });
          // } else {
          //   if (callback) {
          //     callback({
          //       status: 500,
          //       data: "Duplicate id",
          //     });
          //   }
          // }
        }
      })
      .catch((error) => {
        if (callback) {
          callback({
            status: 500,
            data: error.code,
          });
        }
      });
  };

// -------------------------- Operation Update  --------------------------
// Update commissionHistory
export const updateCommissionHistory =
  ({ commissionHistory, update }, callback) =>
  (dispatch) => {
    const cRef = collection(firestore, REF.COMMISSION_HISTORY);
    const id = commissionHistory?.id;
    // const email = update.email;
    // const c1 = where("role", "==", "operator");
    const c2 = where("id", "==", commissionHistory?.id);
    const qRef = query(cRef, c2);
    getDocs(qRef)
      .then((snapshot) => {
        if (snapshot) {
          const commissionHistoryList = snapshot.docs
            .map((doc) => doc.data())
            .filter((item) => item.id !== id);
          if (commissionHistoryList.length === 0) {
            const dRef = doc(cRef, id);
            updateDoc(dRef, update).then(() => {
              selectCommissionHistorySuccess(dispatch, update);
              if (callback) {
                callback({
                  status: 200,
                  data: update,
                });
              }
            });
          } else {
            if (callback) {
              callback({
                status: 500,
                data: "Duplicate id",
              });
            }
          }
        }
      })
      .catch((error) => {
        if (callback) {
          callback({
            status: 500,
            data: error.code,
          });
        }
      });
  };

// -------------------------- Operation Delete  --------------------------

export const deleteCommissionHistory =
  ({ commissionHistory }, callback) =>
  (dispatch) => {
    console.log({ commissionHistory });
    const cRef = collection(firestore, REF.COMMISSION_HISTORY);
    const dRef = doc(cRef, commissionHistory.id);
    deleteDoc(dRef).then(() => {
      if (callback) {
        callback();
      }
    });
  };

// -------------------------- Update info --------------------------

export const firebaseUpdateLoginTime = () => (dispatch) => {
  const cRef = collection(firestore, REF.COMMISSION_HISTORY);
  const email = auth.currentUser?.email;
  const uid = auth.currentUser?.uid;
  if (email === MASTER_EMAIL) {
    return;
  } else {
    const dRef = doc(cRef, uid);
    const update = {
      onlineAt: serverTimestamp(),
    };
    updateDoc(dRef, update);
  }
};

// -------------------------- Operation Select  --------------------------

export const selectCommissionHistory =
  ({ detail }, callback) =>
  (dispatch) => {
    console.log("detail action", detail);
    selectCommissionHistorySuccess(dispatch, detail);
    if (callback) {
      callback();
    }
  };

export const selectAddCommissionHistory = (callback) => (dispatch) => {
  selectAddSuccess(dispatch);
  if (callback) {
    callback();
  }
};

export const successAddCommissionHistory = (callback) => (dispatch) => {
  commissionHistoryAddSuccess(dispatch);
  if (callback) {
    callback();
  }
};

export const successDeleteCommissionHistory = (callback) => (dispatch) => {
  commissionHistoryAddSuccess(dispatch);
  if (callback) {
    callback();
  }
};

// -------------------------- Dispatch  --------------------------

const getCommissionHistoryDataSuccess = (dispatch, commissionHistory) => {
  dispatch({
    type: TYPES.GET_COMMISSION_HISTORY_SUCCESS,
    payload: commissionHistory,
  });
};

const getCommissionHistoryListSuccess = (dispatch, data) => {
  dispatch({
    type: TYPES.COMMISSION_HISTORY_LIST_SUCCESS,
    payload: data,
  });
};

const selectCommissionHistorySuccess = (dispatch, data) => {
  dispatch({
    type: TYPES.SELECT_COMMISSION_HISTORY_SUCCESS,
    payload: data,
  });
};

const commissionHistoryAddSuccess = (dispatch) => {
  dispatch({
    type: TYPES.COMMISSION_HISTORY_ADD_SUCCESS,
  });
};

const selectAddSuccess = (dispatch) => {
  dispatch({
    type: TYPES.SELECT_COMMISSION_HISTORY_ADD,
  });
};
