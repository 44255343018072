import { combineReducers } from "redux";
import authReducer from "./auth/auth.reducer";
import loginReducer from "./login/login.reducer";
import navReducer from "./nav/nav.reducer";
import userReducer from "./user/user.reducer";
import operatorReducer from "./operator/operator.reducer";
import ipsReducer from "./ips/ips.reducer";
import clientReducer from "./client/client.reducer";
import topupWithdrawReducer from "./topup_withdraw_history/history.reducer";
import profitHistoryReducer from "./shared_profit_history/history.reducer";
import partnerReducer from "./partner/partner.reducer";
import commissionHistoryReducer from "./commission_fee_history/history.reducer";
import withdrawRequestReducer from "./withdraw_request_history/history.reducer";

const rootReducer = combineReducers({
  authReducer,
  loginReducer,
  navReducer,
  userReducer,
  operatorReducer,
  ipsReducer,
  clientReducer,
  topupWithdrawReducer,
  profitHistoryReducer,
  partnerReducer,
  commissionHistoryReducer,
  withdrawRequestReducer,
});

export default rootReducer;
