import React from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "antd";
import { detailSelector } from "../../../store/operator/operator.selector";
import UseDetailAdd from "./operator_detail_add";
import UseDetailEdit from "./operator_detail_edit";

const UserDetailSection = () => {
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  const detail = useSelector(detailSelector);
  // -------------------------- EFFECT --------------------------

  // -------------------------- FUNCTION --------------------------

  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------

  // -------------------------- MAIN --------------------------

  return (
    <Row gutter={[16, 12]}>
      <Col xl={24} lg={24} md={24} xs={24}>
        {detail === null ? <UseDetailAdd /> : <UseDetailEdit />}
      </Col>
    </Row>
  );
};

export default UserDetailSection;
