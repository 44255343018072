import { Form, Input } from "antd";
import React from "react";

const IpsDetailsBankAccount = (props) => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="accountId"
      name="accountId"
      label={
        <span>
          Account ID&nbsp;
          <span style={{ color: "red" }}>*</span>
        </span>
      }
      rules={[
        {
          required: true,
          message: "required field",
        },
      ]}
    >
      <Input style={{ width: "100%" }} placeholder="Account number" />
    </Form.Item>
  );
};
export default IpsDetailsBankAccount;
