import { Form, Input, Select } from "antd";
import React from "react";
import { countriesList } from "../../../../../store/data/countries_list";

const ClientDetailLastName = (props) => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="country"
      name="country"
      label={
        <span>
          Country&nbsp;
          <span style={{ color: "red" }}>*</span>
        </span>
      }
      rules={[
        {
          required: true,
          message: "required field",
        },
      ]}
    >
      <Select
        showSearch
        placeholder="Country"
        options={countriesList}
        filterOption={filterOption}
      />
    </Form.Item>
  );
};
export default ClientDetailLastName;
