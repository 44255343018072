import { Form, Input } from "antd";
import moment from "moment";
import React, { useState } from "react";
import DatePickerMoment from "../../../../../../component/datepicker_moment";
import { useEffect } from "react";

const ClientRequestWithdrawTime = (props) => {
  // -------------------------- VAR --------------------------
  const { requestOn, processTime, form } = props;
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    const withdrawTime = requestOn
      ?.add(processTime, "days")
      .format("MMM DD, YYYY");
    form.setFieldValue("withdrawTime", withdrawTime);
  }, [requestOn, processTime]);
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------

  return (
    <Form.Item
      id="withdrawTime"
      name="withdrawTime"
      label="Withdraw on"
      rules={[
        {
          required: true,
          message: "required field",
        },
      ]}
    >
      {/* <DatePickerMoment style={{ width: "100%" }} format={"MMM DD, YYYY"} /> */}
      <Input disabled />
    </Form.Item>
  );
};
export default ClientRequestWithdrawTime;
