import { Form, Input, InputNumber } from "antd";
import React from "react";

const ClientTopupWithdrawBankAccountNumber = (props) => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="bankAccountNumber"
      name="bankAccountNumber"
      label="Account Number"
      rules={[
        {
          required: true,
          message: "required field",
        },
        {
          whitespace: true,
          message: "Invalid",
        },
        // {
        //   pattern: /^[A-Za-z ]+$/,
        //   message: "Alphabets only",
        // },
        {
          max: 32,
          message: "Max length must be 32 characters",
        },
      ]}
    >
      <Input />
    </Form.Item>
  );
};
export default ClientTopupWithdrawBankAccountNumber;
