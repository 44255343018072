import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { TYPES } from "./auth.type";
import { app } from "../../config/config";

const auth = getAuth(app);

// -------------------------- Snapshot --------------------------
const authSub = [];
export const snapAuth = () => (dispatch) => {
  const unSub = onAuthStateChanged(auth, () => {
    const uid = auth?.currentUser?.uid;
    const isLogged = uid ? true : false;
    console.log({ uid });
    firebaseAuthSuccess(dispatch, isLogged);
    // firebaseAuthUserSuccess(dispatch, uid);
  });
  authSub.push(unSub);
};

export const unSnapAuth = () => (dispatch) => {
  authSub.forEach((subscriber) => {
    subscriber();
  });
  authSub.length = 0;
};

// -------------------------- log out --------------------------
export const logOut = () => (dispatch) => {
  signOut(auth);
};

// -------------------------- Dispatch --------------------------
const firebaseAuthSuccess = (dispatch, data) => {
  dispatch({
    type: TYPES.FIREBASE_AUTH_STATUS,
    payload: data,
  });
};
