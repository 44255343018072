import { Input } from "antd";
import React from "react";

const { Search } = Input;
const PartnerListSearch = (props) => {
  // -------------------------- VAR --------------------------
  const { placeholder, search, setSearch } = props;
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Search
      placeholder={placeholder}
      allowClear
      onChange={handleChange}
      style={{
        width: "100%",
      }}
      value={search}
    />
  );
};
export default PartnerListSearch;
