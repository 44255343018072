export const MODEL_CLIENT = {
  model: {
    username: "",
    avatar: "",
    active: false,
    firstName: "",
    lastName: "",
    password: "",
    gender: "",
    group: null,
    telephone: "",
    email: "",
    address: "",
    requestType: "",
    totalIps: 0,
    totalFund: "0 USD",
  },
};
