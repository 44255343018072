import React from "react";
import { Form, Input } from "antd";
import { ManOutlined, WomanOutlined } from "@ant-design/icons";

const ClientSex = (props) => {
  // -------------------------- VAR --------------------------
  const { disabled, sex } = props;
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- EFFECT --------------------------
  // -------------------------- FUNCTION --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      name="sex"
      rules={[
        {
          required: true,
          message: "Please input sex",
        },
        {
          whitespace: true,
          message: "Invalid",
        },
        {
          pattern: /^[A-Za-z]+$/,
          message: "Alphabets only",
        },
        {
          max: 32,
          message: "Max length must be 32 characters",
        },
      ]}
    >
      {sex === "Male" ? (
        <Input
          // showCount
          // maxLength={32}
          addonBefore={<ManOutlined className="form-item-icon" />}
          placeholder="Sex"
          disabled={disabled}
        />
      ) : (
        <Input
          // showCount
          // maxLength={32}
          addonBefore={<WomanOutlined className="form-item-icon" />}
          placeholder="Sex"
          disabled={disabled}
        />
      )}
    </Form.Item>
  );
};

export default ClientSex;
