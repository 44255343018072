import { Form, Select } from "antd";
import React from "react";
import { DURATION_2 } from "../../../../../store/data/ips_mgmt";
const IpsDetailsDuration = (props) => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  const durationOptions = DURATION_2;
  // -------------------------- FUNCTION --------------------------
  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="duration"
      name="duration"
      label={
        <span>
          Term Deposit&nbsp;
          <span style={{ color: "red" }}>*</span>
        </span>
      }
      rules={[
        {
          required: true,
          message: "required field",
        },
      ]}
    >
      <Select options={durationOptions} placeholder="Select duration" />
    </Form.Item>
  );
};
export default IpsDetailsDuration;
