import React, { useState } from "react";
import { Select } from "antd";
import { USER_GROUPS } from "../../../store/string";

const { Option } = Select;

const OperatorListSelectGroup = (props) => {
  // -------------------------- STATE --------------------------
  const { disabled } = props;
  const [value, setValue] = useState("All");
  const options = [{ name: "All Roles", value: "All" }, ...USER_GROUPS];
  // -------------------------- FUNCTION --------------------------
  const handleChange = (value) => {
    setValue(value);
    props.onSelect(value);
  };
  // -------------------------- DATA FUNCTION --------------------------

  // -------------------------- FIREBASE FUNCTION --------------------------

  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------

  return (
    <Select
      value={value}
      style={{ width: "100%" }}
      dropdownMatchSelectWidth={200}
      onChange={handleChange}
      disabled={disabled}
      placeholder="All Roles"
    >
      {options?.map((item) => (
        <Option key={item.value} value={item.value}>
          {item.name}
        </Option>
      ))}
    </Select>
  );
};

export default OperatorListSelectGroup;
