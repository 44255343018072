import moment from "moment/moment";

export const MODEL_REQUEST = {
  model: {
    ipsId: "",
    clientId: "",
    requestTime: moment(),
    processTime: "7",
    withdrawTime: moment().add(7, "days").format("MMM DD, YYYY"),
    amount: "",
    status: "undeposited",
  },
};
