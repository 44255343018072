import { Form, InputNumber, Select } from "antd";
import React from "react";

const IpsDetailsFund = (props) => {
  // -------------------------- VAR --------------------------
  const { fundCurrency, setFundCurrency } = props;
  const currencyOptions = [
    { label: "USD", value: "usd" },
    { label: "VND", value: "vnd" },
    { label: "AUD", value: "aud" },
    { label: "NZD", value: "nzd" },
  ];
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  const handleChange = (e) => {
    setFundCurrency(e);
  };
  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="fund"
      name="fund"
      label={
        <span>
          Initial Fund&nbsp;
          <span style={{ color: "red" }}>*</span>
        </span>
      }
      rules={[
        {
          required: true,
          message: "required field",
        },
      ]}
    >
      {/* {receivedBy === "wallet" ? (
        <InputNumber
          style={{ width: "100%" }}
          placeholder="Amount"
          addonAfter={<Select defaultValue="USD" options={currencyOptions} />}
        />
      ) : (
        <InputNumber style={{ width: "100%" }} placeholder="Amount" />
      )} */}
      <InputNumber
        style={{ width: "100%" }}
        placeholder="Amount"
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
        addonAfter={
          <Select
            value={fundCurrency}
            options={currencyOptions}
            onChange={handleChange}
          />
        }
      />
    </Form.Item>
  );
};
export default IpsDetailsFund;
