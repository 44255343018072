import { Form, InputNumber, Select } from "antd";
import React from "react";
const ClientTopupWithdrawBankAmount = (props) => {
  // -------------------------- VAR --------------------------
  const currencyOptions = [
    { label: "USD", value: "usd" },
    { label: "VND", value: "vnd" },
    { label: "AUD", value: "aud" },
    { label: "NZD", value: "nzd" },
  ];
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="bankAmount"
      name="bankAmount"
      label="Amount"
      rules={[
        {
          required: true,
          message: "required field",
        },
      ]}
    >
      <InputNumber
        style={{ width: "100%" }}
        addonAfter={<Select options={currencyOptions} defaultValue="usd" />}
      />
    </Form.Item>
  );
};
export default ClientTopupWithdrawBankAmount;
