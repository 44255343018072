import React from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Switch } from "antd";

const ClientActive = (props) => {
  // -------------------------- VAR --------------------------
  const { data } = props;
  const { onChange } = props;
  const { active } = data;
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- EFFECT --------------------------
  // -------------------------- FUNCTION --------------------------
  const handleChange = (value) => {
    onChange({ id: "active", value });
  };
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <span>
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        checked={active}
        onChange={handleChange}
      />
      <span style={{ marginLeft: 5 }}>{active ? "Active" : "Inactive"}</span>
    </span>
  );
};

export default ClientActive;
