import React, { useEffect } from "react";
import { Col, Form, Input, Radio, Row, Select } from "antd";
import {
  CloudOutlined,
  ClusterOutlined,
  DollarOutlined,
} from "@ant-design/icons";

const PartnerCryptoWallet = (props) => {
  // -------------------------- VAR --------------------------
  const { disabled } = props;
  const networkOptions = [
    { label: "BEP20", value: "BEP20" },
    { label: "TRC20", value: "TRC20" },
  ];
  // -------------------------- STATE --------------------------

  // -------------------------- REDUX --------------------------

  // -------------------------- VAR --------------------------

  // -------------------------- FUNCTION --------------------------

  // -------------------------- EFFECT --------------------------

  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------
  return (
    <Row gutter={[16, 0]}>
      <Col xl={12} lg={12} md={24} xs={24}>
        <Row gutter={[2, 0]}>
          <Col xl={3} lg={3} md={3} xs={3}>
            {/* <Input value="Network" disabled={true} /> */}
            <Input
              prefix={<CloudOutlined className="form-item-icon" />}
              disabled={true}
            />
          </Col>
          <Col xl={21} lg={21} md={21} xs={21}>
            <Form.Item
              id="walletNetwork"
              name="walletNetwork"
              rules={[
                {
                  required: true,
                  message: "required field",
                },
              ]}
            >
              <Select options={networkOptions} disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col xl={12} lg={12} md={24} xs={24}>
        <Row gutter={[2, 0]}>
          <Col xl={3} lg={3} md={3} xs={3}>
            {/* <Input value="Network" disabled={true} /> */}
            <Input
              prefix={<ClusterOutlined className="form-item-icon" />}
              disabled={true}
            />
          </Col>
          <Col xl={21} lg={21} md={21} xs={21}>
            <Form.Item
              id="walletAddress"
              name="walletAddress"
              rules={[
                {
                  required: true,
                  message: "required field",
                },
              ]}
            >
              <Input
                placeholder="Wallet Address"
                disabled={disabled}
                // addonBefore={<ClusterOutlined className="form-item-icon" />}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default PartnerCryptoWallet;
