import React from "react";
import { Layout, Empty, Button, Row, Col } from "antd";
import { FiPower } from "react-icons/fi";
import { useDispatch } from "react-redux";
// redux
import { logOut } from "../../store/auth/auth.action";

const { Content } = Layout;
const BannedScreen = () => {
  // -------------------------- VAR --------------------------

  // -------------------------- STATE --------------------------

  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  // -------------------------- FUNCTION --------------------------

  const renderLogout = () => {
    return (
      <Button
        shape="default"
        onClick={() => dispatch(logOut())}
        style={{ alignSelf: "center", margin: "auto" }}
        icon={
          <span className="anticon">
            <FiPower />
          </span>
        }
      >
        Logout
      </Button>
    );
  };

  // -------------------------- EFFECT --------------------------

  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------
  return (
    <Layout className="site-layout full">
      <Content className="content">
        <Row gutter={[10, 10]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <h1 style={{ textAlign: "center" }}>Banned!</h1>
            <Empty description={false} />
            <p style={{ textAlign: "center" }}>You have been banned.</p>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} style={{ textAlign: "center" }}>
            {renderLogout()}
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default BannedScreen;
