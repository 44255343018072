import { Form, Input } from "antd";
import React from "react";

const ClientTopupWithdrawBankAccountName = (props) => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="bankAccountName"
      name="bankAccountName"
      label="Account Name"
      rules={[
        {
          required: true,
          message: "required field",
        },
        {
          whitespace: true,
          message: "Invalid",
        },
        {
          pattern: /^[A-Za-z ]+$/,
          message: "Alphabets only",
        },
        {
          max: 64,
          message: "Max length must be 64 characters",
        },
      ]}
    >
      <Input />
    </Form.Item>
  );
};
export default ClientTopupWithdrawBankAccountName;
