import moment from "moment";

export const CONTRACT_OPTIONS = [
  { label: "All contract types", value: "all" },
  { label: "Profit Sharing", value: "sharing" },
  { label: "Fixed-rate Interest", value: "fixed" },
];

export const MODEL_CLIENT = {
  model: {
    id: "",
    avatar: "",
    active: false,
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
    address: "",
    ips: [],
  },
};

export const IPS_OPTIONS = [
  { label: "IPS 1", value: "ips1" },
  { label: "IPS 2", value: "ips2" },
  { label: "IPS 3", value: "ips3" },
];

export const IPS_DETAILS = [
  {
    id: "ips1",
    name: "IPS 1",
    type: "Fixed-rate Interest",
    duration: "12 months",
    shareProfit: "weekly",
    createAt: "2023-06-01T12:00:00",
    closeAt: "2024-06-01T12:00:00",
    initialFund: 10000,
    currentFund: 10300,
    interestRate: 0.92,
    interest: 103.01,
    network: "BEP20",
    walletAddress: "0x123ee5678",
  },
  {
    id: "ips2",
    name: "IPS 2",
    type: "Fixed-rate Interest",
    duration: "12 months",
    shareProfit: "weekly",
    createAt: "2023-06-01T12:00:00",
    closeAt: "2024-06-01T12:00:00",
    initialFund: 10000,
    currentFund: 10300,
    interestRate: 1,
    interest: 103.01,
    bankName: "Vietcombank",
    accountId: "123860689",
  },
  {
    id: "ips3",
    name: "IPS 3",
    type: "Fixed-rate Interest",
    duration: "12 months",
    shareProfit: "weekly",
    createAt: "2023-06-01T12:00:00",
    closeAt: "2024-06-01T12:00:00",
    initialFund: 10000,
    currentFund: 10300,
    interestRate: 1,
    interest: 103.01,
    network: "TRC20",
    walletAddress: "0x123ee5678",
  },
];

export const IPS_HISTORY = [
  {
    id: "1223sdsdsdsd",
    ipsId: "1223sdsdsd",
    sharedProfit: null,
    createAt: moment(),
    status: "undeposited",
  },
  {
    id: "sdsd",
    ipsId: "1223sdsdsd",
    sharedProfit: 10000000,
    createAt: moment().subtract(1, "weeks"),
    status: "pending",
  },
  {
    id: "4545344",
    ipsId: "1223sdsdsd",
    sharedProfit: 800000,
    createAt: moment().subtract(2, "weeks"),
    status: "deposited",
  },
  {
    id: "sdsddsd222",
    ipsId: "1223sdsdsd",
    sharedProfit: 12000000,
    createAt: moment().subtract(3, "weeks"),
    status: "deposited",
  },
  {
    id: "233rrr",
    ipsId: "1223sdsdsd",
    sharedProfit: 10000000,
    createAt: moment().subtract(4, "weeks"),
    status: "deposited",
  },
];

export const CLIENT_LIST = [
  {
    id: "1223324fdfsdsd",
    firstName: "John",
    lastName: "Nguyen",
    sex: "Male",
    ips: 2,
    createAt: moment(),
    funds: 24000000,
    avatar:
      "https://sm.ign.com/ign_nordic/cover/a/avatar-gen/avatar-generations_prsz.jpg",
    active: true,
    contract: "fixed",
    phone: "+84985650054",
    email: "hien@email.com",
    address: "0x1233434738349ds233234",
    blockChain: "BSC",
  },
  {
    id: "123ersdsdsdsdsds",
    firstName: "Hien",
    lastName: "Truong",
    sex: "Male",
    ips: 2,
    createAt: moment(),
    funds: 24000000,
    avatar:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuh6JcFny3qf5q_JW-L6mAXznVP0phQz00rA&usqp=CAU",
    active: true,
    contract: "fixed",
    phone: "+84985650054",
    email: "hien@email.com",
    address: "0x1233434738349ds233234",
    blockChain: "BSC",
  },
  {
    id: "assd343434343",
    firstName: "Y",
    lastName: "Tran",
    sex: "Female",
    ips: 2,
    createAt: moment(),
    funds: 24000000,
    active: false,
    contract: "sharing",
    phone: "0985650054",
    email: "hien@email.com",
    address: "0x1233434738349ds233234",
    blockChain: "BSC",
  },
];

export const TX_HISTORY = [
  {
    id: "12221",
    createAt: moment(),
    txId: "CCa7...4719",
    network: "BEP20",
    amount: 10000,
    type: "topup",
    method: "wallet",
  },
  {
    id: "122sdssd21",
    createAt: moment(),
    txId: "CCa7...4357",
    network: "BEP20",
    amount: 12000,
    type: "withdraw",
    method: "wallet",
  },
  {
    id: "sd",
    createAt: moment(),
    txId: "CCa7...3478",
    network: "BEP20",
    amount: 10000,
    type: "topup",
    method: "wallet",
  },
  {
    id: "122sdsd21",
    createAt: moment(),
    txId: "CCa7...0912",
    network: "BEP20",
    amount: 10000,
    type: "withdraw",
    method: "wallet",
  },
  {
    id: "65636",
    createAt: moment(),
    bankName: "Vietcombank",
    bankAccount: "415957851",
    amount: 10000,
    type: "topup",
    method: "bank",
  },
  {
    id: "63fgdfh3",
    createAt: moment(),
    bankName: "Techcombank",
    bankAccount: "96793673",
    amount: 12000,
    type: "withdraw",
    method: "bank",
  },
  {
    id: "sd463fgs",
    createAt: moment(),
    bankName: "TP bank",
    bankAccount: "457423897",
    amount: 10000,
    type: "topup",
    method: "bank",
  },
  {
    id: "43fgd62561",
    createAt: moment(),
    bankName: "VIB",
    bankAccount: "969348592",
    amount: 10000,
    type: "withdraw",
    method: "bank",
  },
];

export const REQUEST_HISTORY = [
  {
    id: "1223sdsdsdsd",
    ipsId: "ips1",
    amount: 1000,
    createAt: moment(),
    status: "14 days left",
  },
  {
    id: "fhdfs546546",
    ipsId: "ips1",
    amount: 1000,
    createAt: moment(),
    status: "Deposited",
  },
  {
    id: "4387543jvfdjbzk",
    ipsId: "ips2",
    amount: 1000000,
    createAt: moment(),
    status: "7 days left",
  },
];
