import { Form, Input, Checkbox, Row, Col } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

const IpsDetailOneOff = (props) => {
  // -------------------------- VAR --------------------------
  const { form } = props;
  const oneOff = form.getFieldValue("oneOff");
  console.log({ oneOff });
  // -------------------------- STATE --------------------------
  const [disabled, setDisabled] = useState(true);
  const [oneOffValue, setOneOffValue] = useState("");
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  const handleCheck = () => {
    setDisabled(!disabled);
  };

  const handleChange = (e) => {
    console.log(e);
  };
  // -------------------------- EFFECT --------------------------
  // useEffect(() => {
  //   if (oneOff) {
  //     setDisabled(false);
  //   }
  // }, [oneOff]);
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="oneOff"
      name="oneOff"
      label="One Off"
      rules={[
        {
          required: false,
          message: "required field",
        },
        // {
        //   whitespace: false,
        //   message: "Invalid",
        // },
        {
          pattern: /^[0-9]+$/,
          message: "",
        },
      ]}
    >
      <Row gutter={[12, 0]}>
        <Col xl={2} lg={2} md={2} xs={2}>
          <Checkbox onChange={handleCheck} />
        </Col>
        <Col xl={22} lg={22} md={22} xs={22}>
          <Input
            name="oneOff"
            placeholder="One Off"
            disabled={disabled}
            addonAfter="%"
            // value={oneOff}
            onChange={handleChange}
          />
        </Col>
      </Row>
    </Form.Item>
  );
};
export default IpsDetailOneOff;
