import { Form, Input } from "antd";
import React from "react";

const IpsDetailsBankName = (props) => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="bankName"
      name="bankName"
      label={
        <span>
          Bank Name&nbsp;
          <span style={{ color: "red" }}>*</span>
        </span>
      }
      rules={[
        {
          required: true,
          message: "required field",
        },
      ]}
    >
      <Input style={{ width: "100%" }} placeholder="Bank name" />
    </Form.Item>
  );
};
export default IpsDetailsBankName;
