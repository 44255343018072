import React, { useEffect } from "react";
import { Col, Form, Input, Row, Select } from "antd";
import { useSelector } from "react-redux";
import {
  snapPartnerList,
  unSnapPartnerList,
} from "../../../../../store/partner/partner.action";
import { partnerListSelector } from "../../../../../store/partner/partner.selector";
import { useDispatch } from "react-redux";

const IpsDetailsPartnerRef = (props) => {
  // -------------------------- VAR --------------------------
  const { setPartnerId, setPartnerName, form } = props;
  // -------------------------- STATE --------------------------

  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  const partnerList = useSelector(partnerListSelector);
  console.log({ partnerList });
  // -------------------------- VAR --------------------------
  // const partnerOptions = partnerList?.map((partner) => ({
  //   label:
  //     partner.firstName + " " + partner.lastName + " - " + partner.partnerId,
  //   value: partner.partnerId,
  // }));
  const partnerOptions = [
    { label: "None", value: "" },
    ...partnerList?.map((partner) => ({
      label:
        partner.firstName + " " + partner.lastName + " - " + partner.partnerId,
      value: partner.partnerId,
    })),
  ];

  const partnerId = form?.getFieldValue("partnerId");
  const partner = partnerList?.find(
    (partner) => partner.partnerId === partnerId
  );

  // -------------------------- FUNCTION --------------------------
  if (partnerId) {
    setPartnerName(partner?.firstName + " " + partner?.lastName);
  }

  const handleChange = (value, option) => {
    console.log({ option });
    form.setFieldValue("partnerId", value);
    setPartnerId(value);
    setPartnerName(option.label);
  };
  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    console.log({ partnerId });
  }, [partnerId]);

  useEffect(() => {
    dispatch(snapPartnerList());
    return () => dispatch(unSnapPartnerList());
  }, [dispatch]);
  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="partnerId"
      name="partnerId"
      label="Partner Ref"
      rules={[
        {
          required: false,
          message: "required field",
        },
      ]}
    >
      <Row gutter={[8, 0]}>
        <Col xl={18} lg={18} md={18} xs={18}>
          <Select
            options={partnerOptions}
            placeholder="Partner"
            onChange={handleChange}
            // value={partnerId}
          />
        </Col>
        <Col xl={6} lg={6} md={6} xs={6}>
          <Input
            style={{ width: "100%" }}
            addonAfter="%"
            disabled={true}
            value={partner?.commissionFee}
          />
        </Col>
      </Row>
    </Form.Item>
  );
};
export default IpsDetailsPartnerRef;
