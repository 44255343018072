import React from "react";
import { Form } from "antd";
// import PhoneInput from "../../../component/phone_input";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const OperatorPhone = (props) => {
  // -------------------------- VAR --------------------------

  const { disabled } = props;

  // -------------------------- STATE --------------------------

  // -------------------------- REDUX --------------------------
  // -------------------------- EFFECT --------------------------
  // -------------------------- FUNCTION --------------------------

  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      name="phone"
      rules={[
        {
          required: true,
          message: "Please input phone number",
        },
        {
          whitespace: true,
          message: "Invalid",
        },
        {
          min: 9,
          message: "Min length must be at least 6 characters",
        },
      ]}
    >
      <PhoneInput
        className="ant-phone"
        placeholder="Phone number"
        name="phone"
        maxLength={15}
        minLength={9}
        addInternationalOption={false}
        disabled={disabled}
        style={{
          opacity: disabled ? 0.5 : 1,
          backgroundColor: disabled ? "#f5f5f5" : "#fff",
        }}
        defaultCountry="AU"
      />
    </Form.Item>
  );
};

export default OperatorPhone;
