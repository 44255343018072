import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "antd";
import { Loading } from "../../../component/loading";
import OperatorListSearch from "../component/operator_list_search";
import OperatorListSelectGroup from "../component/operator_list_select_group";
import OperatorListButtonAdd from "../component/operator_list_button_add";
import OperatorList from "../component/operator_list";
import { operatorsSelector } from "../../../store/operator/operator.selector";

const OperatorListSection = (props) => {
  // -------------------------- STATE --------------------------
  const { loading } = props;
  const [search, setSearch] = useState("");
  const [group, setGroup] = useState("All");
  const [data, setData] = useState([]);

  // -------------------------- REDUX --------------------------
  const operators = useSelector(operatorsSelector);
  console.log("operatorList: " + operators);
  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    console.log({ operators });
    let data = operators || [];
    if (search !== "") {
      data = data?.filter((item) => {
        const firstName = item?.firstName ? item?.firstName.toLowerCase() : "";
        const lastName = item?.lastName ? item?.lastName.toLowerCase() : "";
        const text = search.replace(/\s+/g, "").toLowerCase();
        const found = firstName?.includes(text) || lastName?.includes(text);
        return found;
      });
    }
    if (group && group !== "All") {
      data = data?.filter((item) => item.group === group);
    }
    setData(data);
  }, [operators, group, search]);
  // -------------------------- FUNCTION --------------------------

  // -------------------------- RENDER --------------------------

  const renderTop = () => {
    return (
      <Row gutter={[16, 12]}>
        <Col xl={12} lg={12} md={24} xs={24}>
          <OperatorListSearch onChange={setSearch} />
        </Col>
        <Col xl={6} lg={6} md={12} xs={12}>
          <OperatorListSelectGroup onSelect={setGroup} />
        </Col>
        <Col xl={6} lg={6} md={12} xs={12}>
          <OperatorListButtonAdd />
        </Col>
      </Row>
    );
  };

  const renderBottom = () => {
    return (
      <Row gutter={[16, 12]}>
        <Col xl={24} lg={24} md={24} xs={24}>
          {loading ? <Loading /> : <OperatorList data={data} />}
        </Col>
      </Row>
    );
  };

  // -------------------------- MAIN --------------------------

  return (
    <Row gutter={[16, 12]}>
      <Col xl={24} lg={24} md={24} xs={24}>
        {renderTop()}
      </Col>
      <Col xl={24} lg={24} md={24} xs={24}>
        {renderBottom()}
      </Col>
    </Row>
  );
};

export default OperatorListSection;
