import React, { useEffect } from "react";
import { Col, Form, Input, Radio, Row, Select } from "antd";
import { useSelector } from "react-redux";
import {
  snapClientList,
  unSnapClientList,
} from "../../../../../store/client/client.action";
import { clientListSelector } from "../../../../../store/client/client.selector";
import { useDispatch } from "react-redux";
// import { useState } from "react";

const PartnerDetailsCryptoWallet = (props) => {
  // -------------------------- VAR --------------------------
  const networkOptions = [
    { label: "BEP20", value: "BEP20" },
    { label: "TRC20", value: "TRC20" },
  ];
  // -------------------------- STATE --------------------------

  // -------------------------- REDUX --------------------------

  // -------------------------- VAR --------------------------

  // -------------------------- FUNCTION --------------------------
  // const handleChange = (value, option) => {
  //   // setClientId(value);
  //   const client = clientList?.find((client) => client.username === value);
  //   setPartnerInfo(client);
  //   console.log(option);
  // };
  // -------------------------- EFFECT --------------------------
  // useEffect(() => {
  //   dispatch(snapClientList());
  //   return () => dispatch(unSnapClientList());
  // }, [dispatch]);
  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------
  return (
    <Row gutter={[16, 0]}>
      <Col xl={24} lg={24} md={24} xs={24}>
        <Form.Item
          id="walletNetwork"
          name="walletNetwork"
          defaultValue="BEP20"
          label={
            <span>
              Network&nbsp;
              <span style={{ color: "red" }}>*</span>
            </span>
          }
          rules={[
            {
              required: true,
              message: "required field",
            },
          ]}
        >
          <Radio.Group options={networkOptions} />
        </Form.Item>
      </Col>
      <Col xl={24} lg={24} md={24} xs={24}>
        <Form.Item
          id="walletAddress"
          name="walletAddress"
          label={
            <span>
              Wallet Address&nbsp;
              <span style={{ color: "red" }}>*</span>
            </span>
          }
          rules={[
            {
              required: true,
              message: "required field",
            },
          ]}
        >
          <Input placeholder="Wallet Address" />
        </Form.Item>
      </Col>
    </Row>
  );
};
export default PartnerDetailsCryptoWallet;
