import { getAuth, signOut } from "firebase/auth";
import {
  collection,
  where,
  getFirestore,
  getDocs,
  query,
  onSnapshot,
  setDoc,
  serverTimestamp,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { app } from "../../config/config";
import { TYPES } from "./operator.types";
import { REF } from "../ref";
import { FULL_PERMISSION } from "../../store/string";
import { MASTER_EMAIL } from "../../config/config";

const firestore = getFirestore(app);
const auth = getAuth(app);

// -------------------------- Snap List  --------------------------
const subOperator = [];
export const snapOperator = () => (dispatch) => {
  const uid = auth?.currentUser?.uid;
  const email = auth?.currentUser?.email;
  const cRef = collection(firestore, REF.OPERATOR);
  const dRef = doc(cRef, uid);
  if (email === MASTER_EMAIL) {
    const data = {
      firstName: "Master",
      lastName: "Account",
      permissions: FULL_PERMISSION,
      active: true,
    };
    getOperatorDataSuccess(dispatch, data);
    getPermissionsSuccess(dispatch, data);
    return;
  }
  const unSub = onSnapshot(dRef, (snapshot) => {
    if (snapshot) {
      const data = snapshot.data();
      if (data === undefined) {
        signOut(auth);
      } else {
        getOperatorDataSuccess(dispatch, data);
        getPermissionsSuccess(dispatch, data);
      }
    }
  });
  subOperator.push(unSub);
};

export const unSnapOperator = () => (dispatch) => {
  subOperator.forEach((subscriber) => {
    subscriber();
  });
  subOperator.length = 0;
};

export const resetOperator = (callback) => (dispatch) => {
  getOperatorDataSuccess(dispatch, null);
};
// -------------------------- Operation List  --------------------------
// Lấy dữ liệu operator
export const firestoreGetOperatorList = (callback) => (dispatch) => {
  const cRef = collection(firestore, REF.OPERATOR);
  const c1 = where("role", "==", "operator");
  const qRef = query(cRef, c1);
  getDocs(qRef).then((snapshot) => {
    if (snapshot) {
      const data = snapshot.docs.map((doc) => doc.data());
      console.log("operators", data);
      getOperatorListSuccess(dispatch, data);
    }
    if (callback) {
      callback();
    }
  });
};

// Theo dõi dữ liệu operator
const subList = [];
export const snapOperatorList = (callback) => (dispatch) => {
  const cRef = collection(firestore, REF.OPERATOR);
  const c1 = where("role", "==", "operator");
  const qRef = query(cRef, c1);
  const unSub = onSnapshot(qRef, (snapshot) => {
    if (snapshot) {
      const data = snapshot.docs.map((doc) => doc.data());
      getOperatorListSuccess(dispatch, data);
      if (callback) {
        callback();
      }
    }
  });
  subList.push(unSub);
};

export const unSnapOperatorList = () => (dispatch) => {
  subList.forEach((subscriber) => {
    subscriber();
  });
  subList.length = 0;
};

// -------------------------- Operation Add  --------------------------

export const addOperator =
  ({ operator }, callback) =>
  (dispatch) => {
    console.log({ operator });
    const email = operator?.email;
    const cRef = collection(firestore, REF.OPERATOR);
    const c1 = where("role", "==", "operator");
    const c2 = where("email", "==", email);
    const qRef = query(cRef, c1, c2);
    getDocs(qRef)
      .then((snapshot) => {
        console.log({ snapshot });
        if (snapshot) {
          const operatorList = snapshot.docs.map((doc) => doc.data());
          console.log({ operatorList });
          if (operatorList.length === 0) {
            const id = doc(cRef).id;
            const data = {
              ...operator,
              id,
              createAt: serverTimestamp(),
              role: "operator",
            };

            const dRef = doc(cRef, id);
            setDoc(dRef, data, { merge: true }).then(() => {
              if (callback) {
                callback({
                  status: 200,
                  data,
                });
              }
            });
          } else {
            if (callback) {
              callback({
                status: 500,
                data: "Dubplicate Email Address",
              });
            }
          }
        }
      })
      .catch((error) => {
        if (callback) {
          callback({
            status: 500,
            data: error.code,
          });
        }
      });
  };

// -------------------------- Operation Update  --------------------------
// Update operator
export const updateOperator =
  ({ operator, update }, callback) =>
  (dispatch) => {
    const cRef = collection(firestore, REF.OPERATOR);
    const id = operator.id;
    const email = update.email;
    const c1 = where("role", "==", "operator");
    const c2 = where("email", "==", email);
    const qRef = query(cRef, c1, c2);
    getDocs(qRef)
      .then((snapshot) => {
        if (snapshot) {
          const operatorList = snapshot.docs
            .map((doc) => doc.data())
            .filter((item) => item.id !== id);
          if (operatorList.length === 0) {
            const dRef = doc(cRef, id);
            updateDoc(dRef, update).then(() => {
              selectOperatorSuccess(dispatch, update);
              if (callback) {
                callback({
                  status: 200,
                  data: update,
                });
              }
            });
          } else {
            if (callback) {
              callback({
                status: 500,
                data: "Dubplicate Email Address",
              });
            }
          }
        }
      })
      .catch((error) => {
        if (callback) {
          callback({
            status: 500,
            data: error.code,
          });
        }
      });
  };

// -------------------------- Operation Delete  --------------------------

export const deleteOperator =
  ({ operator }, callback) =>
  (dispatch) => {
    const cRef = collection(firestore, REF.OPERATOR);
    const dRef = doc(cRef, operator.id);
    deleteDoc(dRef).then(() => {
      if (callback) {
        callback();
      }
    });
  };

// -------------------------- Update info --------------------------

export const firebaseUpdateLoginTime = () => (dispatch) => {
  const cRef = collection(firestore, REF.OPERATOR);
  const email = auth.currentUser?.email;
  const uid = auth.currentUser?.uid;
  if (email === MASTER_EMAIL) {
    return;
  } else {
    const dRef = doc(cRef, uid);
    const update = {
      onlineAt: serverTimestamp(),
    };
    updateDoc(dRef, update);
  }
};

// -------------------------- Operation Select  --------------------------

export const selectOperator =
  ({ detail }, callback) =>
  (dispatch) => {
    selectOperatorSuccess(dispatch, detail);
    if (callback) {
      callback();
    }
  };

export const selectAddOperator = (callback) => (dispatch) => {
  selectAddSuccess(dispatch);
  if (callback) {
    callback();
  }
};

export const successAddOperator = (callback) => (dispatch) => {
  operatorAddSuccess(dispatch);
  if (callback) {
    callback();
  }
};

export const successDeleteOperator = (callback) => (dispatch) => {
  operatorAddSuccess(dispatch);
  if (callback) {
    callback();
  }
};

// -------------------------- Dispatch  --------------------------

const getOperatorDataSuccess = (dispatch, operator) => {
  dispatch({
    type: TYPES.GET_OPERATOR_SUCCESS,
    payload: operator,
  });
};

const getPermissionsSuccess = (dispatch, operator) => {
  const email = auth?.currentUser?.email;
  const permissions =
    email === MASTER_EMAIL ? FULL_PERMISSION : operator?.permissions;
  dispatch({
    type: TYPES.GET_PERMISSIONS_SUCCESS,
    payload: permissions,
  });
};

const getOperatorListSuccess = (dispatch, data) => {
  dispatch({
    type: TYPES.OPERATOR_LIST_SUCCESS,
    payload: data,
  });
};

const selectOperatorSuccess = (dispatch, data) => {
  dispatch({
    type: TYPES.SELECT_OPERATOR_SUCCESS,
    payload: data,
  });
};

const operatorAddSuccess = (dispatch) => {
  dispatch({
    type: TYPES.OPERATOR_ADD_SUCCESS,
  });
};

const selectAddSuccess = (dispatch) => {
  dispatch({
    type: TYPES.SELECT_OPERATOR_ADD,
  });
};
