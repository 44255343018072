import React from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Switch } from "antd";

const OperatorActive = (props) => {
  // -------------------------- VAR --------------------------
  const { disabled, data } = props;
  const { active } = data;
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- EFFECT --------------------------
  // -------------------------- FUNCTION --------------------------
  const onChange = (value) => {
    props.onChange({ id: "active", value });
  };
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <span>
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        checked={active}
        disabled={disabled}
        onChange={onChange}
      />
      <span style={{ marginLeft: 10 }}>{active ? "Active" : "Deactivated"}</span>
    </span>
  );
};

export default OperatorActive;
