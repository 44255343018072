import React from "react";
import { Form } from "antd";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useEffect } from "react";

const PartnerDetailPhone = (props) => {
  // -------------------------- VAR --------------------------

  const { form, partnerInfo } = props;

  // -------------------------- STATE --------------------------

  // -------------------------- REDUX --------------------------
  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    form.setFieldValue("phone", partnerInfo?.phone);
  }, [partnerInfo]);
  // -------------------------- FUNCTION --------------------------

  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="phone"
      name="phone"
      label="Phone Number"
      rules={[
        {
          required: false,
          message: "Please input phone number",
        },
        {
          whitespace: true,
          message: "Invalid",
        },
        {
          min: 9,
          message: "Min length must be at least 6 characters",
        },
      ]}
    >
      <PhoneInput
        // placeholder="Phone number"
        className="client-phone"
        maxLength={15}
        minLength={9}
        addInternationalOption={false}
        style={{ width: "92%", minHeight: 24 }}
        defaultCountry="VN"
        disabled={true}
      />
    </Form.Item>
  );
};

export default PartnerDetailPhone;
