import { Card, Col, Form, Modal, message, Button } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ClientDetailCreateForm from "./component/dialog_client_list_form";
import { MODEL_CLIENT } from "../../../model/client";
import ClientDetailId from "./component/component/dialog_client_list_id";
import ClientDetailPassword from "./component/component/dialog_client_list_password";
import ClientDetailEmail from "./component/component/dialog_client_list_email";
import ClientDetailPhone from "./component/component/dialog_client_list_phone";
import ClientDetailIps from "./component/component/dialog_client_list_ips";
import ClientDetailFirstName from "./component/component/dialog_client_list_firstname";
import ClientDetailLastName from "./component/component/dialog_client_list_lastname";
import ClientDetailCountry from "./component/component/dialog_client_list_country";
import ClientDetailGender from "./component/component/dialog_client_list_sex";
import { addClient, selectClient } from "../../../store/client/client.action";

const ClientListCreate = (props) => {
  // -------------------------- VAR --------------------------
  const { open, setOpen } = props;
  // -------------------------- STATE --------------------------
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  const data = MODEL_CLIENT.model;
  // -------------------------- FUNCTION --------------------------

  const handleReset = () => {
    form.setFieldsValue(MODEL_CLIENT.model);
  };

  const handleCancel = () => {
    handleReset();
    setOpen(false);
  };

  const onFinish = (values) => {
    console.log({ values });
    const client = {
      ...data,
      ...values,
      email: values.email || "",
      phone: values.phone || "",
    };
    console.log({ client });
    setLoading(true);
    dispatch(
      addClient({ client }, (response) => {
        setLoading(false);
        handleReset();
        setOpen(false);
        if (response.status === 200) {
          const detail = response.data;
          dispatch(selectClient({ detail }));
          message.success("Successfully added client");
        } else {
          const error = response.data;
          message.error(error);
        }
      })
    );
  };

  const onFinishFailed = (error) => {
    console.log({ error });
  };

  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    if (open) {
      const update = {
        id: data?.id,
        password: data?.password,
        email: data?.email,
        phone: data?.phone,
        // ips: data?.ips,
      };
      form.setFieldsValue(update);
    }
  }, [open, data]);

  // -------------------------- RENDER --------------------------
  const renderId = () => {
    return <ClientDetailId />;
  };

  const renderPassword = () => {
    return <ClientDetailPassword />;
  };

  const renderEmail = () => {
    return <ClientDetailEmail />;
  };

  const renderPhone = () => {
    return <ClientDetailPhone />;
  };

  const renderIps = () => {
    return <ClientDetailIps />;
  };

  const renderFirstname = () => {
    return <ClientDetailFirstName />;
  };

  const renderLastname = () => {
    return <ClientDetailLastName />;
  };

  const renderGender = () => {
    return <ClientDetailGender />;
  };

  const renderCountry = () => {
    return <ClientDetailCountry />;
  };

  // -------------------------- MAIN --------------------------
  const layout = {
    labelAlign: "left",
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      //   okText="Save"
      //   onOk={onFinish}
      footer={null}
      confirmLoading={loading}
    >
      <Col span={24} style={{ paddingTop: 30 }}>
        <Form
          requiredMark={false}
          size="small"
          autoComplete="off"
          form={form}
          {...layout}
          // uploadedFiles={uploadedFiles}
          // setUploadedFiles={setUploadedFiles}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          {renderId()}
          {renderFirstname()}
          {renderLastname()}
          {renderGender()}
          {renderEmail()}
          {renderPhone()}
          {renderCountry()}
          <div
            style={{
              display: "flex",
              //   margin: 30,
              padding: 30,
              paddingTop: 10,
              marginLeft: 50,
              justifyItems: "right",
            }}
          >
            <div
              style={{
                width: "50%",
              }}
            >
              <Button
                className="login-form-button"
                style={{ width: "80%" }}
                loading={loading}
                size="medium"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
            <div
              style={{
                width: "50%",
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                size="medium"
                style={{ width: "80%" }}
                loading={loading}
              >
                Submit
              </Button>
            </div>
          </div>
        </Form>
      </Col>
    </Modal>
  );
};
export default ClientListCreate;
