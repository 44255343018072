import { initializeApp } from "firebase/app";
//
export const BRAND_NAME = "AI Finance - Client Admin"; // Brand Name

// Thông tin tài khoản master
export const MASTER_EMAIL = "nghiale@aifinance.co.nz"; //
// Thông tin tài khoản hệ thống
export const SANDBOX = false;

export const firebaseConfig = {
  apiKey: "AIzaSyCESFdid_OIKFJqisvppsru5V3OJj04Cgc",
  authDomain: "ai-finance-client.firebaseapp.com",
  projectId: "ai-finance-client",
  storageBucket: "ai-finance-client.appspot.com",
  messagingSenderId: "781552150971",
  appId: "1:781552150971:web:9180a96a6075496d8c7c9e",
  measurementId: "G-4E9EJ4GJMW",
};

export const reCAPTCHA = "6LesVzcoAAAAANv8UpLwrRMg0z2i--7jXGc9EZsK";
export const app = initializeApp(firebaseConfig);
export const TIME_ZONE = `Asia/Ho_Chi_Minh`;

// ========================= App String ========================= //
export const MAP_API_KEY = "";
export const VERSION = "v0.1.0";
export const WEB_DOMAIN = "";
export const MEDIA_STORAGE =
  "https://storage.googleapis.com/" + firebaseConfig.storageBucket + "/";
