import React, { useState, useEffect } from "react";
import { Col, Row, Form, Typography, Button, message, Modal } from "antd";
import { useDispatch } from "react-redux";
import { MODEL_TOPUP_WITHDRAW } from "../../../model/topupwithdraw";
import { Timestamp } from "firebase/firestore";
import ClientTopupWithdrawBankAccountNumber from "./component/component/topup_withdraw_component/dialog_topup_withdraw_bank_acc_number";
import ClientTopupWithdrawBankAccountName from "./component/component/topup_withdraw_component/dialog_topup_withdraw_bank_acc_name";
import ClientTopupWithdrawBankName from "./component/component/topup_withdraw_component/dialog_topup_withdraw_bank_name";
import ClientTopupWithdrawDatetime from "./component/component/topup_withdraw_component/dialog_topup_withdraw_datetime";
import ClientTopupWithdrawOnusNetwork from "./component/component/topup_withdraw_component/dialog_topup_withdraw_onus_network";
import ClientTopupWithdrawTransactionId from "./component/component/topup_withdraw_component/dialog_topup_withdraw_tx_id";
import ClientTopupWithdrawWalletAmount from "./component/component/topup_withdraw_component/dialog_topup_withdraw_wallet_amount";
import ClientTopupWithdrawWalletNetwork from "./component/component/topup_withdraw_component/dialog_topup_withdraw_wallet_network";
import ClientTopupWithdrawTxType from "./component/component/topup_withdraw_component/dialog_topup_withdraw_tx_type";
import {
  addTopupWithdraw,
  selectTopupWithdraw,
} from "../../../store/topup_withdraw_history/history.action";
import ClientTopupWithdrawBankAmount from "./component/component/topup_withdraw_component/dialog_topup_withdraw_bank_amount";
import { updateIps } from "../../../store/ips/ips.action";
import { updateClient } from "../../../store/client/client.action";

const ClientTopupWithdrawCreate = (props) => {
  // -------------------------- VAR --------------------------
  const { open, setOpen, client, ips, method, ipsList, clientDetail } = props;
  console.log({ method });
  const ipsData = ipsList?.filter((ipsDetail) => ipsDetail.id === ips)[0];
  // ------------------------- STATE --------------------------
  const [form] = Form.useForm();
  form.setFieldValue("onusNetwork", "ONUS Off-chain");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(MODEL_TOPUP_WITHDRAW.model);
  // ------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  // ------------------------ EFFECT --------------------------

  // ----------------------- FUNCTION --------------------------
  const handleDateTime = (date) => {
    const jsDate = date.toDate();

    // Create a Firestore Timestamp from the JavaScript Date object
    return Timestamp.fromDate(jsDate);
  };

  const handleChooseMethod = ({ id, value }) => {
    setData({
      ...data,
      [id]: value,
    });
  };

  const onFinish = (values) => {
    console.log({ values });
    const transaction = {
      ...data,
      ...values,
      clientId: client,
      ipsId: ips,
      backupId: ipsData?.ipsId,
      datetime: handleDateTime(values.datetime),
    };
    setLoading(true);
    dispatch(
      addTopupWithdraw({ topupWithdraw: transaction }, (response) => {
        handleReset();
        setOpen(false);
        if (response.status === 200) {
          const detail = response.data;
          const currentFund = ipsData?.fund;
          let newFund = currentFund;
          if (method === "bank") {
            newFund =
              detail?.txType === "topup"
                ? currentFund + detail?.bankAmount
                : currentFund - detail?.bankAmount;
          } else {
            newFund =
              detail?.txType === "topup"
                ? currentFund + detail?.walletAmount
                : currentFund - detail?.walletAmount;
          }

          const ipsUpdate = {
            ...ipsData,
            fund: newFund,
          };

          const clientUpdate = {
            ...clientDetail,
            totalFund: newFund + " " + clientDetail?.totalFund?.split(" ")[1],
          };

          dispatch(
            updateIps({ ips: ipsData, update: ipsUpdate }, (response) => {
              if (response.status === 200) {
                message.success("Successfully update ips");
              } else {
                const error = response.data;
                message.error(error);
              }
            })
          );
          dispatch(
            updateClient(
              { client: clientDetail, update: clientUpdate },
              (response) => {
                setLoading(false);
                if (response.status === 200) {
                  message.success("Successfully update client");
                } else {
                  const error = response.data;
                  message.error(error);
                }
              }
            )
          );
          dispatch(selectTopupWithdraw({ detail }));
          message.success("Successfully added transaction");
        } else {
          const error = response.data;
          message.error(error);
        }
      })
    );
  };

  const onFinishFailed = (error) => {
    console.log({ error });
  };

  const handleReset = () => {
    form.setFieldsValue(MODEL_TOPUP_WITHDRAW.model);
  };

  const handleCancel = () => {
    handleReset();
    setOpen(false);
  };
  // ------------------------ RENDER --------------------------
  const renderTxType = () => {
    return <ClientTopupWithdrawTxType />;
  };

  const renderTxId = () => {
    return <ClientTopupWithdrawTransactionId />;
  };

  const renderOnusNetwork = () => {
    return <ClientTopupWithdrawOnusNetwork />;
  };

  const renderWalletNetwork = () => {
    return <ClientTopupWithdrawWalletNetwork />;
  };

  const renderWalletAmount = () => {
    return <ClientTopupWithdrawWalletAmount />;
  };

  const renderDateTime = () => {
    return <ClientTopupWithdrawDatetime />;
  };

  const renderBankName = () => {
    return <ClientTopupWithdrawBankName />;
  };

  const renderBankAccountId = () => {
    return <ClientTopupWithdrawBankAccountNumber />;
  };

  const renderBankAccountName = () => {
    return <ClientTopupWithdrawBankAccountName />;
  };

  const renderBankAmount = () => {
    return <ClientTopupWithdrawBankAmount />;
  };

  // ------------------------- MAIN --------------------------
  const layout = {
    labelAlign: "left",
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      footer={null}
      confirmLoading={loading}
    >
      <Col span={24} style={{ paddingTop: 30 }}>
        <Form
          requiredMark={false}
          size="small"
          autoComplete="off"
          form={form}
          {...layout}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          {renderTxType()}
          {method === "wallet" && renderWalletNetwork()}
          {method === "onus" && renderOnusNetwork()}
          {(method === "wallet" || method === "onus") && renderTxId()}
          {method === "bank" && renderBankName()}
          {method === "bank" && renderBankAccountName()}
          {method === "bank" && renderBankAccountId()}
          {method === "bank" && renderBankAmount()}
          {renderDateTime()}
          {(method === "wallet" || method === "onus") && renderWalletAmount()}
          <div
            style={{
              // display: "flex",
              //   margin: 30,
              padding: "20px 40px 20px 160px ",
              // paddingTop: 20,
              // marginLeft: 50,
              // justifyItems: "right",
            }}
          >
            <div
              style={{
                width: "50%",
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                size="medium"
                style={{ width: "100%" }}
                loading={loading}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Form>
      </Col>
    </Modal>
  );
};

export default ClientTopupWithdrawCreate;
