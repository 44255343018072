import {
  Col,
  Divider,
  Form,
  Radio,
  Row,
  Tabs,
  Typography,
  Button,
  message,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ClientAvatar from "./component/client_detail_avatar";
import ClientActive from "./component/client_detail_active";
import { MODEL_CLIENT } from "../../../store/data/client_mgmt";
import ClientEmail from "./component/client_detail_email";
import ClientPhone from "./component/client_detail_phone";
import ClientDetailIps from "./component/client_detail_ips";
import ClientDetailTx from "./component/client_detail_tx";
import ClientDetailRequest from "./component/client_detail_request";
import ClientSex from "./component/client_detail_sex";
import ClientName from "./component/client_detail_name";
import { ipsListSelector } from "../../../store/ips/ips.selector";
import {
  snapIpsListByClient,
  unSnapIpsListByClient,
} from "../../../store/ips/ips.action";
import { useSelector, useDispatch } from "react-redux";
import { updateClient } from "../../../store/client/client.action";

const { Title } = Typography;
const ClientDetail = (props) => {
  // -------------------------- VAR --------------------------
  const { details } = props;
  // console.log({ details });
  // -------------------------- STATE --------------------------
  const [form] = Form.useForm();
  const [data, setData] = useState(details);
  const [tab, setTab] = useState("ips");
  // -------------------------- REDUX --------------------------
  const ipsDataByClient = useSelector(ipsListSelector);
  // console.log({ ipsDataByClient });
  const dispatch = useDispatch();
  // -------------------------- FUNCTION --------------------------
  const handleChange = ({ id, value }) => {
    setData({
      ...details,
      [id]: value,
    });
    const update = {
      ...details,
      active: value,
    };
    dispatch(
      updateClient({ client: details, update }, (response) => {
        if (response.status === 200) {
          message.success("Successfully update client");
        } else {
          const error = response.data;
          message.error(error);
        }
      })
    );
  };
  const handleSelectTab = (e) => {
    setTab(e.target.value);
  };
  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    if (!Array.isArray(details) && details) {
      dispatch(snapIpsListByClient({ client: details }));
    }
    return () => dispatch(unSnapIpsListByClient());
  }, [dispatch, details]);

  useEffect(() => {
    setData(details);
    const formData = {
      name: details?.firstName + " " + details?.lastName,
      email: details?.email,
      phone: details?.telephone || details?.phone,
      sex: details?.gender,
    };
    form.setFieldsValue(formData);
  }, [details, form]);

  // -------------------------- RENDER --------------------------
  const renderBody = () => {
    return (
      <>
        <Col xl={24} lg={24} md={24} xs={24}>
          <Form
            name="detail_edit"
            autoComplete="off"
            form={form}
            // onFinish={(onFinish)}
            // onFinishFailed={onFinishFailed}
          >
            {renderTitle()}
            {renderDetail()}
          </Form>
        </Col>
        {/* <Col xl={24} lg={24} md={24} xs={24}>
          <Divider plain>History</Divider>
        </Col> */}
        <Col xl={24} lg={24} md={24} xs={24}>
          {renderHistory()}
        </Col>
        <Col xl={24} lg={24} md={24} xs={24}>
          {renderSelectedTab()}
        </Col>
      </>
    );
  };

  const renderTitle = () => {
    const registeredTime = moment(details?.createdAt?.toDate()).format(
      "MMM DD, YYYY"
    );
    return (
      <Row gutter={[16, 12]}>
        <Col span={8}>
          <Title level={4}>Client Detail</Title>
        </Col>
        <Col span={16}>
          <Title level={4} style={{ fontSize: 14, paddingTop: 10 }}>
            Registered on: {registeredTime}
          </Title>
        </Col>
      </Row>
    );
  };
  const renderDetail = () => {
    return (
      <Row gutter={[16, 12]}>
        <Col xl={4} lg={4} md={24} xs={24}>
          {renderLeftDetail()}
        </Col>
        <Col xl={20} lg={20} md={24} xs={24}>
          {renderRightDetail()}
        </Col>
      </Row>
    );
  };

  const renderLeftDetail = () => {
    return (
      <Row gutter={[16, 0]}>
        <Col xl={24} lg={24} md={24} xs={24}>
          <ClientAvatar data={data} />
        </Col>
        <Col xl={24} lg={24} md={24} xs={24}>
          <ClientActive data={data} onChange={handleChange} />
        </Col>
      </Row>
    );
  };

  const renderRightDetail = () => {
    const disabled = true;
    return (
      <Row gutter={[16, 0]}>
        <Col xl={12} lg={12} md={12} xs={24}>
          <ClientName disabled={disabled} />
        </Col>
        <Col xl={12} lg={12} md={12} xs={24}>
          <ClientSex disabled={disabled} sex={details?.gender} />
        </Col>
        <Col xl={12} lg={12} md={12} xs={24}>
          <ClientEmail disabled={disabled} />
        </Col>
        <Col xl={12} lg={12} md={12} xs={24}>
          <ClientPhone disabled={disabled} />
        </Col>
      </Row>
    );
  };

  const renderHistory = () => {
    return (
      <Radio.Group
        defaultValue="ips"
        buttonStyle="solid"
        style={{ width: "100%", textAlign: "center" }}
        onChange={handleSelectTab}
        size="large"
      >
        <Radio.Button
          value="ips"
          className="radio-group-tabs"
          style={{ width: "33%" }}
        >
          IPS
        </Radio.Button>
        <Radio.Button
          value="tx"
          className="radio-group-tabs"
          style={{ width: "34%" }}
        >
          Topup/Withdraw
        </Radio.Button>
        <Radio.Button
          value="req"
          className="radio-group-tabs"
          style={{ width: "33%" }}
        >
          Request
        </Radio.Button>
      </Radio.Group>
    );
  };

  const renderSelectedTab = () => {
    if (tab === "ips") {
      return ipsDataByClient ? (
        <ClientDetailIps ipsList={ipsDataByClient} />
      ) : (
        <ClientDetailIps ipsList={[]} />
      );
    } else if (tab === "tx") {
      return ipsDataByClient ? (
        <ClientDetailTx ipsList={ipsDataByClient} clientDetail={details} />
      ) : (
        <ClientDetailTx ipsList={[]} clientDetail={details} />
      );
    } else {
      return ipsDataByClient ? (
        <ClientDetailRequest ipsList={ipsDataByClient} clientDetail={details} />
      ) : (
        <ClientDetailRequest ipsList={[]} clientDetail={details} />
      );
    }
  };
  // -------------------------- MAIN --------------------------
  return <Row gutter={[16, 12]}>{renderBody()}</Row>;
};
export default ClientDetail;
