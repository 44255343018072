import React from "react";
import { Form, Radio } from "antd";
const IpsDetailsOnusAccountType = (props) => {
  // -------------------------- VAR --------------------------
  const { setOnusAccountType } = props;
  const ACCOUNT_TYPE = [
    { label: "Phone", value: "onusPhone" },
    { label: "Email", value: "onusMail" },
    { label: "Account ID", value: "onusAccountId" },
  ];
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  const accountOptions = ACCOUNT_TYPE;
  // -------------------------- FUNCTION --------------------------
  const handleChange = (e) => {
    // console.log({ e });
    setOnusAccountType(e.target.value);
  };
  // -------------------------- EFFECT --------------------------

  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="onusAccountType"
      name="onusAccountType"
      label={
        <span>
          Account Type&nbsp;
          <span style={{ color: "red" }}>*</span>
        </span>
      }
      // initialValue="onusPhone"
      rules={[
        {
          required: true,
          message: "required field",
        },
      ]}
    >
      <Radio.Group options={accountOptions} onChange={handleChange} />
    </Form.Item>
  );
};
export default IpsDetailsOnusAccountType;
