import React from "react";
import { Layout } from "antd";
import logo from "../../config/img/logo.png";
import LoginForm from "./component/login_form";
import { BRAND_NAME, WEB_DOMAIN } from "../../config/config";
import "./style.css";
const { Content } = Layout;

const Login = () => {
  // -------------------------- STATE --------------------------

  // -------------------------- FUNCTION --------------------------
  // -------------------------- RENDER --------------------------

  const Copyright = () => {
    return (
      <p variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <a color="inherit" href={WEB_DOMAIN} alt="Copyright">
          {BRAND_NAME}
        </a>{" "}
        {new Date().getFullYear()}
        {"."}
      </p>
    );
  };

  // -------------------------- MAIN --------------------------
  return (
    <Layout className="full">
      <Content className="login ">
        <div className="center">
          <img src={logo} className="login-logo" alt="branch-logo" />
          {<LoginForm />}
        </div>
      </Content>
      <Copyright />
    </Layout>
  );
};

export default Login;
