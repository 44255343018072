import moment from "moment/moment";

export const MODEL_IPS = {
  model: {
    ipsId: "",
    clientId: "",
    clientName: "",
    partnerId: "",
    partnerName: "",
    commissionFee: "",
    type: "",
    startAt: "",
    duration: "",
    fund: "",
    fundCurrency: "USD",
    interest: "",
    profit: "",
    receiveInterest: "wallet",
    bankName: "",
    accountId: "",
    accountName: "",
    currency: "usd",
    onusAccountType: "onusPhone",
    onusMail: "",
    onusPhone: "",
    network: "bep20",
    walletAddress: "",
    pdfFile: "",
    oneOff: "",
    notes: "",
  },
};
