import { getAuth, signOut } from "firebase/auth";
import {
  collection,
  where,
  getFirestore,
  getDocs,
  query,
  onSnapshot,
  setDoc,
  serverTimestamp,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { app } from "../../config/config";
import { TYPES } from "./history.type";
import { REF } from "../ref";
import { FULL_PERMISSION } from "../../store/string";
import { MASTER_EMAIL } from "../../config/config";

const firestore = getFirestore(app);
const auth = getAuth(app);

// -------------------------- Snap List  --------------------------
const subTopupWithdraw = [];
export const snapTopupWithdraw = () => (dispatch) => {
  const uid = auth?.currentUser?.uid;
  const email = auth?.currentUser?.email;
  const cRef = collection(firestore, REF.TOPUP_WITHDRAW);
  const dRef = doc(cRef, uid);
  if (email === MASTER_EMAIL) {
    const data = {
      firstName: "Master",
      lastName: "Account",
      permissions: FULL_PERMISSION,
      active: true,
    };
    getTopupWithdrawDataSuccess(dispatch, data);
    // getPermissionsSuccess(dispatch, data);
    return;
  }
  const unSub = onSnapshot(dRef, (snapshot) => {
    if (snapshot) {
      const data = snapshot.data();
      if (data === undefined) {
        signOut(auth);
      } else {
        getTopupWithdrawDataSuccess(dispatch, data);
        // getPermissionsSuccess(dispatch, data);
      }
    }
  });
  subTopupWithdraw.push(unSub);
};

export const unSnapTopupWithdraw = () => (dispatch) => {
  subTopupWithdraw.forEach((subscriber) => {
    subscriber();
  });
  subTopupWithdraw.length = 0;
};

export const resetTopupWithdraw = (callback) => (dispatch) => {
  getTopupWithdrawDataSuccess(dispatch, null);
};
// -------------------------- Operation List  --------------------------
// Lấy dữ liệu topup withdraw
export const firestoreGetTopupWithdrawList = (callback) => (dispatch) => {
  const cRef = collection(firestore, REF.TOPUP_WITHDRAW);
  getDocs(cRef).then((snapshot) => {
    if (snapshot) {
      const data = snapshot.docs.map((doc) => doc.data());
      console.log("topupWithdraw", data);
      getTopupWithdrawListSuccess(dispatch, data);
    }
    if (callback) {
      callback();
    }
  });
};

// Theo dõi dữ liệu topupWithdraw
const subList = [];
export const snapTopupWithdrawList = (callback) => (dispatch) => {
  const cRef = collection(firestore, REF.TOPUP_WITHDRAW);
  const unSub = onSnapshot(cRef, (snapshot) => {
    if (snapshot) {
      const data = snapshot.docs.map((doc) => doc.data());
      getTopupWithdrawListSuccess(dispatch, data);
      if (callback) {
        callback();
      }
    }
  });
  subList.push(unSub);
};

export const unSnapTopupWithdrawList = () => (dispatch) => {
  subList.forEach((subscriber) => {
    subscriber();
  });
  subList.length = 0;
};

const topupWithdrawListByClientAndIps = [];
export const snapTopupWithdrawListByClientAndIps =
  ({ client }, { ips }, callback) =>
  (dispatch) => {
    const cRef = collection(firestore, REF.TOPUP_WITHDRAW);
    const clientId = client?.id;
    const ipsId = ips?.id;
    const c1 = where("clientId", "==", clientId);
    const c2 = where("ipsId", "==", ipsId);
    const qRef = query(cRef, c1, c2);
    const unSub = onSnapshot(qRef, (snapshot) => {
      if (snapshot) {
        const data = snapshot.docs.map((doc) => doc.data());
        getTopupWithdrawListSuccess(dispatch, data);
        if (callback) {
          callback(data);
        }
      }
    });
    topupWithdrawListByClientAndIps.push(unSub);
  };

export const unSnapTopupWithdrawListByClientAndIps = () => (dispatch) => {
  subList.forEach((subscriber) => {
    subscriber();
  });
  topupWithdrawListByClientAndIps.length = 0;
};

// -------------------------- Operation Add  --------------------------

export const addTopupWithdraw =
  ({ topupWithdraw }, callback) =>
  (dispatch) => {
    console.log({ topupWithdraw });
    // const email = topupWithdraw?.email;
    // const username = topupWithdraw?.username;
    const cRef = collection(firestore, REF.TOPUP_WITHDRAW);
    // const c1 = where("role", "==", "operator");
    // const c2 = where("username", "==", username);
    // const qRef = query(cRef, c2);
    getDocs(cRef)
      .then((snapshot) => {
        if (snapshot) {
          const topupWithdrawList = snapshot.docs.map((doc) => doc.data());
          //   if (topupWithdrawList.length === 0) {
          const id = doc(cRef).id;
          const data = {
            ...topupWithdraw,
            id,
            createAt: serverTimestamp(),
          };

          const dRef = doc(cRef, id);
          setDoc(dRef, data, { merge: true }).then(() => {
            if (callback) {
              callback({
                status: 200,
                data,
              });
            }
          });
          //   } else {
          //     if (callback) {
          //       callback({
          //         status: 500,
          //         data: "Duplicate username",
          //       });
          //     }
          //   }
        }
      })
      .catch((error) => {
        if (callback) {
          callback({
            status: 500,
            data: error.code,
          });
        }
      });
  };

// -------------------------- Operation Update  --------------------------
// Update topupWithdraw
export const updateTopupWithdraw =
  ({ topupWithdraw, update }, callback) =>
  (dispatch) => {
    const cRef = collection(firestore, REF.TOPUP_WITHDRAW);
    const id = topupWithdraw?.id;
    // const email = update.email;
    // const c1 = where("role", "==", "operator");
    const c2 = where("id", "==", topupWithdraw?.id);
    const qRef = query(cRef, c2);
    getDocs(qRef)
      .then((snapshot) => {
        if (snapshot) {
          const topupWithdrawList = snapshot.docs
            .map((doc) => doc.data())
            .filter((item) => item.id !== id);
          if (topupWithdrawList.length === 0) {
            const dRef = doc(cRef, id);
            updateDoc(dRef, update).then(() => {
              selectTopupWithdrawSuccess(dispatch, update);
              if (callback) {
                callback({
                  status: 200,
                  data: update,
                });
              }
            });
          } else {
            if (callback) {
              callback({
                status: 500,
                data: "Duplicate username",
              });
            }
          }
        }
      })
      .catch((error) => {
        if (callback) {
          callback({
            status: 500,
            data: error.code,
          });
        }
      });
  };

// -------------------------- Operation Delete  --------------------------

export const deleteTopupWithdraw =
  ({ topupWithdraw }, callback) =>
  (dispatch) => {
    console.log({ topupWithdraw });
    const cRef = collection(firestore, REF.TOPUP_WITHDRAW);
    const dRef = doc(cRef, topupWithdraw.id);
    deleteDoc(dRef).then(() => {
      if (callback) {
        callback();
      }
    });
  };

// -------------------------- Update info --------------------------

export const firebaseUpdateLoginTime = () => (dispatch) => {
  const cRef = collection(firestore, REF.TOPUP_WITHDRAW);
  const email = auth.currentUser?.email;
  const uid = auth.currentUser?.uid;
  if (email === MASTER_EMAIL) {
    return;
  } else {
    const dRef = doc(cRef, uid);
    const update = {
      onlineAt: serverTimestamp(),
    };
    updateDoc(dRef, update);
  }
};

// -------------------------- Operation Select  --------------------------

export const selectTopupWithdraw =
  ({ detail }, callback) =>
  (dispatch) => {
    console.log("detail action", detail);
    selectTopupWithdrawSuccess(dispatch, detail);
    if (callback) {
      callback();
    }
  };

export const selectAddTopupWithdraw = (callback) => (dispatch) => {
  selectAddSuccess(dispatch);
  if (callback) {
    callback();
  }
};

export const successAddTopupWithdraw = (callback) => (dispatch) => {
  topupWithdrawAddSuccess(dispatch);
  if (callback) {
    callback();
  }
};

export const successDeleteTopupWithdraw = (callback) => (dispatch) => {
  topupWithdrawAddSuccess(dispatch);
  if (callback) {
    callback();
  }
};

// -------------------------- Dispatch  --------------------------

const getTopupWithdrawDataSuccess = (dispatch, topupWithdraw) => {
  dispatch({
    type: TYPES.GET_TOPUP_WITHDRAW_SUCCESS,
    payload: topupWithdraw,
  });
};

const getTopupWithdrawListSuccess = (dispatch, data) => {
  dispatch({
    type: TYPES.TOPUP_WITHDRAW_LIST_SUCCESS,
    payload: data,
  });
};

const selectTopupWithdrawSuccess = (dispatch, data) => {
  dispatch({
    type: TYPES.SELECT_TOPUP_WITHDRAW_SUCCESS,
    payload: data,
  });
};

const topupWithdrawAddSuccess = (dispatch) => {
  dispatch({
    type: TYPES.TOPUP_WITHDRAW_ADD_SUCCESS,
  });
};

const selectAddSuccess = (dispatch) => {
  dispatch({
    type: TYPES.SELECT_TOPUP_WITHDRAW_ADD,
  });
};
