import React from "react";
import { Form, Input } from "antd";
import { MailOutlined } from "@ant-design/icons";

const ClientDetailEmail = (props) => {
  // -------------------------- VAR --------------------------

  const { disabled } = props;

  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- EFFECT --------------------------
  // -------------------------- FUNCTION --------------------------

  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="email"
      name="email"
      label="Email"
      // label={
      //   <span>
      //     Email&nbsp;
      //     <span style={{ color: "red" }}>*</span>
      //   </span>
      // }
      rules={[
        {
          required: false,
          message: "Please input email",
        },
        {
          whitespace: true,
          message: "Invalid",
        },
        {
          type: "email",
          message: "Wrong email format",
        },
      ]}
    >
      <Input
      // addonBefore={<MailOutlined className="form-item-icon" />}
      // placeholder="Email"
      />
    </Form.Item>
  );
};

export default ClientDetailEmail;
