import { TYPES } from "./history.type";

const initState = {
  profitHistory: null,
  profitHistoryList: null,
  status: "add",
  allow: null,
  detail: null,
};

const profitHistoryReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case TYPES.PROFIT_HISTORY_LIST_SUCCESS:
      return {
        ...state,
        profitHistoryList: payload,
      };
    case TYPES.GET_PROFIT_HISTORY_SUCCESS:
      return {
        ...state,
        profitHistory: payload,
      };
    case TYPES.SELECT_PROFIT_HISTORY_SUCCESS:
      return {
        ...state,
        detail: payload,
      };
    case TYPES.SELECT_PROFIT_HISTORY_ADD:
      return {
        ...state,
        detail: null,
      };
    case TYPES.PROFIT_HISTORY_ADD_SUCCESS:
      return {
        ...state,
        detail: null,
        status: "add",
      };
    case TYPES.GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        allow: payload,
      };
    default:
      return state;
  }
};

export default profitHistoryReducer;
