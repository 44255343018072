import { Button } from "antd";

const PartnerDetailEdit = (props) => {
  // --------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------
  const { disabled, setDisabled } = props;
  // ------------------------- FUNCTION --------------------------
  const handleEdit = () => {
    setDisabled(false);
  };

  const handleCancel = () => {
    setDisabled(true);
  };
  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  const renderEdit = () => {
    return (
      <Button type="primary" onClick={handleEdit}>
        Edit
      </Button>
    );
  };

  const renderSaveAndCancel = () => {
    return (
      <div>
        <Button onClick={handleCancel} style={{ marginBottom: 5 }}>
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" style={{ marginLeft: 10 }}>
          Save
        </Button>
      </div>
    );
  };
  // -------------------------- MAIN --------------------------
  return <>{disabled ? renderEdit() : renderSaveAndCancel()}</>;
};

export default PartnerDetailEdit;
