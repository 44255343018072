import React from "react";
import { Form, Button, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
// redux
import { firebaseLogin } from "../../../store/login/login.action";
import { loadingSelector } from "../../../store/login/login.selector";
import { firebaseUpdateLoginTime } from "../../../store/user/user.action";

const LoginFormButton = () => {
  // -------------------------- STATE --------------------------
  const form = Form.useFormInstance();
  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  const loading = useSelector(loadingSelector);
  // -------------------------- FUNCTION --------------------------
  const handleLogin = () => {
    const values = form.getFieldsValue();
    const errors = form.getFieldsError();
    const failed = errors?.some((item) => item?.errors?.length > 0);
    if (!failed) {
      dispatch(
        firebaseLogin({ login: values }, (response) => {
          if (response.status === 200) {
            dispatch(firebaseUpdateLoginTime());
            message.success(response.data);
          } else if (response.status === 500) {
            message.error(response.data);
          }
        })
      );
    }
  };
  // -------------------------- MAIN --------------------------
  return (
    <Button
      type="primary"
      // htmlType="submit"
      className="login-form-button"
      style={{ width: "100%", marginBottom: 10 }}
      onClick={() => handleLogin()}
      loading={loading}
    >
      Login
    </Button>
  );
};

export default LoginFormButton;
