import { TYPES } from "./operator.types";

const initState = {
  operator: null,
  operators: null,
  status: "add",
  allow: null,
  detail: null,
};

const operatorReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case TYPES.OPERATOR_LIST_SUCCESS:
      return {
        ...state,
        operators: payload,
      };
    case TYPES.GET_OPERATOR_SUCCESS:
      return {
        ...state,
        operator: payload,
      };
    case TYPES.SELECT_OPERATOR_SUCCESS:
      return {
        ...state,
        detail: payload,
      };
    case TYPES.SELECT_OPERATOR_ADD:
      return {
        ...state,
        detail: null,
      };
    case TYPES.OPERATOR_ADD_SUCCESS:
      return {
        ...state,
        detail: null,
        status: "add",
      };
    case TYPES.GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        allow: payload,
      };
    default:
      return state;
  }
};

export default operatorReducer;
