import { Card, Col, Divider, Row, message } from "antd";
import React from "react";
import { convertNumber } from "../../../../../util/number.function";
import moment from "moment";
import numeral from "numeral";

const ClientDetailIpsCard = (props) => {
  // -------------------------- VAR --------------------------
  const { data } = props;
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        message.success("Copied to clipboard");
      },
      () => {
        message.error("Failed to copy to clipboard");
      }
    );
  };

  const handleWalletAddress = (str, maxLength) => {
    if (str.length <= maxLength) {
      return str; // No need to truncate
    }

    const startLength = Math.floor((maxLength - 3) / 2); // Length of the start part
    const endLength = maxLength - 3 - startLength; // Length of the end part

    const start = str.slice(0, startLength);
    const end = str.slice(-endLength);

    return `${start}...${end}`;
  };
  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  const renderFirstCol = () => {
    return (
      <Col xl={7} lg={7} md={24} xs={24}>
        {data?.method === "bank" ? (
          <>
            <Row gutter={[0, 10]}>
              <Col xl={24} lg={24} md={24} xs={24}>
                <Row justify="space-between">{renderBankName()}</Row>
              </Col>
              <Col xl={24} lg={24} md={24} xs={24}>
                <Row justify="space-between">{renderBankAccountName()}</Row>
              </Col>
              <Col xl={24} lg={24} md={24} xs={24}>
                <Row justify="space-between">{renderBankAccount()}</Row>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row gutter={[0, 10]}>
              {data?.method === "wallet" ? (
                <>
                  <Col xl={24} lg={24} md={24} xs={24}>
                    <Row justify="space-between">{renderNetwork()}</Row>
                  </Col>
                  <Col xl={24} lg={24} md={24} xs={24}>
                    <Row justify="space-between">{renderWalletAddress()}</Row>
                  </Col>
                </>
              ) : (
                <>
                  <Col xl={24} lg={24} md={24} xs={24}>
                    <Row justify="space-between">{renderOnusNetwork()}</Row>
                  </Col>
                  <Col xl={24} lg={24} md={24} xs={24}>
                    <Row justify="space-between">{renderOnusEmailPhone()}</Row>
                  </Col>
                </>
              )}
            </Row>
          </>
        )}
      </Col>
    );
  };

  const renderSecondCol = () => {
    return (
      <Col xl={7} lg={7} md={24} xs={24}>
        <Row gutter={[0, 10]}>
          <Col xl={24} lg={24} md={24} xs={24}>
            <Row justify="space-between">{renderStartDate()}</Row>
          </Col>
          <Col xl={24} lg={24} md={24} xs={24}>
            <Row justify="space-between">{renderEndDate()}</Row>
          </Col>
          <Col xl={24} lg={24} md={24} xs={24}>
            <Row justify="space-between">{renderDuration()}</Row>
          </Col>
        </Row>
      </Col>
    );
  };

  const renderThirdCol = () => {
    return (
      <Col xl={8} lg={8} md={24} xs={24}>
        <Row gutter={[0, 10]}>
          <Col xl={24} lg={24} md={24} xs={24}>
            <Row justify="space-between">{renderCurrentFund()}</Row>
          </Col>
          <Col xl={24} lg={24} md={24} xs={24}>
            <Row justify="space-between">{renderType()}</Row>
          </Col>
          <Col xl={24} lg={24} md={24} xs={24}>
            <Row justify="space-between">{renderInterestRate()}</Row>
          </Col>
          <Col xl={24} lg={24} md={24} xs={24}>
            <Row justify="space-between">{renderInterest()}</Row>
          </Col>
        </Row>
      </Col>
    );
  };

  const renderNetwork = () => {
    const network = data?.wallet?.network;
    return (
      <>
        <Col>Network: </Col>
        <Col>{network.toUpperCase()}</Col>
      </>
    );
  };

  const renderWalletAddress = () => {
    const walletAddress = data?.wallet?.walletAddress;
    return (
      <>
        <Col>Address: </Col>
        <Col
          onClick={() => handleCopy(walletAddress)}
          style={{ cursor: "pointer" }}
          className="address-hover-color"
        >
          {handleWalletAddress(walletAddress, 10)}
        </Col>
      </>
    );
  };

  const renderOnusNetwork = () => {
    const onusNetwork = data?.method.toUpperCase();
    return (
      <>
        <Col>Network: </Col>
        <Col>{onusNetwork} Off-Chain</Col>
      </>
    );
  };

  const renderOnusEmailPhone = () => {
    const emailPhone = data?.onus?.email
      ? data?.onus?.email
      : data?.onus?.phone;
    const title = data?.onus?.email ? "Email" : "Phone";
    return (
      <>
        <Col>{title}: </Col>
        <Col>{emailPhone}</Col>
      </>
    );
  };

  const renderBankName = () => {
    const bankName = data?.bank?.bankName;
    return (
      <>
        <Col>Bank: </Col>
        <Col>{bankName.toUpperCase()}</Col>
      </>
    );
  };

  const renderBankAccount = () => {
    const bankAccount = data?.bank?.accountId;
    return (
      <>
        <Col>Account Number: </Col>
        <Col>{bankAccount}</Col>
      </>
    );
  };

  const renderBankAccountName = () => {
    const bankAccountName = data?.bank?.accountName;
    return (
      <>
        <Col>Account Name: </Col>
        <Col>{bankAccountName}</Col>
      </>
    );
  };

  const renderType = () => {
    let type = "";
    if (data?.type === "fixed") {
      type = "Fixed-Rate Interest";
    } else {
      type = "Profit Sharing";
    }
    return (
      <>
        <Col>Type: </Col>
        <Col>{type}</Col>
      </>
    );
  };

  const renderDuration = () => {
    const duration = data?.duration;
    return (
      <>
        <Col>Duration:</Col>
        <Col>{duration} months</Col>
      </>
    );
  };

  const renderStartDate = () => {
    const startAt = moment(data?.startAt?.toDate()).format("MMM DD, YYYY");
    return (
      <>
        <Col>Start date:</Col>
        <Col>{startAt}</Col>
      </>
    );
  };
  const renderEndDate = () => {
    const closeAt = moment(data?.startAt?.toDate())
      .add(data?.duration, "months")
      .format("MMM DD, YYYY");
    return (
      <>
        <Col>End date:</Col>
        <Col>{closeAt}</Col>
      </>
    );
  };

  const renderBreak = () => {
    return (
      <Col xl={24} lg={24} md={24} xs={24}>
        {" "}
      </Col>
    );
  };

  const renderInterestRate = () => {
    const interestRate = data?.interest;
    let per = "";
    switch (data?.interestRatePer) {
      case "week":
        per = " per week";
        break;
      case "month":
        per = " per month";
        break;
      default:
        per = " per year";
        break;
    }
    return (
      <>
        <Col>Interest Rate:</Col>
        <Col>
          {interestRate}%{per}
        </Col>
      </>
    );
  };

  const renderInterest = () => {
    let currency = "";
    if (data?.method === "bank") {
      currency = data?.bank?.currency;
    } else {
      currency = "usdt";
    }
    const interest = (data?.fund * convertNumber(data?.interest)) / 100;
    let interestPerWeek = 0;
    switch (data?.interestRatePer) {
      case "week":
        interestPerWeek = interest;
        break;
      case "month":
        interestPerWeek = interest / 4;
        break;
      default:
        interestPerWeek = interest / 52;
        break;
    }
    return (
      <>
        <Col>Interest per week:</Col>
        <Col>
          {numeral(interestPerWeek).format("0,0.000") +
            " " +
            currency.toUpperCase()}
        </Col>
      </>
    );
  };

  const renderCurrentFund = () => {
    let currency = "";
    if (data?.method === "bank") {
      currency = data?.bank?.currency;
    } else {
      currency = "usdt";
    }
    const currentFund = convertNumber(data?.fund);
    return (
      <>
        <Col>Current fund:</Col>
        <Col>{currentFund + " " + currency.toUpperCase()}</Col>
      </>
    );
  };

  const renderDivider = () => {
    return (
      <Col xl={1} lg={1} md={0} xs={0}>
        <Divider type="vertical" style={{ height: "100%" }} />
      </Col>
    );
  };
  // -------------------------- MAIN --------------------------

  return (
    <Card size="small">
      <Row gutter={[10, 10]}>
        {renderFirstCol()}
        {renderDivider()}
        {renderSecondCol()}
        {renderDivider()}
        {renderThirdCol()}
      </Row>
    </Card>
  );
};
export default ClientDetailIpsCard;
