import { getAuth, signOut } from "firebase/auth";
import {
  collection,
  where,
  getFirestore,
  getDocs,
  query,
  onSnapshot,
  setDoc,
  serverTimestamp,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { app } from "../../config/config";
import { TYPES } from "./user.types";
import { REF } from "../ref";
import { ROLE_OPERATOR, FULL_PERMISSION } from "../../store/string";
import { MASTER_EMAIL } from "../../config/config";
// import { TYPES } from "./user.types";

const firestore = getFirestore(app);
const auth = getAuth(app);

// -------------------------- Snap List  --------------------------
const subUser = [];
export const snapUser = () => (dispatch) => {
  const uid = auth?.currentUser?.uid;
  const email = auth?.currentUser?.email;
  const cRef = collection(firestore, REF.OPERATOR);
  const dRef = doc(cRef, uid);
  if (email === MASTER_EMAIL) {
    const data = {
      firstName: "Master",
      lastName: "Account",
      permissions: FULL_PERMISSION,
      active: true,
    };
    getUserDataSuccess(dispatch, data);
    getPermissionsSuccess(dispatch, data);
    return;
  }
  const unSub = onSnapshot(dRef, (snapshot) => {
    if (snapshot) {
      const data = snapshot.data();
      if (data === undefined) {
        signOut(auth);
      } else {
        getUserDataSuccess(dispatch, data);
        getPermissionsSuccess(dispatch, data);
      }
    }
  });
  subUser.push(unSub);
};

export const unSnapUser = () => (dispatch) => {
  subUser.forEach((subscriber) => {
    subscriber();
  });
  subUser.length = 0;
};

export const resetUser = (callback) => (dispatch) => {
  getUserDataSuccess(dispatch, null);
};
// -------------------------- Operation List  --------------------------
// Lấy dữ liệu operator
export const firestoreGetOperatorList = (callback) => (dispatch) => {
  const cRef = collection(firestore, REF.OPERATOR);
  const c1 = where("role", "==", ROLE_OPERATOR);
  const qRef = query(cRef, c1);
  getDocs(qRef).then((snapshot) => {
    if (snapshot) {
      const data = snapshot.docs.map((doc) => doc.data());
      getOperatorListSuccess(dispatch, data);
    }
    if (callback) {
      callback();
    }
  });
};

// Theo dõi dữ liệu operator
const subList = [];
export const snapOperatorList = (callback) => (dispatch) => {
  const cRef = collection(firestore, REF.OPERATOR);
  const c1 = where("role", "==", ROLE_OPERATOR);
  const qRef = query(cRef, c1);
  const unSub = onSnapshot(qRef, (snapshot) => {
    if (snapshot) {
      const data = snapshot.docs.map((doc) => doc.data());
      getOperatorListSuccess(dispatch, data);
      if (callback) {
        callback();
      }
    }
  });
  subList.push(unSub);
};

export const unSnapOperatorList = () => (dispatch) => {
  subList.forEach((subscriber) => {
    subscriber();
  });
  subList.length = 0;
};

// -------------------------- Operation Add  --------------------------

export const addOperator =
  ({ user }, callback) =>
  (dispatch) => {
    const email = user?.email;
    const cRef = collection(firestore, REF.OPERATOR);
    const c1 = where("role", "==", ROLE_OPERATOR);
    const c2 = where("email", "==", email);
    const qRef = query(cRef, c1, c2);
    getDocs(qRef).then((snapshot) => {
      if (snapshot) {
        const userList = snapshot.docs.map((doc) => doc.data());
        if (userList.length === 0) {
          // Chưa có
          const id = doc(cRef).id;
          const data = {
            // tạo new data
            ...user,
            id,
            createAt: serverTimestamp(),
            role: ROLE_OPERATOR,
          };
          const dRef = doc(cRef, id);
          setDoc(dRef, data, { merge: true }).then(() => {
            if (callback) {
              callback(data);
            }
          });
        } else {
          if (callback) {
            callback(false);
          }
        }
      }
    });
  };

// -------------------------- Operation Update  --------------------------
// Update user
export const updateOperator =
  ({ user, update }, callback) =>
  (dispatch) => {
    const cRef = collection(firestore, REF.OPERATOR);
    const id = user.id;
    const email = update.email;
    const c1 = where("role", "==", ROLE_OPERATOR);
    const c2 = where("email", "==", email);
    const qRef = query(cRef, c1, c2);
    getDocs(qRef).then((snapshot) => {
      if (snapshot) {
        const userList = snapshot.docs
          .map((doc) => doc.data())
          .filter((item) => item.id !== id);
        if (userList.length === 0) {
          const dRef = doc(cRef, id);
          updateDoc(dRef, update).then(() => {
            selectOperatorSuccess(dispatch, update);
            if (callback) {
              callback(true);
            }
          });
        } else {
          if (callback) {
            callback(false);
          }
        }
      }
    });
  };

// -------------------------- Operation Delete  --------------------------

export const deleteUser =
  ({ user }, callback) =>
  (dispatch) => {
    // Xóa user
    const cRef = collection(firestore, REF.OPERATOR);
    const dRef = doc(cRef, user.id);
    deleteDoc(dRef).then(() => {
      if (callback) {
        callback();
      }
    });
  };

// export const firestoreImportOperatorByJson =
//   ({ json }, callback) =>
//   (dispatch) => {
//     const userRef = firebase.firestore().collection(REF.OPERATOR);
//     userRef
//       .where("role", "==", ROLE_OPERATOR)
//       .get()
//       .then((snapshot) => {
//         if (snapshot) {
//           const data = snapshot.docs.map((doc) => doc.data());
//           const allPromise =
//             json &&
//             json.map((item) => {
//               const exist = data.find((d) => d.username === item.username);
//               if (exist) {
//                 // tồn tại, lấy id
//                 const id = exist.id;
//                 const newData = {
//                   ...exist,
//                   ...item, // ghi đè
//                   updateAt: firebase.firestore.FieldValue.serverTimestamp(),
//                 };
//                 return userRef.doc(id).update(newData);
//               } else {
//                 // tạo id data
//                 const id = userRef.doc().id;
//                 const newData = {
//                   ...item,
//                   id,
//                   role: ROLE_OPERATOR,
//                   createAt: firebase.firestore.FieldValue.serverTimestamp(),
//                 };
//                 return userRef.doc(id).set(newData, { merge: true });
//               }
//             });
//           allProgress(allPromise, (p) => {
//             const percent = `${p.toFixed(0)}%`;
//             if (p === 100 && callback) {
//               callback("completed");
//             } else {
//               callback(percent);
//             }
//           });
//         }
//       });
//   };

// -------------------------- Update info --------------------------

// Load info mỗi lần tải trang
export const firebaseUpdateLoginTime = () => (dispatch) => {
  const cRef = collection(firestore, REF.OPERATOR);
  const email = auth.currentUser?.email;
  const uid = auth.currentUser?.uid;
  if (email === MASTER_EMAIL) {
    //là master
    return;
  } else {
    // là member
    const dRef = doc(cRef, uid);
    const update = {
      onlineAt: serverTimestamp(),
      logged: true,
    };
    updateDoc(dRef, update);
  }
};

// user logged out
export const firebaseUpdateLoggedOut = (callback) => (dispatch) => {
  const cRef = collection(firestore, REF.OPERATOR);
  const email = auth.currentUser?.email;
  const uid = auth.currentUser?.uid;
  if (email === MASTER_EMAIL) {
    //là master
    callback();
  } else {
    // là member
    const dRef = doc(cRef, uid);
    const update = {
      logged: false,
    };
    updateDoc(dRef, update)
      .then(() => {
        callback();
      })
      .catch((err) => console.log(err));
  }
};

// -------------------------- Operation Select  --------------------------

export const selectOperator =
  ({ detail }, callback) =>
  (dispatch) => {
    selectOperatorSuccess(dispatch, detail);
    if (callback) {
      callback();
    }
  };

export const selectAddOperator = (callback) => (dispatch) => {
  selectAddSuccess(dispatch);
  if (callback) {
    callback();
  }
};

export const successAddOperator = (callback) => (dispatch) => {
  operatorAddSuccess(dispatch);
  if (callback) {
    callback();
  }
};

export const successDeleteOperator = (callback) => (dispatch) => {
  operatorAddSuccess(dispatch);
  if (callback) {
    callback();
  }
};

// -------------------------- Dispatch  --------------------------

const getUserDataSuccess = (dispatch, user) => {
  dispatch({
    type: TYPES.GET_OPERATOR_SUCCESS,
    payload: user,
  });
};

const getPermissionsSuccess = (dispatch, user) => {
  const email = auth?.currentUser?.email;
  const permissions =
    email === MASTER_EMAIL ? FULL_PERMISSION : user?.permissions;
  dispatch({
    type: TYPES.GET_PERMISSIONS_SUCCESS,
    payload: permissions,
  });
};

const getOperatorListSuccess = (dispatch, data) => {
  dispatch({
    type: TYPES.OPERATOR_LIST_SUCCESS,
    payload: data,
  });
};

const selectOperatorSuccess = (dispatch, data) => {
  dispatch({
    type: TYPES.SELECT_OPERATOR_SUCCESS,
    payload: data,
  });
};

const operatorAddSuccess = (dispatch) => {
  dispatch({
    type: TYPES.OPERATOR_ADD_SUCCESS,
  });
};

const selectAddSuccess = (dispatch) => {
  dispatch({
    type: TYPES.SELECT_OPERATOR_ADD,
  });
};

// -------------------------- Util  --------------------------
// const allProgress = (proms, progress_cb) => {
//   let d = 0;
//   progress_cb(0);
//   for (const p of proms) {
//     // eslint-disable-next-line no-loop-func
//     p.then(() => {
//       d++;
//       progress_cb((d * 100) / proms.length);
//     });
//   }
//   return Promise.all(proms);
// };
