import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import PartnerListSearch from "./component/partner_list_search";
import { Loading } from "../../../component/loading";
import PartnerListListing from "./component/partner_list_listing";
import PartnerListCreate from "../dialog/dialog_partner_list_create";
import PartnerListTotal from "./component/partner_list_total";

const PartnerList = (props) => {
  // -------------------------- VAR --------------------------
  const { loading, partnerData, setDetails } = props;
  // -------------------------- STATE --------------------------
  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState("");
  const [open, setOpen] = useState(false);
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  const handleClick = () => {
    setOpen(true);
  };
  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    const lowerSearch = search?.toLowerCase();
    const searchedData =
      search === ""
        ? partnerData
        : partnerData?.filter((item) =>
            `${item?.firstName}${item?.lastName}`
              .toLowerCase()
              ?.includes(lowerSearch)
          );

    setFilterData(searchedData);
  }, [partnerData, search]);

  useEffect(() => {
    console.log({ filterData });
  }, [filterData]);
  // -------------------------- RENDER --------------------------
  const renderTop = () => {
    return (
      <Row gutter={[16, 12]}>
        {renderSearch()}
        {renderAddButton()}
        <PartnerListCreate open={open} setOpen={setOpen} />
      </Row>
    );
  };

  const renderSearch = () => {
    const placeholder = "Search by name...";
    return (
      <Col xl={16} lg={16} md={24} xs={24}>
        <PartnerListSearch
          search={search}
          setSearch={setSearch}
          placeholder={placeholder}
        />
      </Col>
    );
  };

  const renderAddButton = () => {
    return (
      <Col xl={6} lg={6} md={24} xs={24}>
        <Button type="primary" onClick={handleClick}>
          Add partner
        </Button>
      </Col>
    );
  };

  const renderTotal = () => {
    return loading ? (
      <Loading />
    ) : (
      <PartnerListTotal data={filterData} setDetails={setDetails} />
    );
  };

  const renderBottom = () => {
    return loading ? (
      <Loading />
    ) : (
      <PartnerListListing data={filterData} setDetails={setDetails} />
    );
  };
  // -------------------------- MAIN --------------------------
  return (
    <Row gutter={[16, 12]}>
      <Col xl={24} lg={24} md={24} xs={24}>
        {renderTop()}
      </Col>
      <Col xl={24} lg={24} md={24} xs={24}>
        {renderTotal()}
      </Col>
      <Col xl={24} lg={24} md={24} xs={24}>
        {renderBottom()}
      </Col>
    </Row>
  );
};
export default PartnerList;
