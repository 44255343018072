import { getAuth, signOut } from "firebase/auth";
import {
  collection,
  where,
  getFirestore,
  getDocs,
  query,
  onSnapshot,
  setDoc,
  serverTimestamp,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { app } from "../../config/config";
import { TYPES } from "./partner.type";
import { REF } from "../ref";
import { FULL_PERMISSION } from "../string";
import { MASTER_EMAIL } from "../../config/config";

const firestore = getFirestore(app);
const auth = getAuth(app);

// -------------------------- Snap List  --------------------------
const subPartner = [];
export const snapPartner = () => (dispatch) => {
  const uid = auth?.currentUser?.uid;
  const email = auth?.currentUser?.email;
  const cRef = collection(firestore, REF.PARTNER);
  const dRef = doc(cRef, uid);
  if (email === MASTER_EMAIL) {
    const data = {
      firstName: "Master",
      lastName: "Account",
      permissions: FULL_PERMISSION,
      active: true,
    };
    getPartnerDataSuccess(dispatch, data);
    // getPermissionsSuccess(dispatch, data);
    return;
  }
  const unSub = onSnapshot(dRef, (snapshot) => {
    if (snapshot) {
      const data = snapshot.data();
      if (data === undefined) {
        signOut(auth);
      } else {
        getPartnerDataSuccess(dispatch, data);
        // getPermissionsSuccess(dispatch, data);
      }
    }
  });
  subPartner.push(unSub);
};

export const unSnapPartner = () => (dispatch) => {
  subPartner.forEach((subscriber) => {
    subscriber();
  });
  subPartner.length = 0;
};

export const resetPartner = (callback) => (dispatch) => {
  getPartnerDataSuccess(dispatch, null);
};
// -------------------------- Operation List  --------------------------
// Lấy dữ liệu partner
export const firestoreGetPartnerList = (callback) => (dispatch) => {
  const cRef = collection(firestore, REF.PARTNER);
  // const c1 = where("role", "==", "operator");
  // const qRef = query(cRef, c1);
  // getDocs(qRef).then((snapshot) => {
  getDocs(cRef).then((snapshot) => {
    if (snapshot) {
      const data = snapshot.docs.map((doc) => doc.data());
      console.log("partner", data);
      getPartnerListSuccess(dispatch, data);
    }
    if (callback) {
      callback();
    }
  });
};

// Theo dõi dữ liệu partner
const subList = [];
export const snapPartnerList = (callback) => (dispatch) => {
  const cRef = collection(firestore, REF.PARTNER);
  // const c1 = where("role", "==", "operator");
  // const qRef = query(cRef, c1);
  const unSub = onSnapshot(cRef, (snapshot) => {
    if (snapshot) {
      const data = snapshot.docs.map((doc) => doc.data());
      getPartnerListSuccess(dispatch, data);
      if (callback) {
        callback();
      }
    }
  });
  subList.push(unSub);
};

export const unSnapPartnerList = () => (dispatch) => {
  subList.forEach((subscriber) => {
    subscriber();
  });
  subList.length = 0;
};

// -------------------------- Operation Add  --------------------------

export const addPartner =
  ({ partner }, callback) =>
  (dispatch) => {
    console.log({ partner });
    const partnerId = partner?.partnerId;
    const cRef = collection(firestore, REF.PARTNER);
    const c2 = where("partnerId", "==", partnerId);
    const qRef = query(cRef, c2);
    getDocs(qRef)
      .then((snapshot) => {
        if (snapshot) {
          const partnerList = snapshot.docs.map((doc) => doc.data());
          if (partnerList.length === 0) {
            const id = doc(cRef).id;
            const data = {
              ...partner,
              id,
              createdAt: serverTimestamp(),
            };

            const dRef = doc(cRef, id);
            setDoc(dRef, data, { merge: true }).then(() => {
              if (callback) {
                callback({
                  status: 200,
                  data,
                });
              }
            });
          } else {
            if (callback) {
              callback({
                status: 500,
                data: "Duplicate partner ID",
              });
            }
          }
        }
      })
      .catch((error) => {
        if (callback) {
          callback({
            status: 500,
            data: error.code,
          });
        }
      });
  };

// -------------------------- Operation Update  --------------------------
// Update partner
export const updatePartner =
  ({ partner, update }, callback) =>
  (dispatch) => {
    const cRef = collection(firestore, REF.PARTNER);
    const id = partner?.id;
    const c2 = where("partnerId", "==", partner?.partnerId);
    const qRef = query(cRef, c2);
    getDocs(qRef)
      .then((snapshot) => {
        if (snapshot) {
          const partnerList = snapshot.docs
            .map((doc) => doc.data())
            .filter((item) => item.id !== id);
          if (partnerList.length === 0) {
            const dRef = doc(cRef, id);
            updateDoc(dRef, update).then(() => {
              selectPartnerSuccess(dispatch, update);
              if (callback) {
                callback({
                  status: 200,
                  data: update,
                });
              }
            });
          } else {
            if (callback) {
              callback({
                status: 500,
                data: "Duplicate partner ID",
              });
            }
          }
        }
      })
      .catch((error) => {
        if (callback) {
          callback({
            status: 500,
            data: error.code,
          });
        }
      });
  };

// -------------------------- Operation Delete  --------------------------

export const deletePartner =
  ({ partner }, callback) =>
  (dispatch) => {
    console.log({ partner });
    const cRef = collection(firestore, REF.PARTNER);
    const dRef = doc(cRef, partner?.id);
    deleteDoc(dRef).then(() => {
      if (callback) {
        callback();
      }
    });
  };

// -------------------------- Update info --------------------------

export const firebaseUpdateLoginTime = () => (dispatch) => {
  const cRef = collection(firestore, REF.PARTNER);
  const email = auth.currentUser?.email;
  const uid = auth.currentUser?.uid;
  if (email === MASTER_EMAIL) {
    return;
  } else {
    const dRef = doc(cRef, uid);
    const update = {
      onlineAt: serverTimestamp(),
    };
    updateDoc(dRef, update);
  }
};

// -------------------------- Operation Select  --------------------------

export const selectPartner =
  ({ detail }, callback) =>
  (dispatch) => {
    console.log("detail action", detail);
    selectPartnerSuccess(dispatch, detail);
    if (callback) {
      callback();
    }
  };

export const selectAddPartner = (callback) => (dispatch) => {
  selectAddSuccess(dispatch);
  if (callback) {
    callback();
  }
};

export const successAddPartner = (callback) => (dispatch) => {
  partnerAddSuccess(dispatch);
  if (callback) {
    callback();
  }
};

export const successDeletePartner = (callback) => (dispatch) => {
  partnerAddSuccess(dispatch);
  if (callback) {
    callback();
  }
};

// -------------------------- Dispatch  --------------------------

const getPartnerDataSuccess = (dispatch, partner) => {
  dispatch({
    type: TYPES.GET_PARTNER_SUCCESS,
    payload: partner,
  });
};

// const getPermissionsSuccess = (dispatch, partner) => {
//   const email = auth?.currentUser?.email;
//   const permissions =
//     email === MASTER_EMAIL ? FULL_PERMISSION : partner?.permissions;
//   dispatch({
//     type: TYPES.GET_PERMISSIONS_SUCCESS,
//     payload: permissions,
//   });
// };

const getPartnerListSuccess = (dispatch, data) => {
  dispatch({
    type: TYPES.PARTNER_LIST_SUCCESS,
    payload: data,
  });
};

const selectPartnerSuccess = (dispatch, data) => {
  dispatch({
    type: TYPES.SELECT_PARTNER_SUCCESS,
    payload: data,
  });
};

const partnerAddSuccess = (dispatch) => {
  dispatch({
    type: TYPES.PARTNER_ADD_SUCCESS,
  });
};

const selectAddSuccess = (dispatch) => {
  dispatch({
    type: TYPES.SELECT_PARTNER_ADD,
  });
};
