import { getAuth, signOut } from "firebase/auth";
import {
  collection,
  where,
  getFirestore,
  getDocs,
  query,
  onSnapshot,
  setDoc,
  serverTimestamp,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { app } from "../../config/config";
import { TYPES } from "./client.type";
import { REF } from "../ref";
import { FULL_PERMISSION } from "../../store/string";
import { MASTER_EMAIL } from "../../config/config";

const firestore = getFirestore(app);
const auth = getAuth(app);

// -------------------------- Snap List  --------------------------
const subClient = [];
export const snapClient = () => (dispatch) => {
  const uid = auth?.currentUser?.uid;
  const email = auth?.currentUser?.email;
  const cRef = collection(firestore, REF.CLIENT);
  const dRef = doc(cRef, uid);
  if (email === MASTER_EMAIL) {
    const data = {
      firstName: "Master",
      lastName: "Account",
      permissions: FULL_PERMISSION,
      active: true,
    };
    getClientDataSuccess(dispatch, data);
    // getPermissionsSuccess(dispatch, data);
    return;
  }
  const unSub = onSnapshot(dRef, (snapshot) => {
    if (snapshot) {
      const data = snapshot.data();
      if (data === undefined) {
        signOut(auth);
      } else {
        getClientDataSuccess(dispatch, data);
        // getPermissionsSuccess(dispatch, data);
      }
    }
  });
  subClient.push(unSub);
};

export const unSnapClient = () => (dispatch) => {
  subClient.forEach((subscriber) => {
    subscriber();
  });
  subClient.length = 0;
};

export const resetClient = (callback) => (dispatch) => {
  getClientDataSuccess(dispatch, null);
};
// -------------------------- Operation List  --------------------------
// Lấy dữ liệu client
export const firestoreGetClientList = (callback) => (dispatch) => {
  const cRef = collection(firestore, REF.CLIENT);
  // const c1 = where("role", "==", "operator");
  // const qRef = query(cRef, c1);
  // getDocs(qRef).then((snapshot) => {
  getDocs(cRef).then((snapshot) => {
    if (snapshot) {
      const data = snapshot.docs.map((doc) => doc.data());
      console.log("client", data);
      getClientListSuccess(dispatch, data);
    }
    if (callback) {
      callback();
    }
  });
};

// Theo dõi dữ liệu client
const subList = [];
export const snapClientList = (callback) => (dispatch) => {
  const cRef = collection(firestore, REF.CLIENT);
  // const c1 = where("role", "==", "operator");
  // const qRef = query(cRef, c1);
  const unSub = onSnapshot(cRef, (snapshot) => {
    if (snapshot) {
      const data = snapshot.docs.map((doc) => doc.data());
      getClientListSuccess(dispatch, data);
      if (callback) {
        callback();
      }
    }
  });
  subList.push(unSub);
};

export const unSnapClientList = () => (dispatch) => {
  subList.forEach((subscriber) => {
    subscriber();
  });
  subList.length = 0;
};

// -------------------------- Operation Add  --------------------------

export const addClient =
  ({ client }, callback) =>
  (dispatch) => {
    console.log({ client });
    // const email = client?.email;
    const username = client?.username;
    const cRef = collection(firestore, REF.CLIENT);
    // const c1 = where("role", "==", "operator");
    const c2 = where("username", "==", username);
    const qRef = query(cRef, c2);
    getDocs(qRef)
      .then((snapshot) => {
        if (snapshot) {
          const clientList = snapshot.docs.map((doc) => doc.data());
          if (clientList.length === 0) {
            const id = doc(cRef).id;
            const data = {
              ...client,
              id,
              createdAt: serverTimestamp(),
              // role: "operator",
            };

            const dRef = doc(cRef, id);
            setDoc(dRef, data, { merge: true }).then(() => {
              if (callback) {
                callback({
                  status: 200,
                  data,
                });
              }
            });
          } else {
            if (callback) {
              callback({
                status: 500,
                data: "Duplicate username",
              });
            }
          }
        }
      })
      .catch((error) => {
        if (callback) {
          callback({
            status: 500,
            data: error.code,
          });
        }
      });
  };

// -------------------------- Operation Update  --------------------------
// Update client
export const updateClient =
  ({ client, update }, callback) =>
  (dispatch) => {
    const cRef = collection(firestore, REF.CLIENT);
    const id = client?.id;
    // const email = update.email;
    // const c1 = where("role", "==", "operator");
    const c2 = where("username", "==", client?.username);
    const qRef = query(cRef, c2);
    getDocs(qRef)
      .then((snapshot) => {
        if (snapshot) {
          const clientList = snapshot.docs
            .map((doc) => doc.data())
            .filter((item) => item.id !== id);
          if (clientList.length === 0) {
            const dRef = doc(cRef, id);
            updateDoc(dRef, update).then(() => {
              selectClientSuccess(dispatch, update);
              if (callback) {
                callback({
                  status: 200,
                  data: update,
                });
              }
            });
          } else {
            if (callback) {
              callback({
                status: 500,
                data: "Duplicate Email Address",
              });
            }
          }
        }
      })
      .catch((error) => {
        if (callback) {
          callback({
            status: 500,
            data: error.code,
          });
        }
      });
  };

// -------------------------- Operation Delete  --------------------------

export const deleteClient =
  ({ client }, callback) =>
  (dispatch) => {
    console.log({ client });
    const cRef = collection(firestore, REF.CLIENT);
    const dRef = doc(cRef, client.id);
    deleteDoc(dRef).then(() => {
      if (callback) {
        callback();
      }
    });
  };

// -------------------------- Update info --------------------------

export const firebaseUpdateLoginTime = () => (dispatch) => {
  const cRef = collection(firestore, REF.CLIENT);
  const email = auth.currentUser?.email;
  const uid = auth.currentUser?.uid;
  if (email === MASTER_EMAIL) {
    return;
  } else {
    const dRef = doc(cRef, uid);
    const update = {
      onlineAt: serverTimestamp(),
    };
    updateDoc(dRef, update);
  }
};

// -------------------------- Operation Select  --------------------------

export const selectClient =
  ({ detail }, callback) =>
  (dispatch) => {
    console.log("detail action", detail);
    selectClientSuccess(dispatch, detail);
    if (callback) {
      callback();
    }
  };

export const selectAddClient = (callback) => (dispatch) => {
  selectAddSuccess(dispatch);
  if (callback) {
    callback();
  }
};

export const successAddClient = (callback) => (dispatch) => {
  clientAddSuccess(dispatch);
  if (callback) {
    callback();
  }
};

export const successDeleteClient = (callback) => (dispatch) => {
  clientAddSuccess(dispatch);
  if (callback) {
    callback();
  }
};

// -------------------------- Dispatch  --------------------------

const getClientDataSuccess = (dispatch, client) => {
  dispatch({
    type: TYPES.GET_CLIENT_SUCCESS,
    payload: client,
  });
};

// const getPermissionsSuccess = (dispatch, client) => {
//   const email = auth?.currentUser?.email;
//   const permissions =
//     email === MASTER_EMAIL ? FULL_PERMISSION : client?.permissions;
//   dispatch({
//     type: TYPES.GET_PERMISSIONS_SUCCESS,
//     payload: permissions,
//   });
// };

const getClientListSuccess = (dispatch, data) => {
  dispatch({
    type: TYPES.CLIENT_LIST_SUCCESS,
    payload: data,
  });
};

const selectClientSuccess = (dispatch, data) => {
  dispatch({
    type: TYPES.SELECT_CLIENT_SUCCESS,
    payload: data,
  });
};

const clientAddSuccess = (dispatch) => {
  dispatch({
    type: TYPES.CLIENT_ADD_SUCCESS,
  });
};

const selectAddSuccess = (dispatch) => {
  dispatch({
    type: TYPES.SELECT_CLIENT_ADD,
  });
};
