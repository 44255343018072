import React from "react";
import { Form, Input } from "antd";
import { MailOutlined } from "@ant-design/icons";

const ClientEmail = (props) => {
  // -------------------------- VAR --------------------------

  const { disabled } = props;

  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- EFFECT --------------------------
  // -------------------------- FUNCTION --------------------------

  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      name="email"
      rules={[
        {
          required: true,
          message: "Please input email",
        },
        {
          whitespace: true,
          message: "Invalid",
        },
        {
          type: "email",
          message: "Wrong email format",
        },
      ]}
    >
      <Input
        addonBefore={<MailOutlined className="form-item-icon" />}
        placeholder="Email"
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default ClientEmail;
