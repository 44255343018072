import { Form, InputNumber } from "antd";
import React from "react";
const ClientTopupWithdrawWalletAmount = (props) => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="walletAmount"
      name="walletAmount"
      label="Amount"
      rules={[
        {
          required: true,
          message: "required field",
        },
      ]}
    >
      <InputNumber style={{ width: "100%" }} addonAfter="USDT" />
    </Form.Item>
  );
};
export default ClientTopupWithdrawWalletAmount;
