import React, { useEffect } from "react";
import { Form, Select, Row, Col, Input } from "antd";
import { useSelector } from "react-redux";
import {
  snapClientList,
  unSnapClientList,
} from "../../../../store/client/client.action";
import { clientListSelector } from "../../../../store/client/client.selector";
import { useDispatch } from "react-redux";
import { UserOutlined } from "@ant-design/icons";

const PartnerDetailClient = (props) => {
  // -------------------------- VAR --------------------------
  const { disabled, setPartnerInfo } = props;
  // -------------------------- STATE --------------------------

  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  const clientList = useSelector(clientListSelector);
  console.log({ clientList });
  // -------------------------- VAR --------------------------
  const clientOptions = clientList?.map((client) => ({
    label: client.firstName + " " + client.lastName + " - " + client.email,
    value: client.username,
  }));
  // const clientId = form?.getFieldValue("partnerInfo");
  // const client = clientList?.find((client) => client.username === clientId);
  // -------------------------- FUNCTION --------------------------
  // setClientName(client?.firstName + " " + client?.lastName);

  const handleChange = (value, option) => {
    // setClientId(value);
    const client = clientList?.find((client) => client.username === value);
    setPartnerInfo(client);
    console.log(option);
  };
  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    dispatch(snapClientList());
    return () => dispatch(unSnapClientList());
  }, [dispatch]);
  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------
  return (
    <Row gutter={[2, 0]}>
      <Col xl={3} lg={3} md={3} xs={3}>
        {/* <Input value="Network" disabled={true} /> */}
        <Input
          prefix={<UserOutlined className="form-item-icon" />}
          disabled={true}
        />
      </Col>
      <Col xl={21} lg={21} md={21} xs={21}>
        <Form.Item
          id="clientId"
          name="clientId"
          placeholder="Select Partner"
          rules={[
            {
              required: true,
              message: "required field",
            },
          ]}
        >
          <Select
            options={clientOptions}
            placeholder="Select Partner"
            onChange={handleChange}
            disabled={disabled}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};
export default PartnerDetailClient;
