import React from "react";
import { Form, Radio } from "antd";
import { CURRENCY } from "../../../../../store/data/ips_mgmt";
const IpsDetailsCurrency = (props) => {
  // -------------------------- VAR --------------------------
  const { setCurrency } = props;
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  const currencyOptions = CURRENCY;
  // -------------------------- FUNCTION --------------------------
  const handleChange = (e) => {
    setCurrency(e.target.value);
  };
  // -------------------------- EFFECT --------------------------

  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="currency"
      name="currency"
      label={
        <span>
          Currency&nbsp;
          <span style={{ color: "red" }}>*</span>
        </span>
      }
      initialValue="usd"
      rules={[
        {
          required: true,
          message: "required field",
        },
      ]}
    >
      <Radio.Group
        options={currencyOptions}
        defaultValue="usd"
        placeholder="Currency"
        onChange={handleChange}
      />
    </Form.Item>
  );
};
export default IpsDetailsCurrency;
