import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// component
import { LoadingScreen } from "../../component/loading";
import { routePublict } from "./route/router_publict";
import { routerPrivate } from "./route/router_private";
// redux
import {
  snapOperatorList,
  unSnapOperatorList,
} from "../../store/operator/operator.action";
import { snapAuth, unSnapAuth } from "../../store/auth/auth.action";
import { isLoggedSelector } from "../../store/auth/auth.selector";
import { snapUser, unSnapUser, resetUser } from "../../store/user/user.action";

const Router = () => {
  // -------------------------- STATE --------------------------
  const logged = useSelector(isLoggedSelector);

  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    dispatch(snapAuth());

    return () => {
      dispatch(unSnapAuth());
    };
  }, [dispatch]);

  useEffect(() => {
    if (logged === true) {
      dispatch(snapUser());
      dispatch(snapOperatorList());
    }
    if (logged === false) {
      dispatch(resetUser());
    }
    return () => {
      dispatch(unSnapUser());
      dispatch(unSnapOperatorList());
    };
  }, [dispatch, logged]);

  // -------------------------- FUNCTION --------------------------
  // -------------------------- MAIN --------------------------
  if (logged === null) {
    return <LoadingScreen />;
  }
  return (
    <BrowserRouter>
      <Routes>
        {logged ? routerPrivate() : routePublict()}
        <Route path="*" element={<Navigate to={"/"} exact replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
