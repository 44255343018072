import React from "react";
import { Form, Radio, Select } from "antd";
import { NETWORK } from "../../../../../store/data/ips_mgmt";
const IpsDetailsNetwork = (props) => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  const networkOptions = NETWORK;
  // -------------------------- FUNCTION --------------------------

  // -------------------------- EFFECT --------------------------

  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="network"
      name="network"
      label={
        <span>
          Network&nbsp;
          <span style={{ color: "red" }}>*</span>
        </span>
      }
      initialValue="bep20"
      rules={[
        {
          required: true,
          message: "required field",
        },
      ]}
    >
      <Radio.Group
        options={networkOptions}
        // allowClear
        // showSearch
        // optionFilterProp="label"
      />
    </Form.Item>
  );
};
export default IpsDetailsNetwork;
