import React from "react";
import { Input } from "antd";

const { Search } = Input;

const OperatorListSearch = (props) => {
  // -------------------------- STATE --------------------------
  // -------------------------- FUNCTION --------------------------
  const onChange = (e) => {
    const value = e.target.value;
    props.onChange(value);
  };
  // -------------------------- DATA FUNCTION --------------------------
  // -------------------------- FIREBASE FUNCTION --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Search
      placeholder="Enter name to search"
      onChange={onChange}
      style={{
        width: "100%",
      }}
    />
  );
};

export default OperatorListSearch;
