import React, { useEffect } from "react";
import { Form, Select } from "antd";
import { useSelector } from "react-redux";
import {
  snapClientList,
  unSnapClientList,
} from "../../../../../store/client/client.action";
import { clientListSelector } from "../../../../../store/client/client.selector";
import { useDispatch } from "react-redux";
// import { useState } from "react";

const PartnerDetailsClient = (props) => {
  // -------------------------- VAR --------------------------
  // const { setClientId, setClientName, form } = props;
  const { setPartnerInfo } = props;
  // -------------------------- STATE --------------------------

  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  const clientList = useSelector(clientListSelector);
  console.log({ clientList });
  // -------------------------- VAR --------------------------
  const clientOptions = clientList?.map((client) => ({
    label: client.firstName + " " + client.lastName + " - " + client.email,
    value: client.username,
  }));
  // const clientId = form?.getFieldValue("partnerInfo");
  // const client = clientList?.find((client) => client.username === clientId);
  // -------------------------- FUNCTION --------------------------
  // setClientName(client?.firstName + " " + client?.lastName);

  const handleChange = (value, option) => {
    // setClientId(value);
    const client = clientList?.find((client) => client.username === value);
    setPartnerInfo(client);
    console.log(option);
  };
  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    dispatch(snapClientList());
    return () => dispatch(unSnapClientList());
  }, [dispatch]);
  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="clientId"
      name="clientId"
      label={
        <span>
          Partner&nbsp;
          <span style={{ color: "red" }}>*</span>
        </span>
      }
      rules={[
        {
          required: true,
          message: "required field",
        },
      ]}
    >
      <Select
        options={clientOptions}
        placeholder="Select Partner"
        onChange={handleChange}
      />
    </Form.Item>
  );
};
export default PartnerDetailsClient;
