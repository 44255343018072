import React, { useEffect, useState } from "react";
import { Layout, Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import LayoutHeader from "../00_Nav/layout/layout_header";
import LayoutBreadCrum from "../00_Nav/layout/layout_bread_crum";
import { URL } from "../00_Nav/url";
import { setHighlight } from "../../store/nav/nav.action";
import { snapIpsList, unSnapIpsList } from "../../store/ips/ips.action";
import { ipsListSelector } from "../../store/ips/ips.selector";
import IpsDetailCreate from "./dialog/dialog_ips_details_create";
import IpsTop from "./component/ips_top";
import IpsTable from "./component/ips_table";

const { Content } = Layout;

const IpsScreen = () => {
  // -------------------------- VAR --------------------------
  const breadCrumb = [
    { title: "Home", url: URL.home },
    { title: "IPS Management", url: URL.ipsManagement },
  ];
  // -------------------------- STATE --------------------------
  const [search, setSearch] = useState("");
  const [type, setType] = useState("all");
  const [duration, setDuration] = useState("all");
  const [profit, setProfit] = useState("all");
  const [open, setOpen] = useState(false);
  const [data1, setData] = useState([]);
  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  const ipsData = useSelector(ipsListSelector);
  console.log({ ipsData });
  // -------------------------- FUNCTION --------------------------
  // const handleSearch = () => {
  //   const lowerSearch = search.toLowerCase();
  //   const refineData = ipsData
  //     ?.filter((item) => type === "all" || item?.type === type)
  //     ?.filter((item) => duration === "all" || item?.duration === duration)
  //     ?.filter((item) => profit === "all" || item?.profit === profit)
  //     ?.filter((item) => item?.id.toLowerCase().includes(lowerSearch));
  //   setData(refineData);
  // };
  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    dispatch(setHighlight(["9"]));
  }, [dispatch]);

  useEffect(() => {
    dispatch(snapIpsList());
    return () => dispatch(unSnapIpsList());
  }, [dispatch]);

  useEffect(() => {
    const lowerSearch = search?.toLowerCase();
    let filterData = ipsData;
    if (type !== "all") {
      filterData = filterData?.filter((item) => item?.type === type);
    }
    console.log({ duration });
    if (duration !== "all") {
      console.log(
        "new filter",
        filterData?.filter(
          (item) => (item?.duration).toString() === duration.toString()
        )
      );
      filterData = filterData?.filter(
        (item) => (item?.duration).toString() === duration.toString()
      );
    }
    if (profit !== "all") {
      filterData = filterData?.filter((item) => item?.shareProfit === profit);
    }
    if (search && search !== "") {
      filterData = filterData?.filter((item) =>
        item?.toLowerCase().includes(lowerSearch)
      );
    }
    console.log({ filterData });
    setData(filterData);
  }, [ipsData, search, type, duration, profit]);
  // -------------------------- RENDER --------------------------
  const renderBody = () => {
    return (
      <Row gutter={[16, 12]} span={24}>
        {renderTop()}
        {renderTable()}
        <IpsDetailCreate open={open} setOpen={setOpen} />
      </Row>
    );
  };

  const renderTop = () => {
    return (
      <Col span={24}>
        <IpsTop
          search={search}
          setSearch={setSearch}
          type={type}
          setType={setType}
          duration={duration}
          setDuration={setDuration}
          profit={profit}
          setProfit={setProfit}
          setOpen={setOpen}
        />
      </Col>
    );
  };

  const renderTable = () => {
    return (
      <Col span={24}>
        <IpsTable data={data1} />
      </Col>
    );
  };
  // -------------------------- MAIN --------------------------
  return (
    <Layout className="site-layout full">
      <LayoutHeader title="IPS Management" />
      <LayoutBreadCrum data={breadCrumb} />
      <Content className="content">{renderBody()}</Content>
    </Layout>
  );
};

export default IpsScreen;
