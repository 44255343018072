import { Button, Card, Table, message } from "antd";
import moment from "moment";
import numeral from "numeral";
import { Timestamp } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addProfitHistory,
  selectProfitHistory,
  snapProfitHistoryList,
  unSnapProfitHistoryList,
} from "../../../../../store/shared_profit_history/history.action";
import { profitHistoryListSelector } from "../../../../../store/shared_profit_history/history.selector";
import ClientDetailIpsHistoryCreateUpdate from "./component/client_details_ips_history_create_update";

const ClientDetailIpsHistory = (props) => {
  // -------------------------- VAR --------------------------
  const { ips, client, ipsList } = props;
  // -------------------------- STATE --------------------------
  const [daysLeft, setDaysLeft] = useState(7);
  // const [isSaturday9AM, setIsSaturday9AM] = useState(false);
  // const [countdownActive, setCountdownActive] = useState(false);
  // const [daysCountdown, setDaysCountdown] = useState(true);
  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  const profitHistory = useSelector(profitHistoryListSelector);
  const data = profitHistory?.filter(
    (history) => history?.ipsId === ips && history?.clientId === client
  );
  const sortedData = data?.sort((a, b) => b.datetime - a.datetime);
  // console.log({ sortedData });
  // -------------------------- FUNCTION --------------------------
  const convertTimestampToDate = (inputTimestamp) => {
    // return moment.unix(inputTimestamp.seconds);
    return new Date(inputTimestamp?.seconds * 1000);
  };

  const handleDateTime = (date) => {
    const jsDate = date.toDate();

    // Create a Firestore Timestamp from the JavaScript Date object
    return Timestamp.fromDate(jsDate);
  };

  const handleAmount = () => {
    const interest = ipsList.find(
      (ipsDetail) => ipsDetail.id === ips
    )?.interest;
    const fund = ipsList.find((ipsDetail) => ipsDetail.id === ips)?.fund;
    const amount = (interest * fund) / 100;

    let interestPerWeek = 0;
    switch (data?.interestRatePer) {
      case "week":
        interestPerWeek = amount;
        break;
      case "month":
        interestPerWeek = amount / 4;
        break;
      default:
        interestPerWeek = amount / 52;
        break;
    }
    return interestPerWeek;
  };

  const handleCurrency = () => {
    const ipsItem = ipsList.find((ipsDetail) => ipsDetail.id === ips);
    let currency = "";
    if (ipsItem?.method === "bank") {
      currency = ipsItem?.bank?.currency;
    } else {
      currency = "usdt";
    }
    return currency.toUpperCase();
  };

  const getNextSaturday = () => {
    const now = moment();
    let daysUntilSaturday = 6 - now.day();
    if (daysUntilSaturday <= 0) {
      daysUntilSaturday = 7;
    }
    const nextSaturday = now
      .clone()
      .add(daysUntilSaturday, "days")
      .startOf("day");

    return nextSaturday;
  };

  // const addNewTransaction = () => {
  //   const transaction = {
  //     status: "undeposited",
  //     clientId: client,
  //     ipsId: ips,
  //     backupId: ipsList?.filter((ipsDetail) => ipsDetail.id === ips)[0]?.ipsId,
  //     datetime: handleDateTime(getNextSaturday()),
  //     amount: handleAmount(),
  //   };

  //   dispatch(
  //     addProfitHistory({ profitHistory: transaction }, (response) => {
  //       if (response.status === 200) {
  //         setDaysLeft(7);
  //         const detail = response.data;
  //         dispatch(selectProfitHistory({ detail }));
  //         message.success("Successfully added transaction");
  //       } else {
  //         const error = response.data;
  //         message.error(error);
  //       }
  //     })
  //   );
  // };

  const getColumns = () => {
    const columns = [
      {
        key: "date",
        dataIndex: "datetime",
        title: "Date",
        align: "center",
        sorter: (a, b) => a.datetime - b.datetime,
        render: (values) => (
          <div style={{ textAlign: "left", paddingLeft: 10 }}>
            {moment(values.toDate()).format("MMM DD, YYYY")}
          </div>
        ),
        width: "25%",
      },
      {
        key: "sharedProfit",
        dataIndex: "amount",
        title: "Shared profit",
        align: "center",
        width: "25%",
        render: (value) =>
          value
            ? // <div style={{ textAlign: "right", paddingRight: "30%" }}>
              alignRight(
                numeral(value).format("0,0.000") + " " + handleCurrency()
              )
            : // </div>
              "0",
      },
      {
        key: "status",
        dataIndex: "status",
        title: "Status",
        align: "center",
        width: "25%",
        render: (value, record) => {
          if (value === "undeposited") {
            return (
              <div style={{ textAlign: "center", paddingLeft: "20%" }}>
                {moment().isSameOrAfter(moment(record?.datetime?.toDate())) ? (
                  <>Pending</>
                ) : daysLeft !== 0 && daysLeft !== 1 ? (
                  <>{daysLeft} days left</>
                ) : (
                  <>{daysLeft} day left</>
                )}
              </div>
            );
          } else {
            return (
              <div
                style={{
                  textAlign: "center",
                  paddingLeft: "20%",
                  color: "green",
                  // fontWeight: "bold",
                }}
              >
                {value.charAt(0).toUpperCase() + value.slice(1)}
              </div>
            );
          }
        },
      },
      {
        key: "action",
        dataIndex: "status",
        title: "Action",
        align: "center",
        width: "25%",
        render: (value, record) => {
          if (value === "undeposited") {
            return (
              <div style={{ textAlign: "center", paddingLeft: "20%" }}>
                {moment().isSameOrAfter(moment(record?.datetime?.toDate())) ? (
                  <ClientDetailIpsHistoryCreateUpdate
                    record={record}
                    client={client}
                    ipsId={ips}
                    ipsList={ipsList}
                    setDaysLeft={setDaysLeft}
                    // setDaysCountdown={setDaysCountdown}
                    disabled={false}
                  />
                ) : (
                  <ClientDetailIpsHistoryCreateUpdate
                    record={record}
                    client={client}
                    ipsId={ips}
                    ipsList={ipsList}
                    setDaysLeft={setDaysLeft}
                    // setDaysCountdown={setDaysCountdown}
                    disabled={true}
                  />
                )}
              </div>
            );
          }
        },
      },
    ];
    return columns;
  };
  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    dispatch(snapProfitHistoryList());
    return () => unSnapProfitHistoryList();
  }, [dispatch]);

  useEffect(() => {
    if (data) {
      // Calculate the time remaining until the next day (00:00:00)
      const sortedData = data?.sort((a, b) => b.datetime - a.datetime);
      const targetTimestamp = sortedData?.filter(
        (history) => history.status === "undeposited"
      )?.[0]?.datetime;

      const targetDate = convertTimestampToDate(targetTimestamp);
      const currentDate = new Date();
      let daysRemaining = (targetDate - currentDate) / (24 * 60 * 60 * 1000);
      // console.log({ daysRemaining });
      if (daysRemaining > 6) {
        setDaysLeft(Math.ceil(daysRemaining));
      } else {
        daysRemaining = 6 - currentDate.getDay();
        // if (daysRemaining <= 0) {
        //   daysRemaining = 7;
        // }
        setDaysLeft(daysRemaining);
      }
      const nextDate = new Date(currentDate);
      nextDate.setHours(24, 0, 0, 0); // Set to the next day (00:00:00)
      nextDate.setMilliseconds(0); // Clear milliseconds
      const timeLeft = nextDate - currentDate;

      const updateDaysLeft = () => {
        const now = new Date();
        let newDaysLeft = (targetDate - now) / (24 * 60 * 60 * 1000);
        if (newDaysLeft > 7) {
          setDaysLeft(Math.ceil(newDaysLeft));
        } else {
          newDaysLeft = 6 - now.getDay();
          // if (newDaysLeft <= 0) {
          //   newDaysLeft = 7;
          // }
          setDaysLeft(newDaysLeft);
        }

        if (newDaysLeft > 0) {
          // Schedule the next update when the new day begins
          const count24h = 24 * 60 * 60 * 1000; // 24 hours
          setTimeout(updateDaysLeft, count24h);
        }
        // else {
        //   addNewTransaction();
        // }
      };
      setTimeout(updateDaysLeft, timeLeft); // Update when the new day begins
    }
  }, [data]);

  // useEffect(() => {
  //   let intervalId;

  //   const checkIsSaturday9AM = () => {
  //     const currentDate = moment();
  //     const saturday9AM = moment().day(6).hour(9).minute(0).second(0);
  //     // const saturday9AM = moment().minute(56);

  //     if (currentDate.isSame(saturday9AM)) {
  //       setCountdownActive(false); // Stop the countdown
  //     }
  //     // else {
  //     //   setIsSaturday9AM(false);
  //     // }
  //   };
  //   checkIsSaturday9AM();
  //   if (countdownActive) {
  //     // Initially check and start the countdown
  //     // setIsSaturday9AM(false);

  //     intervalId = setInterval(checkIsSaturday9AM, 60000); // Check every 1 minute
  //   }

  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [countdownActive]);
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  const columns = getColumns();
  return (
    <Card size="small" className="responsive-card">
      <Table
        columns={columns}
        size="small"
        dataSource={sortedData}
        className="history-table responsive-table"
        rowKey={(record) => record.id}
        scroll={{ x: 512 }}
      />
    </Card>
  );
};
export default ClientDetailIpsHistory;

const alignLeft = (value) => {
  return (
    <div
      style={{
        textAlign: "left",
        paddingLeft: 20,
      }}
    >
      {value}
    </div>
  );
};

const alignRight = (value) => {
  return (
    <div
      style={{
        textAlign: "right",
        paddingLeft: 20,
      }}
    >
      {value}
    </div>
  );
};
