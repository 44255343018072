import React from "react";
import { Outlet } from "react-router-dom";
import { Layout, Grid } from "antd";
import { useSelector } from "react-redux";
import "./style.css";
import logo from "../../../config/img/logo.png";
// component
import LayoutMenu from "./layout_menu";
// redux
import { collapseSelector } from "../../../store/nav/nav.selector";

const { useBreakpoint } = Grid;
const { Sider } = Layout;

const LayoutRoot = () => {
  // -------------------------- VAR --------------------------
  const screens = useBreakpoint();
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  const collapsed = useSelector(collapseSelector);
  // -------------------------- EFFECT --------------------------

  // -------------------------- FUNCTION --------------------------

  // -------------------------- MAIN --------------------------

  return (
    <Layout>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        collapsedWidth={screens.xs ? 0 : 80}
        width={230}
      >
        <div className="logo">
          <img src={logo} alt="logo" className="logo-img" />
        </div>
        <LayoutMenu />
      </Sider>
      <Outlet />
    </Layout>
  );
};

export default LayoutRoot;
