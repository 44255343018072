import { Col, Row, Select, Button, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { TX_HISTORY } from "../../../../store/data/client_mgmt";
import ClientDetailTxHistory from "./component/client_detail_tx_history";
import ClientDetailIpsCard from "./component/client_detail_ips_card";
import ClientTopupWithdrawCreate from "../../dialog/dialog_client_topup_withdraw_create";
const { Title, Text } = Typography;

const ClientDetailTx = (props) => {
  // -------------------------- VAR --------------------------
  const { ipsList, clientDetail } = props;
  // console.log("ipsList tx", ipsList);
  const ipsOptions = ipsList?.map((ips) => ({
    label: ips?.ipsId.toUpperCase(),
    value: ips?.id,
  }));
  // console.log(ipsList.find((ips) => ips?.id));
  // -------------------------- STATE --------------------------
  const [open, setOpen] = useState(false);
  const [ips, setIps] = useState("");
  const [client, setClient] = useState(null);
  // -------------------------- REDUX --------------------------
  const data = ipsList;
  const dataHistory = TX_HISTORY;
  // -------------------------- FUNCTION --------------------------
  const handleClick = () => {
    setOpen(true);
  };
  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    if (ipsList.length > 0) {
      setClient(ipsList[0]?.clientId);
      if (ipsOptions.length > 0) {
        setIps(ipsOptions[0]?.value);
      }
    } else {
      setIps("No IPS yet");
    }
  }, [ipsList]);
  // -------------------------- RENDER --------------------------
  const renderTop = () => {
    const options = ipsOptions;
    return (
      <Col xl={24} lg={24} md={24} xs={24}>
        <Row align="middle">
          <Col xl={2} lg={2} md={2} xs={2}>
            IPS:
          </Col>
          <Col xl={12} lg={12} md={12} xs={12}>
            {options.length > 0 ? (
              <Select options={options} onSelect={setIps} value={ips} />
            ) : (
              <Text style={{ fontSize: 15, fontStyle: "italic" }}>{ips}</Text>
            )}
          </Col>
          <Col xl={10} lg={10} md={10} xs={10} align="right">
            <Button type="primary" onClick={handleClick}>
              Add Transaction
            </Button>
          </Col>
        </Row>
      </Col>
    );
  };

  const renderTxCard = () => {
    const itemData = data.find((item) => item.id === ips);
    return (
      <>
        {itemData ? (
          <Col span={24}>
            <ClientDetailIpsCard data={itemData} />
          </Col>
        ) : null}
      </>
    );
  };

  const renderHistory = () => {
    return (
      <>
        <Col span={24}>
          <Title style={{ fontSize: 16 }}>Transaction history</Title>
        </Col>
        <Col xl={24} lg={24} md={24} xs={24}>
          <ClientDetailTxHistory
            data={dataHistory}
            ips={ips}
            client={client}
            method={ipsList?.find((ipsDetail) => ipsDetail?.id === ips)?.method}
          />
        </Col>
      </>
    );
  };

  const renderModal = () => {
    return (
      <ClientTopupWithdrawCreate
        open={open}
        setOpen={setOpen}
        client={client}
        ips={ips}
        method={ipsList?.find((ipsDetail) => ipsDetail?.id === ips)?.method}
        ipsList={ipsList}
        clientDetail={clientDetail}
      />
    );
  };
  // -------------------------- MAIN --------------------------
  return (
    <Row gutter={[16, 16]}>
      {renderTop()}
      {renderTxCard()}
      {renderHistory()}
      {renderModal()}
    </Row>
  );
};
export default ClientDetailTx;
