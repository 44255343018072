import React, { useState } from "react";
import { message, Upload, Button, Form, Row, Col, Input } from "antd";
import { BookOutlined } from "@ant-design/icons";

const PartnerDetailFile = (props) => {
  // -------------------------- VAR --------------------------

  const { file, setFile, disabled } = props;
  // -------------------------- STATE --------------------------

  // -------------------------- REDUX --------------------------

  // -------------------------- EFFECT --------------------------

  // -------------------------- FUNCTION --------------------------
  const beforeUpload = (file) => {
    const isPdf = file.type === "application/pdf";
    if (!isPdf) {
      message.error("You can only upload PDF file!");
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error("Image must smaller than 5MB!");
    }
    setFile(file);
    return isPdf && isLt5M;
  };
  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------
  return (
    <Row gutter={[2, 0]}>
      <Col xl={3} lg={3} md={3} xs={3}>
        <Input
          prefix={<BookOutlined className="form-item-icon" />}
          disabled={true}
        />
      </Col>
      <Col xl={21} lg={21} md={21} xs={21}>
        <Row>
          <Col md={24} lg={24} xl={24} sm={24}>
            <Form.Item
              id="pdfFile"
              name="pdfFile"
              rules={[
                {
                  required: true,
                  message: "required field",
                },
              ]}
            >
              <Upload
                name="pdfFile"
                accept=".pdf"
                showUploadList={true}
                maxCount={1}
                beforeUpload={beforeUpload}
                style={{ width: "100%" }}
              >
                <Button
                  type="primary"
                  disabled={disabled}
                  style={{ width: "100%" }}
                >
                  Upload Business Partner Document
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PartnerDetailFile;
