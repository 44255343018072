import React from "react";
import { Spin } from "antd";

export const Loading = () => {
  return (
    <div className="loading">
      <Spin />
    </div>
  );
};

export const LoadingScreen = () => {
  return (
    <div className="screen loading">
      <Spin />
    </div>
  );
};
