import React, { useEffect, useState } from "react";
import { Layout, Col, Row } from "antd";
import { useDispatch } from "react-redux";
import { URL } from "../00_Nav/url";
import LayoutHeader from "../00_Nav/layout/layout_header";
import LayoutBreadCrum from "../00_Nav/layout/layout_bread_crum";
import OperatorListSection from "./section/operator_list_section";
import OperatorDetailSection from "./section/operator_detail_section";
import { setHighlight } from "../../store/nav/nav.action";

const { Content } = Layout;

const OperatorManagement = () => {
  // -------------------------- VAR --------------------------

  const breadCrumb = [
    { title: "Home", url: null },
    { title: "Operator Management", url: URL.operators },
  ];
  // -------------------------- STATE --------------------------
  const [loading, setLoading] = useState(false);
  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    dispatch(setHighlight(["3"]));
  }, [dispatch]);
  // -------------------------- MAIN --------------------------
  return (
    <Layout className="site-layout full">
      <LayoutHeader title="Operator Management" />
      <LayoutBreadCrum data={breadCrumb} />
      <Content id="operators" className="content">
        <Row gutter={[16, 12]}>
          <Col xxl={8} xl={8} lg={8} md={24} xs={24}>
            <OperatorListSection loading={loading} />
          </Col>
          <Col xxl={16} xl={16} lg={16} md={24} xs={24} className="box">
            <OperatorDetailSection />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default OperatorManagement;
