import { Card, Table } from "antd";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCommissionHistory,
  snapCommissionHistoryList,
  unSnapCommissionHistoryList,
} from "../../../../../store/commission_fee_history/history.action";
import { commissionHistoryListSelector } from "../../../../../store/commission_fee_history/history.selector";
import { useState } from "react";
import PartnerDetailCommissionHistoryCreateUpdate from "./component/partner_details_commission_history_create_update";

const PartnerDetailTxHistory = (props) => {
  // -------------------------- VAR --------------------------
  const { ips, partner, ipsList } = props;
  // console.log("method history", client);
  // -------------------------- STATE --------------------------
  const [daysLeft, setDaysLeft] = useState(7);
  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  const commissionHistoryList = useSelector(commissionHistoryListSelector);
  console.log({ commissionHistoryList });
  const data = commissionHistoryList?.filter(
    (history) => history?.ipsId === ips && history?.partnerId === partner
  );
  const sortedData = data?.sort((a, b) => b.datetime - a.datetime);
  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    dispatch(snapCommissionHistoryList());
    return () => unSnapCommissionHistoryList();
  }, [dispatch]);
  // -------------------------- FUNCTION --------------------------
  // const convertTimestampToDate = (inputTimestamp) => {
  //   // return moment.unix(inputTimestamp.seconds);
  //   return new Date(inputTimestamp?.seconds * 1000);
  // };

  // const handleCurrency = () => {
  //   const ipsItem = ipsList.find((ipsDetail) => ipsDetail.id === ips);
  //   let currency = "";
  //   if (ipsItem?.method === "bank") {
  //     currency = ipsItem?.bank?.currency;
  //   } else {
  //     currency = "usdt";
  //   }
  //   return currency.toUpperCase();
  // };

  const getColumns = () => {
    const columns = [
      {
        key: "date",
        dataIndex: "datetime",
        title: "Date",
        align: "center",
        sorter: (a, b) => b.datetime - a.datetime,
        render: (values) => (
          <div style={{ textAlign: "left", paddingLeft: 10 }}>
            {moment(values.toDate()).format("MMM DD, YYYY")}
          </div>
        ),
        width: "15%",
      },
      {
        key: "type",
        dataIndex: "type",
        title: <>Type</>,
        align: "center",
        width: "17%",
        render: (value) =>
          value ? (
            <div style={{ textAlign: "right", paddingRight: "30%" }}>
              {value}
            </div>
          ) : (
            "-"
          ),
      },
      {
        key: "ipsId",
        dataIndex: "backupIpsId",
        title: "IPS ID",
        align: "center",
        width: "17%",
        render: (value) =>
          value ? (
            <div style={{ textAlign: "right", paddingRight: "30%" }}>
              {value}
            </div>
          ) : (
            "-"
          ),
      },
      {
        key: "amount",
        dataIndex: "amount",
        title: "Amount",
        align: "center",
        width: "18%",
        render: (value) =>
          value
            ? // <div style={{ textAlign: "right", paddingRight: "30%" }}>
              alignRight(numeral(value).format("0,0.000") + " USDT")
            : // </div>
              "-",
      },
      {
        key: "status",
        dataIndex: "status",
        title: "Status",
        align: "center",
        width: "16%",
        render: (value, record) => {
          if (value === "undeposited") {
            return (
              <div style={{ textAlign: "center", paddingLeft: "20%" }}>
                {moment().isSameOrAfter(moment(record?.datetime?.toDate())) ? (
                  <>Pending</>
                ) : daysLeft !== 0 && daysLeft !== 1 ? (
                  <>{daysLeft} days left</>
                ) : (
                  <>{daysLeft} day left</>
                )}
              </div>
            );
          } else {
            return (
              <div
                style={{
                  textAlign: "center",
                  paddingLeft: "20%",
                  color: "green",
                  // fontWeight: "bold",
                }}
              >
                {value.charAt(0).toUpperCase() + value.slice(1)}
              </div>
            );
          }
        },
      },
      {
        key: "action",
        dataIndex: "status",
        title: "Action",
        align: "center",
        width: "17%",
        render: (value, record) => {
          if (value === "undeposited") {
            return (
              <div style={{ textAlign: "center" }}>
                {moment().isSameOrAfter(moment(record?.datetime?.toDate())) ? (
                  <PartnerDetailCommissionHistoryCreateUpdate
                    record={record}
                    setDaysLeft={setDaysLeft}
                    disabled={false}
                  />
                ) : (
                  <PartnerDetailCommissionHistoryCreateUpdate
                    record={record}
                    setDaysLeft={setDaysLeft}
                    disabled={true}
                  />
                )}
              </div>
            );
          }
        },
      },
    ];

    return columns;
  };

  // -------------------------- RENDER --------------------------
  // --------------------------- MAIN --------------------------
  const columns = getColumns();
  return (
    <Card size="small">
      <Table
        columns={columns?.map((col) => ({
          ...col,
          onCell: () => ({
            style: {
              fontSize: 12,
            },
          }),
        }))}
        size="small"
        dataSource={sortedData}
        scroll={{ x: 512 }}
        className="history-table"
        rowKey={(record) => record.id}
      />
    </Card>
  );
};
export default PartnerDetailTxHistory;

const alignRight = (value) => {
  return (
    <div
      style={{
        textAlign: "right",
        paddingLeft: 20,
      }}
    >
      {value}
    </div>
  );
};
