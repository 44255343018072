import React from "react";
import { Form, Radio } from "antd";
import { RECEIVED_BY } from "../../../../../store/data/ips_mgmt";
const IpsDetailsReceiveInterest = (props) => {
  // -------------------------- VAR --------------------------
  const { setReceiveBy } = props;
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  const receivedByOptions = RECEIVED_BY;
  // -------------------------- FUNCTION --------------------------
  const handleChange = (e) => {
    setReceiveBy(e.target.value);
  };
  // -------------------------- EFFECT --------------------------

  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="receiveInterest"
      name="receiveInterest"
      initialValue="wallet"
      label={
        <span>
          Interest Received&nbsp;
          <span style={{ color: "red" }}>*</span>
        </span>
      }
      rules={[
        {
          required: true,
          message: "required field",
        },
      ]}
    >
      <Radio.Group
        options={receivedByOptions}
        defaultValue="wallet"
        placeholder="Receive interest"
        // allowClear
        // showSearch
        // optionFilterProp="label"
        onChange={handleChange}
        className="my-radio-group"
      ></Radio.Group>
    </Form.Item>
  );
};
export default IpsDetailsReceiveInterest;
