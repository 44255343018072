import { Select } from "antd";
import React from "react";

const ClientListSelectContract = (props) => {
  // -------------------------- VAR --------------------------
  const { option, setOption, options } = props;
  console.log({ option, options });
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  const handleSelect = (value) => {
    console.log({ value });
    setOption(value);
  };
  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Select
      value={option}
      options={options}
      onSelect={handleSelect}
      style={{ width: "100%", textAlign: "left" }}
    />
  );
};
export default ClientListSelectContract;
