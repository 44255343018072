import React, { useState } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Upload, Popconfirm, Tooltip, Button } from "antd";
import { BsTrash } from "react-icons/bs";
import Resizer from "react-image-file-resizer";
import placeholder from "../../../../asset/img/placeholder.png";
import avatar from "../../../../asset/img/avatar.png";

const ClientAvatar = (props) => {
  // -------------------------- VAR --------------------------
  const { data } = props;
  const { avatar: profile } = data;
  // -------------------------- STATE --------------------------

  // -------------------------- REDUX --------------------------

  // -------------------------- EFFECT --------------------------

  // -------------------------- FUNCTION --------------------------

  // const beforeUpload = (file) => {
  //   const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  //   if (!isJpgOrPng) {
  //     message.error("You can only upload JPG/PNG file!");
  //   }
  //   const isLt2M = file.size / 1024 / 1024 < 2;
  //   if (!isLt2M) {
  //     message.error("Image must smaller than 2MB!");
  //   }
  //   return isJpgOrPng && isLt2M;
  // };

  // const handleUpload = (action) => {
  //   const file = action.file;
  //   setLoading(true);
  //   Resizer.imageFileResizer(
  //     file,
  //     512,
  //     512,
  //     "jpg",
  //     96,
  //     0,
  //     (uri) => {
  //       setLoading(false);
  //       props.onChange({ id: "avatar", value: uri });
  //     },
  //     "base64"
  //   );
  // };

  // const handleDelete = () => {
  //   props.onChange({ id: "avatar", value: "" });
  // };
  // -------------------------- RENDER --------------------------

  const renderAvatar = () => {
    return (
      <img
        src={profile || avatar}
        alt="avatar"
        style={{
          width: "100%",
          height: "100%",
        }}
      />
    );
  };

  // const renderButton = () => {
  //   return (
  //     <div style={{ position: "relative" }}>
  //       {loading ? <LoadingOutlined /> : <PlusOutlined />}
  //       <div style={{ marginTop: 8 }}>Avatar</div>
  //     </div>
  //   );
  // };

  // const renderDelete = () => {
  //   return (
  //     <Popconfirm
  //       title="Are you sure to remove avatar?"
  //       placement="leftTop"
  //       onConfirm={handleDelete}
  //       okText="Yes"
  //       cancelText="No"
  //       okButtonProps={{ danger: true }}
  //       disabled={disabled}
  //       icon={
  //         <span className="anticon">
  //           <BsTrash style={{ color: "red" }} />
  //         </span>
  //       }
  //     >
  //       <Tooltip title="Delete">
  //         <Button
  //           danger
  //           size="small"
  //           type="text"
  //           disabled={disabled}
  //           icon={
  //             <span className="anticon">
  //               <BsTrash />
  //             </span>
  //           }
  //         />
  //       </Tooltip>
  //     </Popconfirm>
  //   );
  // };

  // -------------------------- MAIN --------------------------
  return (
    <div style={{ position: "relative" }}>
      <Upload
        disabled
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        // disabled={disabled}
        // beforeUpload={beforeUpload}
        // customRequest={handleUpload}
      >
        {renderAvatar()}
      </Upload>
      {/* {avatar ? (
        <div style={{ position: "absolute", top: 0, right: 10, zIndex: 1 }}>{renderDelete()}</div>
      ) : null} */}
    </div>
  );
};

export default ClientAvatar;
