import React from "react";
import { Menu } from "antd";
import { useSelector } from "react-redux";
import { useMenu } from "../../../hook/useMenu";
import { highlightSelector } from "../../../store/nav/nav.selector";

const LayoutMenu = (props) => {
  // -------------------------- VAR --------------------------
  const { mode } = props;
  const { menu } = useMenu();
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  const hightlight = useSelector(highlightSelector);
  // -------------------------- EFFECT --------------------------
  // -------------------------- FUNCTION --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return <Menu key={hightlight} mode={mode || "vertical"} items={menu} selectedKeys={hightlight} />;
};

export default LayoutMenu;
