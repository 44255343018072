import React from "react";
import { Layout, Tooltip, Button, Row, Col } from "antd";
import { useDispatch } from "react-redux";
import { FiMenu, FiPower } from "react-icons/fi";
import { SANDBOX } from "../../../config/config";
import { firebaseUpdateLoggedOut } from "../../../store/user/user.action";
// component
//redux
import { toggleCollapse } from "../../../store/nav/nav.action";
import { logOut } from "../../../store/auth/auth.action";

const LayoutHeader = (props) => {
  // -------------------------- STATE --------------------------
  const { title } = props;
  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  // -------------------------- EFFECT --------------------------
  // -------------------------- FUNCTION --------------------------
  const toggleMenu = () => {
    dispatch(toggleCollapse());
  };

  const handleLogout = () => {
    dispatch(
      firebaseUpdateLoggedOut(() => {
        dispatch(logOut());
      })
    );
  };

  // -------------------------- RENDER --------------------------

  const renderBody = () => {
    return (
      <Row justify="space-between" align="middle">
        <Col flex="50px">{renderMenu()}</Col>
        <Col flex="auto">{renderTitle()}</Col>
        <Col flex="50px">{renderLogout()}</Col>
      </Row>
    );
  };

  const renderMenu = () => {
    return (
      <Tooltip title="Menu">
        <Button
          shape="default"
          onClick={() => toggleMenu()}
          style={{ padding: 6, borderRadius: 5 }}
        >
          <FiMenu style={{ fontSize: 18 }} />
        </Button>
      </Tooltip>
    );
  };

  const renderTitle = () => {
    return (
      <div className="header-title">
        {title || ""}
        {SANDBOX ? " - UAT" : ""}
      </div>
    );
  };

  const renderLogout = () => {
    return (
      <Tooltip title="Logout">
        <Button
          shape="default"
          onClick={handleLogout}
          style={{ padding: 6, borderRadius: 5 }}
        >
          <span className="anticon">
            <FiPower style={{ fontSize: 18 }} />
          </span>
        </Button>
      </Tooltip>
    );
  };
  // -------------------------- MAIN --------------------------
  return <Layout.Header className="header-menu">{renderBody()}</Layout.Header>;
};

export default LayoutHeader;
