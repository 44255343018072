import React from "react";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { selectOperator } from "../../../store/operator/operator.action";

const OperatorListButtonAdd = () => {
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  // -------------------------- EFFECT --------------------------
  // -------------------------- FUNCTION --------------------------
  const handleAdd = () => {
    dispatch(selectOperator({ detail: null }));
  };
  // -------------------------- MAIN --------------------------
  return (
    <Button type="primary" style={{ width: "100%" }} onClick={() => handleAdd()}>
      Add
    </Button>
  );
};

export default OperatorListButtonAdd;
