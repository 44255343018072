export const REGION = "asia-southeast1";
// local
export const LOCAL_USER = "Local User";
// ref
export const REF = {
  OPERATOR: "operator",
  SUPPLIER: "supplier",
  IPS: "ips",
  CLIENT: "client",
  PROFIT_HISTORY: "sharedProfitHistory",
  TOPUP_WITHDRAW: "topupWithdrawHistory",
  PARTNER: "partner",
  COMMISSION_HISTORY: "commissionFeeHistory",
  WITHDRAW_REQUEST: "withdrawRequestHistory",
};
