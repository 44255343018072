import React, { useEffect, useState } from "react";
import { Layout, Col, Row } from "antd";
import { useDispatch } from "react-redux";
import { URL } from "../00_Nav/url";
import LayoutHeader from "../00_Nav/layout/layout_header";
import LayoutBreadCrum from "../00_Nav/layout/layout_bread_crum";
import { setHighlight } from "../../store/nav/nav.action";
// import { CLIENT_LIST } from "../../store/data/client_mgmt";
import ClientDetail from "./component/client_detail";
import ClientList from "./component/client_list";
import {
  snapClientList,
  unSnapClientList,
} from "../../store/client/client.action";
import { clientListSelector } from "../../store/client/client.selector";
import { useSelector } from "react-redux";
import { snapIpsList, unSnapIpsList } from "../../store/ips/ips.action";
import { ipsListSelector } from "../../store/ips/ips.selector";

const { Content } = Layout;

const ClientScreen = () => {
  // -------------------------- VAR --------------------------
  const breadCrumb = [
    { title: "Home", url: null },
    { title: "Client Management", url: URL.clientMgmt },
  ];
  // -------------------------- STATE --------------------------
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState([]);
  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  const clientData = useSelector(clientListSelector);
  const ipsList = useSelector(ipsListSelector);
  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    dispatch(snapClientList());
    return () => dispatch(unSnapClientList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(snapIpsList());
    return () => dispatch(unSnapIpsList());
  }, [dispatch]);

  useEffect(() => {
    if (Array.isArray(clientData) && clientData?.length > 0) {
      setLoading(false);
      setDetails(clientData[0]);
    }
  }, [clientData]);

  useEffect(() => {
    dispatch(setHighlight(["5"]));
  }, [dispatch]);
  // -------------------------- RENDER --------------------------
  const renderBody = () => {
    // console.log({details})
    return (
      <Row gutter={[16, 12]}>
        <Col xl={9} lg={9} md={12} xs={24}>
          <ClientList
            loading={loading}
            clientData={clientData}
            setDetails={setDetails}
            // ipsList={ipsList}
          />
        </Col>
        <Col xl={15} lg={15} md={12} xs={24} className="box">
          <ClientDetail details={details} />
        </Col>
      </Row>
    );
  };
  // -------------------------- MAIN --------------------------
  return (
    <Layout className="site-layout full">
      <LayoutHeader title="Client Management" />
      <LayoutBreadCrum data={breadCrumb} />
      <Content className="content">{renderBody()}</Content>
    </Layout>
  );
};

export default ClientScreen;
