import React from "react";
import { Form, Input } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { useEffect } from "react";

const PartnerDetailEmail = (props) => {
  // -------------------------- VAR --------------------------

  const { form, partnerInfo } = props;
  console.log({ partnerInfo });
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    form.setFieldValue("email", partnerInfo?.email);
  }, [partnerInfo]);
  // -------------------------- FUNCTION --------------------------

  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="email"
      name="email"
      label="Email"
      rules={[
        {
          required: false,
          message: "Please input email",
        },
        {
          whitespace: true,
          message: "Invalid",
        },
        {
          type: "email",
          message: "Wrong email format",
        },
      ]}
    >
      <Input
        disabled={true}
        // addonBefore={<MailOutlined className="form-item-icon" />}
        // placeholder="Email"
      />
    </Form.Item>
  );
};

export default PartnerDetailEmail;
