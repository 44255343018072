import React from "react";
import { Button, Col, Row, Typography } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { ipsListSelector } from "../../../../store/ips/ips.selector";
import { useEffect } from "react";
import { snapIpsList, unSnapIpsList } from "../../../../store/ips/ips.action";

const ClientListTotal = (props) => {
  // --------------------------- VAR --------------------------
  const { Title } = Typography;
  const { data } = props;
  console.log({ data });
  const totalClients = data?.length;
  const totalIps = data?.reduce(
    (accumulator, item) => accumulator + item.totalIps,
    0
  );
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // const ipsList = useSelector(ipsListSelector);
  // console.log({ ipsList });
  // const dispatch = useDispatch();
  // ------------------------- FUNCTION --------------------------
  const addHistory = () => {};
  // -------------------------- EFFECT --------------------------
  // useEffect(() => {
  //   dispatch(snapIpsList());
  //   return () => {
  //     dispatch(unSnapIpsList());
  //   };
  // }, [dispatch]);
  // -------------------------- RENDER --------------------------
  // --------------------------- MAIN --------------------------
  return (
    <Row gutter={[16, 12]}>
      <Col xl={12} lg={12} md={24} xs={24}>
        <Title level={5}>Total clients: {totalClients}</Title>
      </Col>
      <Col xl={12} lg={12} md={24} xs={24}>
        <Title level={5}>Total IPS: {totalIps}</Title>
      </Col>
    </Row>
  );
};

export default ClientListTotal;
