import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import ClientListSearch from "./component/client_list_search";
import { CONTRACT_OPTIONS } from "../../../store/data/client_mgmt";
import { Loading } from "../../../component/loading";
import ClientListSelectContract from "./component/client_list_select_contract";
import ClientListListing from "./component/client_list_listing";
import ClientListCreate from "../dialog/dialog_client_list_create";
import ClientListTotal from "./component/client_list_total";

const ClientList = (props) => {
  // -------------------------- VAR --------------------------
  const { loading, clientData, setDetails } = props;
  // console.log({ clientData });
  // -------------------------- STATE --------------------------
  const [search, setSearch] = useState("");
  const [contract, setContract] = useState("all");
  const [filterData, setFilterData] = useState("");
  const [open, setOpen] = useState(false);
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  const handleClick = () => {
    setOpen(true);
  };
  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    // const sortedData = refineData?.sort((a, b) => b.createdAt - a.createdAt);
    const lowerSearch = search?.toLowerCase();
    const searchedData =
      search === ""
        ? clientData
        : clientData?.filter((item) =>
            `${item?.firstName}${item?.lastName}`
              .toLowerCase()
              ?.includes(lowerSearch)
          );

    const refineData =
      contract === "all"
        ? searchedData
        : searchedData?.filter((item) =>
            item?.contractType?.find((type) => type === contract)
          );

    setFilterData(refineData);
  }, [clientData, search, contract]);

  // useEffect(() => {
  //   console.log({ filterData });
  // }, [filterData]);
  // -------------------------- RENDER --------------------------
  const renderTop = () => {
    return (
      <Row gutter={[16, 12]}>
        {renderSearch()}
        {renderSelectContract()}
        {renderAddButton()}
        <ClientListCreate open={open} setOpen={setOpen} />
      </Row>
    );
  };

  const renderSearch = () => {
    const placeholder = "Search by name...";
    return (
      <Col xl={9} lg={9} md={24} xs={24}>
        <ClientListSearch
          search={search}
          setSearch={setSearch}
          placeholder={placeholder}
        />
      </Col>
    );
  };

  const renderSelectContract = () => {
    const selectOptions = CONTRACT_OPTIONS;
    return (
      <Col xl={9} lg={9} md={24} xs={24}>
        <ClientListSelectContract
          option={contract}
          setOption={setContract}
          options={selectOptions}
        />
      </Col>
    );
  };

  const renderAddButton = () => {
    return (
      <Col xl={6} lg={6} md={24} xs={24}>
        <Button type="primary" onClick={handleClick}>
          Add client
        </Button>
      </Col>
    );
  };

  const renderTotal = () => {
    return loading ? (
      <Loading />
    ) : (
      <ClientListTotal data={filterData} setDetails={setDetails} />
    );
  };

  const renderBottom = () => {
    return loading ? (
      <Loading />
    ) : (
      <ClientListListing data={filterData} setDetails={setDetails} />
    );
  };
  // -------------------------- MAIN --------------------------
  return (
    <Row gutter={[16, 12]}>
      <Col xl={24} lg={24} md={24} xs={24}>
        {renderTop()}
      </Col>
      <Col xl={24} lg={24} md={24} xs={24}>
        {renderTotal()}
      </Col>
      <Col xl={24} lg={24} md={24} xs={24}>
        {renderBottom()}
      </Col>
    </Row>
  );
};
export default ClientList;
