import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Form,
  Typography,
  Button,
  message,
  Modal,
  Divider,
} from "antd";
import { useDispatch } from "react-redux";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { updateIps } from "../../../store/ips/ips.action";
import IpsDetailsBankAccount from "./component/component/dialog_ips_details_bank_account";
import IpsDetailsBankName from "./component/component/dialog_ips_details_bank_name";
import IpsDetailsCurrency from "./component/component/dialog_ips_details_currency";
import IpsDetailsDuration from "./component/component/dialog_ips_details_duration";
import IpsDetailsFund from "./component/component/dialog_ips_details_initial_fund";
import IpsDetailsInterest from "./component/component/dialog_ips_details_interest";
import IpsDetailsType from "./component/component/dialog_ips_details_ips_details_type";
import IpsDetailsIps from "./component/component/dialog_ips_details_ips";
import IpsDetailsNetwork from "./component/component/dialog_ips_details_network";
import IpsDetailsNumber from "./component/component/dialog_ips_details_number";
import IpsDetailsReceiveInterest from "./component/component/dialog_ips_details_receive_interest";
import IpsDetailsShareProfit from "./component/component/dialog_ips_details_share_profit";
import IpsDetailsstartAt from "./component/component/dialog_ips_details_start_date";
import IpsDetailsWalletAddress from "./component/component/dialog_ips_details_wallet_address";
import IpsDetailsBankAccountName from "./component/component/dialog_ips_details_bank_account_name";
import { Timestamp } from "firebase/firestore";
import IpsDetailsOnusPhone from "./component/component/dialog_ips_details_onus_phone";
import IpsDetailsOnusEmail from "./component/component/dialog_ips_details_onus_email";
import { useSelector } from "react-redux";
import { ipsListSelector } from "../../../store/ips/ips.selector";
import moment from "moment/moment";
import IpsDetailsClient from "./component/component/dialog_ips_details_client";
import IpsDetailNotes from "./component/component/dialog_ips_details_notes";
import IpsDetailsOnusAccountType from "./component/component/dialog_ips_details_onus_account_type";
import IpsDetailsPartnerRef from "./component/component/dialog_ips_details_partner_ref";
import IpsDetailsFile from "./component/component/dialog_ips_details_file";
import PartnerDetailOneOff from "../../06_Partner_Management/dialog/component/component/dialog_partner_list_one_off";
import IpsDetailsOnusAccountId from "./component/component/dialog_ips_details_onus_account_id";
const { Title } = Typography;

const IpsDetailEdit = (props) => {
  // -------------------------- VAR --------------------------
  const { open, setOpen, record } = props;
  const storage = getStorage();
  console.log({ record });
  const id = record?.id;
  // ------------------------- STATE --------------------------
  const [form] = Form.useForm();
  const [receivedBy, setReceiveBy] = useState(record?.method);
  const [onusAccountType, setOnusAccountType] = useState(
    record?.onusAccountType
  );
  const [interestRate, setInterestRate] = useState(record?.interest);
  const [loading, setLoading] = useState(false);
  const [clientName, setClientName] = useState(null);
  const [clientId, setClientId] = useState(form.getFieldValue("clientId"));
  const [partnerName, setPartnerName] = useState(null);
  const [partnerId, setPartnerId] = useState(form.getFieldValue("partnerId"));
  const [file, setFile] = useState(null);
  const [timeValue, setTimeValue] = useState(record?.interestRatePer);
  const [currency, setCurrency] = useState("usd");
  const [fundCurrency, setFundCurrency] = useState(record?.fundCurrency);
  // ------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  const ipsData = useSelector(ipsListSelector);
  const ipsRecord = ipsData.find((item) => item.id === id);
  // console.log({ ipsRecord });
  // ------------------------ EFFECT --------------------------
  useEffect(() => {
    setReceiveBy(ipsRecord?.method);
    setTimeValue(ipsRecord?.interestRatePer);
    setFundCurrency(ipsRecord?.fundCurrency);
    if (ipsRecord?.method === "bank") {
      setCurrency(ipsRecord?.bank?.currency);
    }
    form.setFieldsValue({
      clientId: ipsRecord?.clientId,
      ipsId: ipsRecord?.ipsId,
      interest: ipsRecord?.interest,
      type: ipsRecord?.type,
      profit: ipsRecord?.shareProfit,
      duration: ipsRecord?.duration,
      fund: ipsRecord?.fund,
      startAt: ipsRecord?.startAt,
      receiveInterest: record?.method,
      network: record?.wallet?.network,
      walletAddress: record?.wallet?.walletAddress,
      bankName: record?.bank?.bankName,
      accountId: record?.bank?.accountId,
      accountName: record?.bank?.accountName,
      currency: record?.bank?.currency,
      onusPhone: record?.onus?.phone,
      onusMail: record?.onus?.email,
      partnerId: ipsRecord?.partner?.partnerId,
      notes: record?.notes,
      pdfFile: ipsRecord?.pdfFile,
      oneOff: ipsRecord?.oneOff,
    });
  }, [ipsRecord, form, record]);

  // useEffect(() => {
  //   console.log({ partnerId });
  //   // form.setFieldValue("partnerId", partnerId);
  // }, [partnerId]);
  // ----------------------- FUNCTION --------------------------
  // form.setFieldsValue({
  //   // clientId: ipsRecord?.clientId,
  //   ipsId: ipsRecord?.ipsId,
  //   interest: ipsRecord?.interest,
  //   type: ipsRecord?.type,
  //   profit: ipsRecord?.shareProfit,
  //   duration: ipsRecord?.duration,
  //   fund: ipsRecord?.fund,
  //   startAt: ipsRecord?.startAt,
  //   receiveInterest: record?.method,
  //   network: record?.wallet?.network,
  //   walletAddress: record?.wallet?.walletAddress,
  //   bankName: record?.bank?.bankName,
  //   accountId: record?.bank?.accountId,
  //   accountName: record?.bank?.accountName,
  //   currency: record?.bank?.currency,
  //   onusPhone: record?.onus?.phone,
  //   onusMail: record?.onus?.email,
  //   // partnerId: ipsRecord?.partner?.partnerId,
  //   notes: record?.notes,
  // });

  const handleDateTime = (date) => {
    const jsDate = date.toDate();

    // Create a Firestore Timestamp from the JavaScript Date object
    return Timestamp.fromDate(jsDate);
  };

  const onFinish = async (values) => {
    // console.log({ values });
    let fileURL = null;
    if (file) {
      const uniqueFileName = `${Date.now()}_${file?.name}`;
      const storageRef = ref(storage, uniqueFileName);
      try {
        await uploadBytes(storageRef, file);
        fileURL = await getDownloadURL(storageRef);
      } catch (error) {
        console.log(error);
      }
    }
    // if (fileURL) {
    const newClientName = clientName?.split(" - ");
    const newPartnerName = partnerName?.split(" - ") || "";
    const update = {
      clientId: values.clientId,
      clientName: newClientName?.[0],
      partner: {
        partnerId: values.partnerId || "",
        partnerName: newPartnerName?.[0] || "",
      },
      duration: values.duration,
      interest: values.interest || 0,
      interestRatePer: timeValue,
      ipsId: values.ipsId,
      pdfFile: fileURL,
      startAt: handleDateTime(values.startAt),
      fund: values.fund,
      fundCurrency: fundCurrency,
      shareProfit: values.profit,
      method: values.receiveInterest,
      type: values.type,
      wallet: {
        network: values.network || "",
        walletAddress: values.walletAddress || "",
      },
      bank: {
        accountId: values.accountId || "",
        accountName: values.accountName || "",
        bankName: values.bankName || "",
        currency: values.currency || "",
      },
      onusAccountType: values.onusAccountType || "",
      onus: {
        email: values.onusMail || "",
        phone: values.onusPhone || "",
      },
      notes: values.notes || "",
      oneOff: values.oneOff || "",
    };
    // console.log({ update });
    setLoading(true);
    dispatch(
      updateIps({ ips: ipsRecord, update }, (response) => {
        setLoading(false);
        setOpen(false);
        if (response.status === 200) {
          message.success("Successfully update ips");
        } else {
          const error = response.data;
          message.error(error);
        }
      })
    );
    // }
  };

  const onFinishFailed = (error) => {
    console.log({ error });
  };

  const handleReset = () => {
    console.log({ ipsRecord });
    form.setFieldsValue(ipsRecord);
    setFundCurrency(ipsRecord?.fundCurrency);
  };

  const handleCancel = () => {
    handleReset();
    setOpen(false);
  };
  // ------------------------ RENDER --------------------------
  const renderIpsId = () => {
    return <IpsDetailsNumber />;
  };

  const renderInitialFund = () => {
    return (
      <IpsDetailsFund
        fundCurrency={fundCurrency}
        setFundCurrency={setFundCurrency}
      />
    );
  };

  const renderType = () => {
    return <IpsDetailsType />;
  };

  const renderInterest = () => {
    return (
      <IpsDetailsInterest
        form={form}
        setInterestRate={setInterestRate}
        interestRate={interestRate}
        timeValue={timeValue}
        setTimeValue={setTimeValue}
      />
    );
  };

  const renderstartAt = () => {
    return <IpsDetailsstartAt />;
  };

  const renderDuration = () => {
    return <IpsDetailsDuration />;
  };

  const renderShareProfit = () => {
    return <IpsDetailsShareProfit />;
  };

  const renderReceiveInterest = () => {
    return <IpsDetailsReceiveInterest setReceiveBy={setReceiveBy} />;
  };

  const renderNetwork = () => {
    return <IpsDetailsNetwork />;
  };

  const renderWalletAddress = () => {
    return <IpsDetailsWalletAddress />;
  };

  const renderBankName = () => {
    return <IpsDetailsBankName />;
  };

  const renderBankAccountId = () => {
    return <IpsDetailsBankAccount />;
  };

  const renderBankAccountName = () => {
    return <IpsDetailsBankAccountName />;
  };

  const renderCurrency = () => {
    return <IpsDetailsCurrency setCurrency={setCurrency} />;
  };

  const renderOnusAccountType = () => {
    return (
      <IpsDetailsOnusAccountType setOnusAccountType={setOnusAccountType} />
    );
  };

  const renderOnusPhone = () => {
    return <IpsDetailsOnusPhone />;
  };

  const renderOnusEmail = () => {
    return <IpsDetailsOnusEmail />;
  };

  const renderOnusAccountId = () => {
    return <IpsDetailsOnusAccountId />;
  };

  const renderClient = () => {
    return (
      <IpsDetailsClient
        setClientId={setClientId}
        setClientName={setClientName}
        form={form}
      />
    );
  };

  const renderPartnerRef = () => {
    return (
      <IpsDetailsPartnerRef
        setPartnerId={setPartnerId}
        setPartnerName={setPartnerName}
        form={form}
      />
    );
  };

  const renderNotes = () => {
    return <IpsDetailNotes />;
  };

  const renderIpsFile = () => {
    return <IpsDetailsFile file={file} setFile={setFile} />;
  };

  const renderOneOff = () => {
    return <PartnerDetailOneOff form={form} />;
  };

  // ------------------------- MAIN --------------------------
  const layout = {
    labelAlign: "left",
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  return (
    <Modal
      width={1000}
      open={open}
      onCancel={handleCancel}
      footer={null}
      confirmLoading={loading}
    >
      <Col span={24} style={{ paddingTop: 30 }}>
        <Form
          requiredMark={false}
          size="small"
          autoComplete="off"
          form={form}
          {...layout}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={[10, 0]}>
            <Col xl={11} lg={11} md={24} xs={24}>
              {renderIpsId()}
              {renderPartnerRef()}
              {renderOneOff()}
              {renderClient()}
              {renderInitialFund()}
              {renderType()}
              {renderInterest()}
              {renderstartAt()}
              {receivedBy === "bank" && renderDuration()}
            </Col>
            <Col
              xl={2}
              lg={2}
              md={0}
              xs={0}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Divider type="vertical" style={{ height: "100%" }} />
            </Col>
            <Col xl={11} lg={11} md={24} xs={24}>
              {receivedBy !== "bank" && renderDuration()}
              {renderShareProfit()}
              {renderReceiveInterest()}
              {receivedBy === "wallet" && renderNetwork()}
              {receivedBy === "wallet" && renderWalletAddress()}
              {receivedBy === "bank" && renderBankName()}
              {receivedBy === "bank" && renderBankAccountId()}
              {receivedBy === "bank" && renderBankAccountName()}
              {receivedBy === "bank" && renderCurrency()}
              {receivedBy === "onus" && renderOnusAccountType()}
              {receivedBy === "onus" &&
                onusAccountType === "onusPhone" &&
                renderOnusPhone()}
              {receivedBy === "onus" &&
                onusAccountType === "onusMail" &&
                renderOnusEmail()}
              {receivedBy === "onus" &&
                onusAccountType === "onusAccountId" &&
                renderOnusAccountId()}
              {renderNotes()}
              {renderIpsFile()}
            </Col>
          </Row>
          <div style={{ paddingTop: 20 }}>
            <Row gutter={[40, 0]}>
              <Col xl={6} lg={6} md={0} sm={0}></Col>
              <Col xl={6} lg={6} md={12} sm={12}>
                <Button
                  className="login-form-button"
                  style={{ width: "100%" }}
                  loading={loading}
                  size="medium"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Col>
              <Col xl={6} lg={6} md={12} sm={12}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  size="medium"
                  style={{ width: "100%" }}
                  loading={loading}
                >
                  Submit
                </Button>
              </Col>
              <Col xl={6} lg={6} md={0} sm={0}></Col>
            </Row>
          </div>
        </Form>
      </Col>
    </Modal>
  );
};

export default IpsDetailEdit;
