import React, { useState } from "react";
import { Button, Modal, Row, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
// redux
import {
  deleteOperator,
  selectOperator,
} from "../../../store/operator/operator.action";
import { userSelector } from "../../../store/user/user.selector";
import { detailSelector } from "../../../store/operator/operator.selector";

const OperatorDialogDelete = (props) => {
  // -------------------------- VAR --------------------------
  const { operator, visible, handleClose } = props;
  const fullName = `${operator?.firstName} ${operator?.lastName}`;
  // -------------------------- STATE --------------------------
  const [loading, setLoading] = useState(false);
  const [pass, setPass] = useState("");
  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  const detail = useSelector(detailSelector);
  const account = useSelector(userSelector);

  // -------------------------- EFFECT --------------------------
  // -------------------------- FUNCTION --------------------------
  const handleOk = () => {
    if (pass === account?.password) {
      setLoading(true);
      dispatch(
        deleteOperator({ operator }, () => {
          setLoading(false);
          handleClose();
          if (operator?.id === detail?.id) {
            dispatch(selectOperator({ detail: null }));
          }
        })
      );
    } else {
      message.error("Wrong password!");
    }
  };
  // -------------------------- RENDER --------------------------

  const renderCancel = () => {
    return (
      <Button key="back" onClick={handleClose}>
        Cancel
      </Button>
    );
  };

  const renderDelete = () => {
    return (
      <Button danger type="primary" loading={loading} onClick={handleOk}>
        Delete
      </Button>
    );
  };

  const renderInput = () => {
    return (
      <Row span={24} style={{ margin: 20, marginBottom: 0 }}>
        <Input.Password
          onChange={(e) => setPass(e?.target?.value)}
          placeholder="Please enter admin password ..."
        />
      </Row>
    );
  };
  // -------------------------- MAIN --------------------------
  return (
    <Modal
      title="Delete Operator"
      visible={visible}
      onCancel={handleClose}
      footer={[renderCancel(), renderDelete()]}
    >
      <p>
        Are you sure to delete{" "}
        <strong style={{ color: "red" }}>{fullName}</strong>?
      </p>
      {renderInput()}
    </Modal>
  );
};

export default OperatorDialogDelete;
