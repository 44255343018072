import { TYPES } from "./ips.type";

const initState = {
  ips: null,
  ipsList: null,
  ipsListByClient: null,
  status: "add",
  allow: null,
  detail: null,
};

const ipsReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case TYPES.IPS_LIST_SUCCESS:
      return {
        ...state,
        ipsList: payload,
      };
    case TYPES.IPS_LIST_SUCCESS_BY_CLIENT:
      return {
        ...state,
        ipsListById: payload,
      };
    case TYPES.GET_IPS_SUCCESS:
      return {
        ...state,
        ips: payload,
      };
    case TYPES.SELECT_IPS_SUCCESS:
      return {
        ...state,
        detail: payload,
      };
    case TYPES.SELECT_IPS_ADD:
      return {
        ...state,
        detail: null,
      };
    case TYPES.IPS_ADD_SUCCESS:
      return {
        ...state,
        detail: null,
        status: "add",
      };
    case TYPES.GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        allow: payload,
      };
    default:
      return state;
  }
};

export default ipsReducer;
