import { Card, Table, message } from "antd";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  snapTopupWithdrawList,
  unSnapTopupWithdrawList,
} from "../../../../../store/topup_withdraw_history/history.action";
import { topupWithdrawListSelector } from "../../../../../store/topup_withdraw_history/history.selector";
import { snapIpsList } from "../../../../../store/ips/ips.action";

const ClientDetailTxHistory = (props) => {
  // -------------------------- VAR --------------------------
  const { ips, client, method } = props;
  // console.log("method history", method);
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  const topupWithdrawHistory = useSelector(topupWithdrawListSelector);
  const data = topupWithdrawHistory?.filter(
    (history) => history?.ipsId === ips && history?.clientId === client
  );
  const sortedData = data?.sort((a, b) => b.datetime - a.datetime);
  // console.log({ data });
  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    dispatch(snapTopupWithdrawList());
    return () => unSnapTopupWithdrawList();
  }, [dispatch]);
  // -------------------------- FUNCTION --------------------------
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        message.success("Copied to clipboard");
      },
      () => {
        message.error("Failed to copy to clipboard");
      }
    );
  };

  const handleTxId = (str, maxLength) => {
    if (str.length <= maxLength) {
      return str; // No need to truncate
    }

    const startLength = Math.floor((maxLength - 6) / 2); // Length of the start part
    const endLength = maxLength - 6 - startLength; // Length of the end part

    const start = str.slice(0, startLength);
    const end = str.slice(-endLength);

    return `${start}...${end}`;
  };

  const getColumns = () => {
    const walletColumns = [
      {
        key: "date",
        dataIndex: "datetime",
        title: "Date",
        align: "center",
        sorter: (a, b) => b.datetime - a.datetime,
        render: (values) => (
          <div style={{ textAlign: "left", paddingLeft: 10 }}>
            {moment(values.toDate()).format("MMM DD, YYYY")}
          </div>
        ),
        width: "25%",
      },
      {
        key: "txId",
        dataIndex: "txId",
        title: <>Tx Hash</>,
        align: "center",
        width: "25%",
        render: (value) =>
          value ? (
            <div
              style={{
                textAlign: "right",
                paddingRight: "30%",
                cursor: "pointer",
              }}
              onClick={() => handleCopy(value)}
              className="address-hover-color"
            >
              {handleTxId(value, 18)}
            </div>
          ) : (
            "-"
          ),
      },
      {
        key: "network",
        dataIndex: "walletNetwork",
        title: "Network",
        align: "center",
        width: "25%",
        render: (value) =>
          value ? (
            <div style={{ textAlign: "right", paddingRight: "30%" }}>
              {value.toUpperCase()}
            </div>
          ) : (
            "-"
          ),
      },
      {
        key: "client",
        dataIndex: "walletAmount",
        title: (
          <>
            Client <br />
            Topup/Withdraw
          </>
        ),
        align: "center",
        width: "25%",
        render: (value, record) =>
          value > 1000 ? (
            <div style={{ textAlign: "right", paddingRight: "30%" }}>
              {record?.txType === "topup" ? "+" : "-"}
              {renderNumber(value)}K USDT
            </div>
          ) : (
            <div style={{ textAlign: "right", paddingRight: "30%" }}>
              {record?.txType === "topup" ? "+" : "-"}
              {numeral(value).format("0,0")} USDT
            </div>
          ),
      },
    ];
    const onusColumns = [
      {
        key: "date",
        dataIndex: "datetime",
        title: "Date",
        align: "center",
        sorter: (a, b) => b.datetime - a.datetime,
        render: (values) => (
          <div style={{ textAlign: "left", paddingLeft: 10 }}>
            {moment(values.toDate()).format("MMM DD, YYYY")}
          </div>
        ),
        width: "25%",
      },
      {
        key: "txId",
        dataIndex: "txId",
        title: <>Tx Hash</>,
        align: "center",
        width: "25%",
        render: (value) =>
          value ? (
            <div style={{ textAlign: "right", paddingRight: "30%" }}>
              {value}
            </div>
          ) : (
            "-"
          ),
      },
      {
        key: "network",
        dataIndex: "onusNetwork",
        title: "Network",
        align: "center",
        width: "25%",
        render: (value) =>
          value ? (
            <div style={{ textAlign: "right", paddingRight: "30%" }}>
              {value}
            </div>
          ) : (
            "-"
          ),
      },
      {
        key: "client",
        dataIndex: "walletAmount",
        title: (
          <>
            Client <br />
            Topup/Withdraw
          </>
        ),
        align: "center",
        width: "25%",
        render: (value, record) =>
          value > 1000 ? (
            <div style={{ textAlign: "right", paddingRight: "30%" }}>
              {record?.txType === "topup" ? "+" : "-"}
              {renderNumber(value)}K USDT
            </div>
          ) : (
            <div style={{ textAlign: "right", paddingRight: "30%" }}>
              {record?.txType === "topup" ? "+" : "-"}
              {numeral(value).format("0,0")} USDT
            </div>
          ),
      },
    ];
    const bankColumns = [
      {
        key: "date",
        dataIndex: "datetime",
        title: "Date",
        align: "center",
        sorter: (a, b) => b.datetime - a.datetime,
        render: (value) => (
          <div style={{ textAlign: "left", paddingLeft: 10 }}>
            {moment(value.toDate()).format("MMM DD, YYYY")}
          </div>
        ),
        width: "20%",
      },
      {
        key: "bank",
        dataIndex: "bankName",
        title: "Bank name",
        align: "center",
        width: "20%",
        render: (value) =>
          value ? (
            <div style={{ textAlign: "right", paddingRight: "30%" }}>
              {value}
            </div>
          ) : (
            "-"
          ),
      },
      {
        key: "accountName",
        dataIndex: "bankAccountName",
        title: "Account Name",
        align: "center",
        width: "20%",
        render: (value) =>
          value ? (
            <div style={{ textAlign: "right", paddingRight: "30%" }}>
              {value}
            </div>
          ) : (
            "-"
          ),
      },
      {
        key: "accountId",
        dataIndex: "bankAccountNumber",
        title: "Account Number",
        align: "center",
        width: "20%",
        render: (value) =>
          value ? (
            <div style={{ textAlign: "right", paddingRight: "30%" }}>
              {value}
            </div>
          ) : (
            "-"
          ),
      },
      {
        key: "client",
        dataIndex: "bankAmount",
        title: (
          <>
            Client <br />
            Topup/Withdraw
          </>
        ),
        align: "center",
        width: "20%",
        render: (value, record) =>
          value > 1000 ? (
            <div style={{ textAlign: "right", paddingRight: "30%" }}>
              {record?.txType === "topup" ? "+" : "-"}
              {renderNumber(value)}K USD
            </div>
          ) : (
            <div style={{ textAlign: "right", paddingRight: "30%" }}>
              {record?.txType === "topup" ? "+" : "-"}
              {numeral(value).format("0,0")} USD
            </div>
          ),
      },
    ];
    if (method === "bank") {
      return bankColumns;
    } else if (method === "wallet") {
      return walletColumns;
    } else {
      return onusColumns;
    }
  };

  // const handleData = (tempData) => {
  //   if (method === "bank") {
  //     return tempData.filter((item) => item.method === "bank");
  //   }
  //   if (method === "wallet" || method === "onus") {
  //     return tempData.filter((item) => item.method === "wallet");
  //   }
  //   return null;
  // };

  const renderNumber = (value) => {
    const valuex1000 = value / 1000 || 0;
    const stringValuex1000 = valuex1000.toString();
    console.log({ valuex1000, stringValuex1000 });
    return stringValuex1000;
  };
  // -------------------------- RENDER --------------------------
  // --------------------------- MAIN --------------------------
  const columns = getColumns();
  // const useData = handleData(data);
  return (
    <Card size="small">
      <Table
        columns={columns}
        size="small"
        dataSource={sortedData}
        scroll={{ x: 512 }}
        className="history-table"
        rowKey={(record) => record.id}
      />
    </Card>
  );
};
export default ClientDetailTxHistory;
