import { TYPES } from "./client.type";

const initState = {
  client: null,
  clientList: null,
  clientListById: null,
  status: "add",
  allow: null,
  detail: null,
};

const clientReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case TYPES.CLIENT_LIST_SUCCESS:
      return {
        ...state,
        clientList: payload,
      };
    case TYPES.CLIENT_LIST_SUCCESS_BY_ID:
      return {
        ...state,
        clientListById: payload,
      };
    case TYPES.GET_CLIENT_SUCCESS:
      return {
        ...state,
        client: payload,
      };
    case TYPES.SELECT_CLIENT_SUCCESS:
      return {
        ...state,
        detail: payload,
      };
    case TYPES.SELECT_CLIENT_ADD:
      return {
        ...state,
        detail: null,
      };
    case TYPES.CLIENT_ADD_SUCCESS:
      return {
        ...state,
        detail: null,
        status: "add",
      };
    case TYPES.GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        allow: payload,
      };
    default:
      return state;
  }
};

export default clientReducer;
