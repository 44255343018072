import React from "react";
import { Form, Select } from "antd";

const ClientRequestProcessTime = (props) => {
  // -------------------------- VAR --------------------------
  const { setProcessTime } = props;
  const TYPE = [
    { label: "7 days", value: "7" },
    { label: "14 days", value: "14" },
  ];
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  const typeOptions = TYPE;
  // -------------------------- FUNCTION --------------------------
  const handleChange = (e) => {
    setProcessTime(parseInt(e));
  };
  // -------------------------- EFFECT --------------------------

  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="processTime"
      name="processTime"
      label="Process in"
      initialValue="7"
      rules={[
        {
          required: true,
          message: "required field",
        },
      ]}
    >
      <Select options={typeOptions} onChange={handleChange} />
    </Form.Item>
  );
};
export default ClientRequestProcessTime;
