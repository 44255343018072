import { Form, InputNumber, Select } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
const IpsDetailsInterest = (props) => {
  // -------------------------- VAR --------------------------
  const { form, setInterestRate, interestRate, timeValue, setTimeValue } =
    props;
  const timeOptions = [
    { label: "per week", value: "week" },
    { label: "per month", value: "month" },
    { label: "per year", value: "year" },
  ];
  // -------------------------- STATE --------------------------
  const [interest, setInterest] = useState(form.getFieldValue("interest"));
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  const handleChange = (e) => {
    form.setFieldValue("interest", e);
    setInterestRate(e);
    setInterest(e);
  };
  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    if (interestRate) {
      const rateInNumber = parseFloat(interestRate);
      setInterest(rateInNumber);
      // console.log({ rateInNumber });
    } else {
      setInterest("");
    }
  }, [interestRate]);
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="interest"
      name="interest"
      label={
        <span>
          Interest Rate&nbsp;
          <span style={{ color: "red" }}>*</span>
        </span>
      }
      rules={[
        {
          required: true,
          message: "required field",
        },
      ]}
    >
      <InputNumber
        name="interest"
        style={{ width: "100%" }}
        placeholder="Interest Rate"
        value={interest}
        addonAfter={
          <>
            <span>%</span>
            <span style={{ marginLeft: 10 }}>
              <Select
                value={timeValue}
                options={timeOptions}
                onChange={(e) => {
                  setTimeValue(e);
                }}
                style={{ width: 100 }}
              />
            </span>
          </>
        }
        onChange={(e) => {
          handleChange(e);
        }}
      />
      <div>
        <span></span>
      </div>
      {timeValue === "week" && (
        <div>
          <span
            style={{ fontSize: "12px", color: "gray", fontStyle: "italic" }}
          >
            {`${(interest * 52).toFixed(3)} % per year`}
          </span>
        </div>
      )}
      {timeValue === "month" && (
        <div>
          <span
            style={{ fontSize: "12px", color: "gray", fontStyle: "italic" }}
          >
            {`${(interest * 12).toFixed(3)} % per year`}
          </span>
        </div>
      )}
    </Form.Item>
  );
};
export default IpsDetailsInterest;
