export const TYPES = {
  LOADING: "withdrawRequest/LOADING",
  WITHDRAW_REQUEST_LIST_SUCCESS:
    "withdrawRequest/WITHDRAW_REQUEST_LIST_SUCCESS",
  GET_PERMISSIONS_SUCCESS: "withdrawRequest/GET_PERMISSIONS_SUCCESS",
  GET_WITHDRAW_REQUEST_SUCCESS: "withdrawRequest/GET_WITHDRAW_REQUEST_SUCCESS",
  SELECT_WITHDRAW_REQUEST_SUCCESS:
    "withdrawRequest/SELECT_WITHDRAW_REQUEST_SUCCESS",
  SELECT_WITHDRAW_REQUEST_ADD: "withdrawRequest/SELECT_WITHDRAW_REQUEST_ADD",
  WITHDRAW_REQUEST_ADD_SUCCESS: "withdrawRequest/WITHDRAW_REQUEST_ADD_SUCCESS",
};
