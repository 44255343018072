import { Form, Select } from "antd";
import React from "react";
import { PROFIT_2 } from "../../../../../store/data/ips_mgmt";
const IpsDetailsShareProfit = (props) => {
  // -------------------------- VAR --------------------------
  const profitOptions = PROFIT_2;
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="profit"
      name="profit"
      label={
        <span>
          Profit Sharing&nbsp;
          <span style={{ color: "red" }}>*</span>
        </span>
      }
      rules={[
        {
          required: true,
          message: "required field",
        },
      ]}
    >
      <Select options={profitOptions} placeholder="Select Term" />
    </Form.Item>
  );
};
export default IpsDetailsShareProfit;
