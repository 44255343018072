import moment from "moment";

export const DURATION = [
  { label: "All durations", value: "all" },
  { label: "6 months", value: "6" },
  { label: "12 months", value: "12" },
  { label: "24 months", value: "24" },
];
export const DURATION_2 = [
  { label: "6 months", value: "6" },
  { label: "12 months", value: "12" },
  { label: "24 months", value: "24" },
];

export const CONTRACT = [
  { label: "All contract types", value: "all" },
  { label: "Profit Sharing", value: "sharing" },
  { label: "Fixed-rate Interest", value: "fixed" },
];

export const TYPE = [
  { label: "Profit Sharing", value: "sharing" },
  { label: "Fixed-rate Interest", value: "fixed" },
];

export const SHARE_PROFIT = [
  { label: "All shared profit", value: "all" },
  { label: "Weekly", value: "weekly" },
  { label: "Monthly", value: "monthly" },
  { label: "Yearly", value: "yearly" },
];

export const PROFIT_2 = [
  { label: "Weekly", value: "weekly" },
  { label: "Monthly", value: "monthly" },
  { label: "Yearly", value: "yearly" },
];

export const RECEIVED_BY = [
  { label: "by Wallet", value: "wallet" },
  { label: "by Bank", value: "bank" },
  { label: "by Onus", value: "onus" },
];

export const NETWORK = [
  { label: "BEP20", value: "bep20" },
  { label: "TRC20", value: "trc20" },
];

export const CURRENCY = [
  { label: "USD", value: "usd" },
  { label: " NZD", value: "nzd" },
  { label: "VND", value: "vnd" },
  { label: "AUD", value: "aud" },
];

export const IPS_DATA = [
  {
    id: "ips1",
    client: "Eric Vuong",
    initialFund: 12000000,
    type: "fixed",
    startAt: moment().subtract(1, "weeks"),
    duration: "12",
    profit: "weekly",
    interest: "0.92%",
    receiveInterest: "wallet",
    network: "bep20",
    walletAddress: "0x123",
    ips: [
      {
        uid: "-1",
        name: "file1.pdf",
        status: "done",
        url: "https://drive.google.com/file/d/11f6B006FDWmwKpqxlntEbTriXCumIr70/view?usp=share_link",
      },
    ],
  },
  {
    id: "ips3",
    client: "Hien Truong",
    initialFund: 12000000,
    type: "fixed",
    startAt: moment().subtract(5, "weeks"),
    duration: "24",
    profit: "monthly",
    interest: "4%",
    receiveInterest: "wallet",
    network: "trc20",
    walletAddress: "0x456",
    ips: [
      {
        uid: "-1",
        name: "file1.pdf",
        status: "done",
        url: "https://drive.google.com/file/d/11f6B006FDWmwKpqxlntEbTriXCumIr70/view?usp=share_link",
      },
    ],
  },
  {
    id: "ips2",
    client: "Hien Vuong",
    initialFund: 12000000,
    type: "fixed",
    startAt: moment().subtract(2, "weeks"),
    duration: "12",
    profit: "weekly",
    interest: "1%",
    receiveInterest: "bank",
    bankName: "Vietcombank",
    accountId: "0711000999",
    currency: "usd",
    ips: [
      {
        uid: "-1",
        name: "file1.pdf",
        status: "done",
        url: "https://drive.google.com/file/d/11f6B006FDWmwKpqxlntEbTriXCumIr70/view?usp=share_link",
      },
    ],
  },
];

export const CLIENT_LIST = [
  {
    id: "1234",
    userName: "John Nguyen",
  },
  {
    id: "2342",
    userName: "Y Tran",
  },
  {
    id: "2343",
    userName: "Kien Nguyen",
  },
  {
    id: "4533",
    userName: "Dinh Pham",
  },
  {
    id: "sg143",
    userName: "Quan Tran",
  },
];

export const MODEL_IPS = {
  client: null,
  fund: null,
  type: null,
  startAt: null,
  duration: null,
  profit: null,
  ips: null,
};
