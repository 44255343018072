import React from "react";
import Router from "./screen/00_Nav/router";
import { ConfigProvider } from "antd";

ConfigProvider.config({
  theme: {
    primaryColor: "#4267b2",
    successColor: "#26a69a",
  },
});

const App = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#4267b2",
          colorSuccess: "#009688",
          colorError: "#ef5350",
        },
      }}
    >
      <Router />
    </ConfigProvider>
  );
};

export default App;
