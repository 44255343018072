import { Button, message } from "antd";
import { Timestamp } from "firebase/firestore";
import moment from "moment";
import numeral from "numeral";
import React, { useState } from "react";
import { MODEL_SHARED_PROFIT } from "../../../../../../model/shared_profit";
import {
  addProfitHistory,
  selectProfitHistory,
  updateProfitHistory,
} from "../../../../../../store/shared_profit_history/history.action";
import { useDispatch } from "react-redux";

const ClientDetailIpsHistoryCreateUpdate = (props) => {
  // -------------------------- VAR --------------------------
  const { record, ipsId, ipsList, setDaysLeft, disabled } = props;
  //   console.log("ipsList", ipsList);
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  // -------------------------- FUNCTION --------------------------
  const handleAmount = () => {
    const interest = ipsList.find((ips) => ips.id === ipsId)?.interest;
    const fund = ipsList.find((ips) => ips.id === ipsId)?.fund;
    const amount = (interest * fund) / 100;

    return amount;
  };

  const handleClick = () => {
    const update = {
      ...record,
      status: "deposited",
    };

    dispatch(
      updateProfitHistory({ profitHistory: record, update }, (response) => {
        if (response.status === 200) {
          const detail = response.data;
          console.log({ detail });
        } else {
          setDaysLeft(0);
          const error = response.data;
          message.error(error);
        }
      })
    );
  };

  // -------------------------- EFFECT --------------------------

  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Button type="primary" onClick={handleClick} disabled={disabled}>
      Mark as deposit
    </Button>
  );
};
export default ClientDetailIpsHistoryCreateUpdate;
