import { Form, Input } from "antd";
import React, { useEffect, useState } from "react";
// import { CLIENT_LIST } from "../../../../../store/data/ips_mgmt";
import { set } from "lodash";
const IpsDetailsNumber = (props) => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------
  // const [clientOptions, setClientOptions] = useState([]);
  // -------------------------- REDUX --------------------------
  // const clientList = CLIENT_LIST;
  // -------------------------- FUNCTION --------------------------

  // -------------------------- EFFECT --------------------------
  // useEffect(() => {
  //   const options = clientList?.map((item) => {
  //     return { label: item?.userName, value: item?.id };
  //   });
  //   setClientOptions(options);
  // }, [clientList]);
  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="ipsId"
      name="ipsId"
      label={
        <span>
          IPS Number&nbsp;
          <span style={{ color: "red" }}>*</span>
        </span>
      }
      rules={[
        {
          required: true,
          message: "required field",
        },
      ]}
    >
      {/* <Select
        options={clientOptions}
        placeholder="Client account"
        allowClear
        showSearch
        optionFilterProp="label"
      /> */}
      <Input style={{ width: "100%" }} placeholder="IPS Number" />
    </Form.Item>
  );
};
export default IpsDetailsNumber;
