import React, { useState, useEffect } from "react";
import { Col, Row, Form, Typography, Button, message, Modal } from "antd";
import { useDispatch } from "react-redux";
import { Timestamp } from "firebase/firestore";
import {
  addTopupWithdraw,
  selectTopupWithdraw,
} from "../../../store/topup_withdraw_history/history.action";
import { updateIps } from "../../../store/ips/ips.action";
import { updateClient } from "../../../store/client/client.action";
import { MODEL_REQUEST } from "../../../model/request";
import moment from "moment";
import ClientRequestRequestTime from "./component/component/client_request_component/dialog_request_request_time";
import ClientRequestProcessTime from "./component/component/client_request_component/dialog_request_process_time";
import ClientRequestWithdrawTime from "./component/component/client_request_component/dialog_request_withdraw_time";
import ClientRequestAmount from "./component/component/client_request_component/dialog_request_amount";
import {
  addWithdrawRequest,
  selectWithdrawRequest,
} from "../../../store/withdraw_request_history/history.action";

const ClientRequestCreate = (props) => {
  // -------------------------- VAR --------------------------
  const { open, setOpen, client, ips, method, ipsList, clientDetail } = props;
  const { Title } = Typography;
  const ipsData = ipsList?.filter((ipsDetail) => ipsDetail.id === ips)[0];
  // ------------------------- STATE --------------------------
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(MODEL_REQUEST.model);
  const [requestOn, setRequestOn] = useState(moment());
  const [processTime, setProcessTime] = useState(7);
  // ------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  // ------------------------ EFFECT --------------------------

  // ----------------------- FUNCTION --------------------------
  const handleDateTime = (date) => {
    const jsDate = date.toDate();
    return Timestamp.fromDate(jsDate);
  };

  const onFinish = (values) => {
    console.log({ values });
    const transaction = {
      ...data,
      ...values,
      ipsId: ips,
      clientId: client,
      requestTime: handleDateTime(values.requestTime),
      withdrawTime: handleDateTime(moment(values.withdrawTime, "MMM DD, YYYY")),
    };
    setLoading(true);
    dispatch(
      addWithdrawRequest({ withdrawRequest: transaction }, (response) => {
        setLoading(false);
        handleReset();
        setOpen(false);
        if (response.status === 200) {
          const detail = response.data;
          console.log({ detail });
          const currentFund = ipsData?.fund;
          let newFund = currentFund;
          if (method === "bank") {
            newFund = currentFund - detail?.amount;
          } else {
            newFund = currentFund - detail?.amount;
          }

          const ipsUpdate = {
            ...ipsData,
            fund: newFund,
          };
          const clientUpdate = {
            ...clientDetail,
            totalFund: newFund + " " + clientDetail?.totalFund?.split(" ")[1],
          };
          console.log({ ipsUpdate, clientUpdate, clientDetail });
          dispatch(
            updateIps({ ips: ipsData, update: ipsUpdate }, (response) => {
              if (response.status === 200) {
                message.success("Successfully update ips");
              } else {
                const error = response.data;
                message.error(error);
              }
            })
          );
          dispatch(
            updateClient(
              { client: clientDetail, update: clientUpdate },
              (response) => {
                setLoading(false);
                if (response.status === 200) {
                  message.success("Successfully update client");
                } else {
                  const error = response.data;
                  message.error(error);
                }
              }
            )
          );
          dispatch(selectWithdrawRequest({ detail }));
          message.success("Successfully added request");
        } else {
          setLoading(false);
          const error = response.data;
          message.error(error);
        }
      })
    );
  };

  const onFinishFailed = (error) => {
    console.log({ error });
  };

  const handleReset = () => {
    form.setFieldsValue(MODEL_REQUEST.model);
  };

  const handleCancel = () => {
    handleReset();
    setOpen(false);
  };
  // ------------------------ RENDER --------------------------
  const renderRequestTime = () => {
    return <ClientRequestRequestTime setRequestOn={setRequestOn} />;
  };

  const renderProcessTime = () => {
    return <ClientRequestProcessTime setProcessTime={setProcessTime} />;
  };

  const renderWithdrawTime = () => {
    return (
      <ClientRequestWithdrawTime
        requestOn={requestOn}
        processTime={processTime}
        form={form}
      />
    );
  };

  const renderAmount = () => {
    return <ClientRequestAmount method={method} ipsData={ipsData} />;
  };

  // ------------------------- MAIN --------------------------
  const layout = {
    labelAlign: "left",
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      footer={null}
      confirmLoading={loading}
    >
      <Col span={24} style={{ paddingTop: 10 }}>
        <Title level={4}>Withdrawal Request</Title>
      </Col>
      <Col span={24} style={{ paddingTop: 30 }}>
        <Form
          requiredMark={false}
          size="small"
          autoComplete="off"
          form={form}
          {...layout}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          {renderRequestTime()}
          {renderProcessTime()}
          {renderWithdrawTime()}
          {renderAmount()}
          <div
            style={{
              padding: "20px 40px 20px 160px ",
            }}
          >
            <div
              style={{
                width: "50%",
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                size="medium"
                style={{ width: "100%" }}
                loading={loading}
              >
                Create
              </Button>
            </div>
          </div>
        </Form>
      </Col>
    </Modal>
  );
};

export default ClientRequestCreate;
