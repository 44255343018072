import numeral from "numeral";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

export const getStatisticStyle = (strinValue) => {
  let value = parseFloat(strinValue);

  const color = value > 0 ? "green" : value < 0 ? "red" : "black";
  const symbol =
    value > 0 ? <ArrowUpOutlined /> : value < 0 ? <ArrowDownOutlined /> : null;
  return (
    <span style={{ color }}>
      {convertNumber(value)} {symbol}
    </span>
  );
};

export const bytetoSize = (x) => {
  const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let l = 0,
    n = parseInt(x, 10) || 0;
  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
};

export const shortenNumber = (number) => {
  return parseFloat(number.toFixed(1));
};

export const numberToOrdinal = (number) => {
  if (number === 0) {
    return number;
  }

  const lastDigit = number % 10;
  const lastTwoDigits = number % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return number + "th";
  }

  switch (lastDigit) {
    case 1:
      return number + "st";
    case 2:
      return number + "nd";
    case 3:
      return number + "rd";
    default:
      return number + "th";
  }
};

function formatNumberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const numberToShortString = (number) => {
  if (Number.isNaN(number) || Number(number) === 0) return 0;

  const symbols = ["", "K", "M", "B", "T", "Q", "P", "E"];

  const tier = Math.floor(Math.log10(Math.abs(number)) / 3);
  const suffix = symbols[tier];

  if (tier === 0) return formatNumberWithCommas(number);

  const scale = 10 ** (tier * 3);
  const scaledNumber = number / scale;

  return formatNumberWithCommas(scaledNumber) + suffix;
};

export const convertNumber = (numberString) => {
  const number = parseFloat(numberString);

  let label = "";
  label = numeral(number).format("0,0.000");
  let target = number;
  // console.log({ target });
  let abs = Math.abs(target);

  if (abs >= 1000 && abs < 1000000) {
    target = number / 1000;
    label = `${numeral(target).format("0,0.00")}K`;
  } else if (abs >= 1000000) {
    target = number / 1000000;
    label = `${numeral(target).format("0,0.00")}M`;
  }

  return label;
};
