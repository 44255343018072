import { Button, Card, Table } from "antd";
import moment from "moment";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { convertNumber } from "../../../../../util/number.function";

const PartnerDetailIpsHistory = (props) => {
  // -------------------------- VAR --------------------------
  const { partner, ipsList } = props;
  console.log({ partner, ipsList });
  // -------------------------- STATE --------------------------
  // const [daysLeft, setDaysLeft] = useState(7);
  // const [isSaturday9AM, setIsSaturday9AM] = useState(false);
  // const [countdownActive, setCountdownActive] = useState(false);
  // const [daysCountdown, setDaysCountdown] = useState(true);
  // -------------------------- REDUX --------------------------
  // const dispatch = useDispatch();
  // const profitHistory = useSelector(profitHistoryListSelector);
  // const data = profitHistory?.filter(
  //   (history) => history?.ipsId === ips && history?.clientId === partner
  // );
  // console.log({ data });
  // -------------------------- FUNCTION --------------------------
  const convertTimestampToDate = (inputTimestamp) => {
    // return moment.unix(inputTimestamp.seconds);
    return new Date(inputTimestamp?.seconds * 1000);
  };

  const handleCurrency = (ipsItem) => {
    // const ipsItem = ipsList.find((ipsDetail) => ipsDetail.id === ips);
    let currency = "";
    if (ipsItem?.method === "bank") {
      currency = ipsItem?.bank?.currency;
    } else {
      currency = "usdt";
    }
    return currency.toUpperCase();
  };

  const getColumns = () => {
    const columns = [
      {
        key: "ipsId",
        dataIndex: "ipsId",
        title: "IPS ID",
        align: "center",
        sorter: (a, b) => a.datetime - b.datetime,
        render: (value) => alignLeft(value?.toUpperCase()),
        width: "20%",
      },
      {
        key: "fund",
        dataIndex: "fund",
        title: "Total Fund",
        align: "center",
        width: "20%",
        render: (value, record) =>
          alignRight(convertNumber(value) + " " + handleCurrency(record)),
      },
      {
        key: "startAt",
        dataIndex: "startAt",
        title: "Start Date",
        align: "center",
        sorter: (a, b) => b - a,
        render: (value) =>
          alignLeft(moment(value?.toDate()).format("MMM DD, YYYY")),
      },
      {
        key: "endAt",
        dataIndex: "endAt",
        title: "End Date",
        align: "center",
        sorter: (a, b) => b - a,
        render: (value, record) =>
          alignLeft(
            moment(record?.startAt?.toDate())
              .add(record?.duration, "months")
              .format("MMM DD, YYYY")
          ),
      },
      {
        key: "commissionFee",
        dataIndex: "commissionFee",
        title: "Commission Fee",
        align: "center",
        width: "20%",
        render: () => alignRight(partner?.commissionFee + "%"),
      },
    ];
    return columns;
  };
  // -------------------------- EFFECT --------------------------
  // useEffect(() => {
  //   dispatch(snapProfitHistoryList());
  // }, [dispatch]);

  // useEffect(() => {
  //   if (daysCountdown && data) {
  //     // Calculate the time remaining until the next day (00:00:00)
  //     const targetTimestamp = data?.filter(
  //       (history) => history.status === "undeposited"
  //     )?.[0]?.datetime;

  //     const targetDate = convertTimestampToDate(targetTimestamp);
  //     const currentDate = new Date();
  //     let daysRemaining = (targetDate - currentDate) / (24 * 60 * 60 * 1000);
  //     console.log({ daysRemaining });
  //     if (daysRemaining > 7) {
  //       setDaysLeft(Math.ceil(daysRemaining));
  //     } else {
  //       daysRemaining = 6 - currentDate.getDay();
  //       if (daysRemaining <= 0) {
  //         daysRemaining = 7;
  //       }
  //       setDaysLeft(daysRemaining);
  //     }
  //     const nextDate = new Date(currentDate);
  //     nextDate.setHours(24, 0, 0, 0); // Set to the next day (00:00:00)
  //     nextDate.setMilliseconds(0); // Clear milliseconds
  //     const timeLeft = nextDate - currentDate;

  //     const updateDaysLeft = () => {
  //       const now = new Date();
  //       let newDaysLeft = (targetDate - now) / (24 * 60 * 60 * 1000);
  //       if (newDaysLeft > 7) {
  //         setDaysLeft(Math.ceil(newDaysLeft));
  //       } else {
  //         newDaysLeft = 6 - now.getDay();
  //         // if (newDaysLeft <= 0) {
  //         //   newDaysLeft = 7;
  //         // }
  //         setDaysLeft(newDaysLeft);
  //       }

  //       if (newDaysLeft > 0) {
  //         // Schedule the next update when the new day begins
  //         const count24h = 24 * 60 * 60 * 1000; // 24 hours
  //         setTimeout(updateDaysLeft, count24h);
  //       } else {
  //         setCountdownActive(true);
  //         setDaysCountdown(false);
  //       }
  //     };
  //     setTimeout(updateDaysLeft, timeLeft); // Update when the new day begins
  //   }
  // }, [daysCountdown, data]);

  // useEffect(() => {
  //   let intervalId;

  //   const checkIsSaturday9AM = () => {
  //     const currentDate = moment();
  //     const saturday9AM = moment().day(6).hour(9).minute(0).second(0);
  //     // const saturday9AM = moment().minute(56);

  //     if (currentDate.isSame(saturday9AM)) {
  //       setIsSaturday9AM(true);
  //       setCountdownActive(false); // Stop the countdown
  //     }
  //     // else {
  //     //   setIsSaturday9AM(false);
  //     // }
  //   };

  //   if (countdownActive) {
  //     // Initially check and start the countdown
  //     // setIsSaturday9AM(false);
  //     checkIsSaturday9AM();
  //     intervalId = setInterval(checkIsSaturday9AM, 60000); // Check every 1 minute
  //   }

  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [countdownActive]);
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  const columns = getColumns();
  return (
    <Card size="small">
      <Table
        columns={columns}
        size="small"
        dataSource={ipsList}
        className="history-table"
        scroll={{ x: 512 }}
        rowKey={(record) => record.id}
      />
      {/* <Table
        columns={columns}
        size="small"
        dataSource={partner} 
        className="history-table"
        rowKey={(record) => record.id}
      /> */}
    </Card>
  );
};
export default PartnerDetailIpsHistory;

const alignLeft = (value) => {
  return (
    <div
      style={{
        textAlign: "left",
        paddingLeft: 20,
      }}
    >
      {value}
    </div>
  );
};

const alignRight = (value) => {
  return (
    <div
      style={{
        textAlign: "right",
        paddingLeft: 20,
      }}
    >
      {value}
    </div>
  );
};
