import { Form, Input } from "antd";
import React from "react";

const IpsDetailsOnusEmail = (props) => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="onusMail"
      name="onusMail"
      label=" "
      colon={false}
      rules={[
        {
          required: true,
          message: "Please input email",
        },
        {
          whitespace: true,
          message: "Invalid",
        },
        {
          type: "email",
          message: "Wrong email format",
        },
      ]}
    >
      <Input style={{ width: "100%" }} placeholder="Email" />
    </Form.Item>
  );
};
export default IpsDetailsOnusEmail;
