import { Card, Col, Row, Typography, Select, Button } from "antd";
import React, { useEffect, useState } from "react";
import {
  IPS_DETAILS,
  IPS_HISTORY,
  IPS_OPTIONS,
} from "../../../../store/data/client_mgmt";
import moment from "moment";
import IpsHistory from "./component/partner_detail_ips_history";
import { convertNumber } from "../../../../util/number.function";
// import ClientDetailIpsSelect from "./component/client_detail_ips_select";
import PartnerDetailIpsHistory from "./component/partner_detail_ips_history";
import PartnerDetailIpsCard from "./component/partner_detail_ips_card";

const { Title, Text } = Typography;
const PartnerDetailIps = (props) => {
  // -------------------------- VAR --------------------------
  const { partnerDetail, ipsList } = props;
  // const ipsOptions = ipsList?.map((ips) => ({
  //   label: ips?.ipsId,
  //   value: ips?.id,
  // }));
  // -------------------------- STATE --------------------------
  // const [ips, setIps] = useState("");
  // const [client, setClient] = useState(null);
  // -------------------------- REDUX --------------------------
  const ipsData = ipsList;
  // -------------------------- FUNCTION --------------------------
  // -------------------------- EFFECT --------------------------
  // useEffect(() => {
  //   if (ipsList.length > 0) {
  //     setClient(ipsList[0]?.clientId);
  //     if (ipsOptions.length > 0) {
  //       setIps(ipsOptions[0]?.value);
  //     } else {
  //       setIps("No IPS yet");
  //     }
  //   }
  // }, [ipsList]);

  // -------------------------- RENDER --------------------------
  // const renderTop = () => {
  //   const options = ipsOptions;
  //   return (
  //     <Col xl={24} lg={24} md={24} xs={24}>
  //       <Row align="middle">
  //         <Col span={2}>IPS:</Col>
  //         <Col span={19}>
  //           {options.length > 0 ? (
  //             <Select options={options} onSelect={setIps} value={ips} />
  //           ) : (
  //             <Text style={{ fontSize: 15, fontStyle: "italic" }}>{ips}</Text>
  //           )}
  //         </Col>
  //       </Row>
  //     </Col>
  //   );
  // };

  // const renderIpsCard = () => {
  //   const itemData = ipsData?.find((item) => item.id === ips);
  //   // console.log({ data });
  //   // console.log({ itemData });
  //   return (
  //     <>
  //       {itemData ? (
  //         <Col span={24}>
  //           <PartnerDetailIpsCard
  //             data={itemData}
  //             partnerDetail={partnerDetail}
  //           />
  //         </Col>
  //       ) : null}
  //     </>
  //   );
  // };

  const renderHistory = () => {
    return (
      <>
        <Col span={24}>
          <Title style={{ fontSize: 16 }}>Referral IPS</Title>
        </Col>
        {ipsData ? (
          <Col span={24}>
            <PartnerDetailIpsHistory
              // ips={ips}
              partner={partnerDetail}
              ipsList={ipsData}
            />
          </Col>
        ) : null}
      </>
    );
  };
  // -------------------------- MAIN --------------------------
  return (
    <Row gutter={[16, 16]}>
      {/* {renderTop()} */}
      {/* {renderIpsCard()} */}
      {renderHistory()}
    </Row>
  );
};
export default PartnerDetailIps;
