import moment from "moment/moment";

export const MODEL_TOPUP_WITHDRAW = {
  model: {
    ipsId: "",
    clientId: "",
    txType: "topup",
    createAt: moment(),
    datetime: moment(),
    txId: "",
    onusNetwork: "",
    walletNetwork: "bep20",
    walletAmount: "",
    bankName: "",
    bankAccountName: "",
    bankAccountNumber: "",
    bankAmount: "",
  },
};
