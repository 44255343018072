import { Button, message } from "antd";
import { Timestamp } from "firebase/firestore";
import moment from "moment";
import numeral from "numeral";
import React, { useState } from "react";
import { MODEL_SHARED_PROFIT } from "../../../../../../model/shared_profit";
import {
  updateCommissionHistory,
  selectCommissionHistory,
} from "../../../../../../store/commission_fee_history/history.action";
import { useDispatch } from "react-redux";

const PartnerDetailCommissionHistoryCreateUpdate = (props) => {
  // -------------------------- VAR --------------------------
  const { record, setDaysLeft, disabled } = props;
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  // -------------------------- FUNCTION --------------------------
  const handleClick = () => {
    const update = {
      ...record,
      status: "deposited",
    };

    dispatch(
      updateCommissionHistory(
        { commissionHistory: record, update },
        (response) => {
          if (response.status === 200) {
            const detail = response.data;
          } else {
            setDaysLeft(0);
            const error = response.data;
            message.error(error);
          }
        }
      )
    );
  };

  // -------------------------- EFFECT --------------------------

  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Button
      type="primary"
      size="small"
      onClick={handleClick}
      style={{
        fontSize: 10,
        justifyItems: "center",
      }}
      disabled={disabled}
    >
      Mark as deposit
    </Button>
  );
};
export default PartnerDetailCommissionHistoryCreateUpdate;
