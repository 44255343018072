import { Form, Input } from "antd";
import React from "react";

const IpsDetailsOnusPhone = (props) => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="onusPhone"
      name="onusPhone"
      label=" "
      colon={false}
      rules={[
        {
          required: true,
          message: "Please input phone number",
        },
        {
          whitespace: true,
          message: "Invalid",
        },
        {
          pattern: /^[0-9]+$/,
          message: "Number only",
        },
        {
          min: 9,
          message: "Min length must be at least 9 characters",
        },
      ]}
    >
      <Input style={{ width: "100%" }} placeholder="Telephone" />
    </Form.Item>
  );
};
export default IpsDetailsOnusPhone;
