import { getAuth, signOut } from "firebase/auth";
import {
  collection,
  where,
  getFirestore,
  getDocs,
  query,
  onSnapshot,
  setDoc,
  serverTimestamp,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { app } from "../../config/config";
import { TYPES } from "./ips.type";
import { REF } from "../ref";
import { FULL_PERMISSION } from "../../store/string";
import { MASTER_EMAIL } from "../../config/config";

const firestore = getFirestore(app);
const auth = getAuth(app);

// -------------------------- Snap List  --------------------------
const subIps = [];
export const snapIps = () => (dispatch) => {
  const uid = auth?.currentUser?.uid;
  const email = auth?.currentUser?.email;
  const cRef = collection(firestore, REF.IPS);
  const dRef = doc(cRef, uid);
  if (email === MASTER_EMAIL) {
    const data = {
      firstName: "Master",
      lastName: "Account",
      permissions: FULL_PERMISSION,
      active: true,
    };
    getIpsDataSuccess(dispatch, data);
    // getPermissionsSuccess(dispatch, data);
    return;
  }
  const unSub = onSnapshot(dRef, (snapshot) => {
    if (snapshot) {
      const data = snapshot.data();
      if (data === undefined) {
        signOut(auth);
      } else {
        getIpsDataSuccess(dispatch, data);
        // getPermissionsSuccess(dispatch, data);
      }
    }
  });
  subIps.push(unSub);
};

export const unSnapIps = () => (dispatch) => {
  subIps.forEach((subscriber) => {
    subscriber();
  });
  subIps.length = 0;
};

export const resetIps = (callback) => (dispatch) => {
  getIpsDataSuccess(dispatch, null);
};
// -------------------------- Operation List  --------------------------
// Lấy dữ liệu ips
export const firestoreGetIpsList = (callback) => (dispatch) => {
  const cRef = collection(firestore, REF.IPS);
  // const c1 = where("role", "==", "operator");
  // const qRef = query(cRef, c1);
  // getDocs(qRef).then((snapshot) => {
  getDocs(cRef).then((snapshot) => {
    if (snapshot) {
      const data = snapshot.docs.map((doc) => doc.data());
      getIpsListSuccess(dispatch, data);
    }
    if (callback) {
      callback();
    }
  });
};

export const getIps =
  ({ ipsId }, callback) =>
  (dispatch) => {
    const cRef = collection(firestore, REF.IPS);
    const c1 = where("id", "==", ipsId);
    const qRef = query(cRef, c1);
    getDocs(qRef).then((snapshot) => {
      if (snapshot) {
        const data = snapshot.docs.map((doc) => doc.data());
        getIpsListSuccess(dispatch, data);
      }
      if (callback) {
        callback();
      }
    });
  };

// Theo dõi dữ liệu ips
const subList = [];
export const snapIpsList = (callback) => (dispatch) => {
  const cRef = collection(firestore, REF.IPS);
  // const c1 = where("role", "==", "operator");
  // const qRef = query(cRef, c1);
  const unSub = onSnapshot(cRef, (snapshot) => {
    if (snapshot) {
      const data = snapshot.docs.map((doc) => doc.data());
      getIpsListSuccess(dispatch, data);
      if (callback) {
        callback();
      }
    }
  });
  subList.push(unSub);
};

export const unSnapIpsList = () => (dispatch) => {
  subList.forEach((subscriber) => {
    subscriber();
  });
  subList.length = 0;
};

const ipsListByClient = [];
export const snapIpsListByClient =
  ({ client }, callback) =>
  (dispatch) => {
    const cRef = collection(firestore, REF.IPS);
    const clientId = client?.username || client?.clientId;
    const c1 = where("clientId", "==", clientId);
    const qRef = query(cRef, c1);
    const unSub = onSnapshot(qRef, (snapshot) => {
      if (snapshot) {
        const data = snapshot.docs.map((doc) => doc.data());
        // getIpsListSuccessById(dispatch, data);
        getIpsListSuccess(dispatch, data);
        if (callback) {
          callback(data);
        }
      }
    });
    ipsListByClient.push(unSub);
  };

export const unSnapIpsListByClient = () => (dispatch) => {
  subList.forEach((subscriber) => {
    subscriber();
  });
  ipsListByClient.length = 0;
};

// -------------------------- Operation Add  --------------------------

export const addIps =
  ({ ips }, callback) =>
  (dispatch) => {
    console.log({ ips });
    // const email = ips?.email;
    const ipsId = ips?.ipsId;
    const cRef = collection(firestore, REF.IPS);
    // const c1 = where("role", "==", "operator");
    const c2 = where("ipsId", "==", ipsId);
    const qRef = query(cRef, c2);
    getDocs(qRef)
      .then((snapshot) => {
        if (snapshot) {
          const ipsList = snapshot.docs.map((doc) => doc.data());
          if (ipsList.length === 0) {
            const id = doc(cRef).id;
            const data = {
              ...ips,
              id,
              createAt: serverTimestamp(),
              // role: "operator",
            };

            const dRef = doc(cRef, id);
            setDoc(dRef, data, { merge: true }).then(() => {
              if (callback) {
                callback({
                  status: 200,
                  data,
                });
              }
            });
          } else {
            if (callback) {
              callback({
                status: 500,
                data: "Duplicate IPS number",
              });
            }
          }
        }
      })
      .catch((error) => {
        if (callback) {
          callback({
            status: 500,
            data: error.code,
          });
        }
      });
  };

// -------------------------- Operation Update  --------------------------
// Update ips
export const updateIps =
  ({ ips, update }, callback) =>
  (dispatch) => {
    const cRef = collection(firestore, REF.IPS);
    const id = ips.id;
    // const email = update.email;
    // const c1 = where("role", "==", "operator");
    const c2 = where("ipsId", "==", ips?.ipsId);
    const qRef = query(cRef, c2);
    getDocs(qRef)
      .then((snapshot) => {
        if (snapshot) {
          const ipsList = snapshot.docs
            .map((doc) => doc.data())
            .filter((item) => item.id !== id);
          if (ipsList.length === 0) {
            const dRef = doc(cRef, id);
            updateDoc(dRef, update).then(() => {
              selectIpsSuccess(dispatch, update);
              if (callback) {
                callback({
                  status: 200,
                  data: update,
                });
              }
            });
          } else {
            if (callback) {
              callback({
                status: 500,
                data: "Duplicate Email Address",
              });
            }
          }
        }
      })
      .catch((error) => {
        if (callback) {
          callback({
            status: 500,
            data: error.code,
          });
        }
      });
  };

// -------------------------- Operation Delete  --------------------------

export const deleteIps =
  ({ ips }, callback) =>
  (dispatch) => {
    console.log({ ips });
    const cRef = collection(firestore, REF.IPS);
    const dRef = doc(cRef, ips.id);
    deleteDoc(dRef).then(() => {
      if (callback) {
        callback();
      }
    });
  };

// -------------------------- Update info --------------------------

export const firebaseUpdateLoginTime = () => (dispatch) => {
  const cRef = collection(firestore, REF.IPS);
  const email = auth.currentUser?.email;
  const uid = auth.currentUser?.uid;
  if (email === MASTER_EMAIL) {
    return;
  } else {
    const dRef = doc(cRef, uid);
    const update = {
      onlineAt: serverTimestamp(),
    };
    updateDoc(dRef, update);
  }
};

// -------------------------- Operation Select  --------------------------

export const selectIps =
  ({ detail }, callback) =>
  (dispatch) => {
    console.log("detail action", detail);
    selectIpsSuccess(dispatch, detail);
    if (callback) {
      callback();
    }
  };

export const selectAddIps = (callback) => (dispatch) => {
  selectAddSuccess(dispatch);
  if (callback) {
    callback();
  }
};

export const successAddIps = (callback) => (dispatch) => {
  ipsAddSuccess(dispatch);
  if (callback) {
    callback();
  }
};

export const successDeleteIps = (callback) => (dispatch) => {
  ipsAddSuccess(dispatch);
  if (callback) {
    callback();
  }
};

// -------------------------- Dispatch  --------------------------

const getIpsDataSuccess = (dispatch, ips) => {
  dispatch({
    type: TYPES.GET_IPS_SUCCESS,
    payload: ips,
  });
};

// const getPermissionsSuccess = (dispatch, ips) => {
//   const email = auth?.currentUser?.email;
//   const permissions =
//     email === MASTER_EMAIL ? FULL_PERMISSION : ips?.permissions;
//   dispatch({
//     type: TYPES.GET_PERMISSIONS_SUCCESS,
//     payload: permissions,
//   });
// };

const getIpsListSuccess = (dispatch, data) => {
  dispatch({
    type: TYPES.IPS_LIST_SUCCESS,
    payload: data,
  });
};

const getIpsListSuccessById = (dispatch, data) => {
  dispatch({
    type: TYPES.IPS_LIST_SUCCESS_BY_CLIENT,
    payload: data,
  });
};

const selectIpsSuccess = (dispatch, data) => {
  dispatch({
    type: TYPES.SELECT_IPS_SUCCESS,
    payload: data,
  });
};

const ipsAddSuccess = (dispatch) => {
  dispatch({
    type: TYPES.IPS_ADD_SUCCESS,
  });
};

const selectAddSuccess = (dispatch) => {
  dispatch({
    type: TYPES.SELECT_IPS_ADD,
  });
};
