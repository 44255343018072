import { Form, Input } from "antd";
import React from "react";
import { useEffect } from "react";

const PartnerDetailCountry = (props) => {
  // -------------------------- VAR --------------------------
  const { form, partnerInfo } = props;
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    form.setFieldValue("country", partnerInfo?.country);
  }, [partnerInfo]);
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="country"
      name="country"
      label="Country"
      rules={[
        {
          required: true,
          message: "required field",
        },
        {
          whitespace: true,
          message: "Invalid",
        },
        {
          pattern: /^[A-Za-z ]+$/,
          message: "Alphabets only",
        },
        {
          max: 32,
          message: "Max length must be 32 characters",
        },
      ]}
    >
      <Input disabled={true} />
    </Form.Item>
  );
};
export default PartnerDetailCountry;
