import React, { useState } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Upload, Popconfirm, Tooltip, Button, Form } from "antd";
import { BsTrash } from "react-icons/bs";
import Resizer from "react-image-file-resizer";

const IpsDetailsFile = (props) => {
  // -------------------------- VAR --------------------------

  const { file, setFile } = props;
  // -------------------------- STATE --------------------------

  // -------------------------- REDUX --------------------------

  // -------------------------- EFFECT --------------------------

  // -------------------------- FUNCTION --------------------------
  //   const handleFileChange = (info) => {
  //     console.log({ info });
  //     if (info?.file?.status === "done") {
  //       // The file has been successfully uploaded
  //       setFile(info?.file?.originFileObj);
  //     } else {
  //       // The file has been successfully removed
  //       console.log(info?.file?.status);
  //     }
  //   };

  const beforeUpload = (file) => {
    const isPdf = file.type === "application/pdf";
    if (!isPdf) {
      message.error("You can only upload PDF file!");
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error("Image must smaller than 5MB!");
    }
    setFile(file);
    return isPdf && isLt5M;
  };
  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="pdfFile"
      name="pdfFile"
      // label={
      //   <span>
      //     IPS Document
      //     <span style={{ color: "red" }}>*</span>
      //   </span>
      // }
      label="IPS Document"
      rules={[
        {
          required: false,
          message: "required field",
        },
      ]}
    >
      <Upload
        name="pdfFile"
        accept=".pdf"
        showUploadList={file ? true : false}
        maxCount={1}
        beforeUpload={beforeUpload}
      >
        <Button type="primary" color="gray">
          Upload PDF
        </Button>
      </Upload>
    </Form.Item>
  );
};

export default IpsDetailsFile;
