import { Form, Input } from "antd";
import React from "react";

const PartnerDetailCommissionFee = (props) => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="commissionFee"
      name="commissionFee"
      label={
        <span>
          Commission&nbsp;
          <span style={{ color: "red" }}>*</span>
        </span>
      }
      rules={[
        {
          required: true,
          message: "required field",
        },
        {
          whitespace: true,
          message: "Invalid",
        },
        {
          pattern: /^[0-9]+$/,
          message: "Number only",
        },
      ]}
    >
      <Input addonAfter="%" placeholder="Commission" />
    </Form.Item>
  );
};
export default PartnerDetailCommissionFee;
