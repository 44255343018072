import { Form } from "antd";
import moment from "moment";
import React from "react";
import DatePickerMoment from "../../../../../../component/datepicker_moment";

const ClientRequestRequestTime = (props) => {
  // -------------------------- VAR --------------------------
  const { setRequestOn } = props;
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  const handleChange = (date) => {
    setRequestOn(date);
  };
  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------

  return (
    <Form.Item
      id="requestTime"
      name="requestTime"
      label="Request on"
      initialValue={moment()}
      rules={[
        {
          required: true,
          message: "required field",
        },
      ]}
    >
      <DatePickerMoment
        style={{ width: "100%" }}
        format={"MMM DD, YYYY"}
        onChange={handleChange}
      />
    </Form.Item>
  );
};
export default ClientRequestRequestTime;
