import { DatePicker, Form } from "antd";
import moment from "moment";
import React, { useState } from "react";
import DatePickerMoment from "../../../../../../component/datepicker_moment";
const ClientTopupWithdrawDatetime = (props) => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------

  return (
    <Form.Item
      id="datetime"
      name="datetime"
      label="Timestamp"
      rules={[
        {
          required: true,
          message: "required field",
        },
      ]}
    >
      <DatePickerMoment
        style={{ width: "100%" }}
        format={"MMM DD, YYYY"}
        showTime
      />
    </Form.Item>
  );
};
export default ClientTopupWithdrawDatetime;
