import { TYPES } from "./history.type";

const initState = {
  topupWithdraw: null,
  topupWithdrawList: null,
  status: "add",
  allow: null,
  detail: null,
};

const topupWithdrawReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case TYPES.TOPUP_WITHDRAW_LIST_SUCCESS:
      return {
        ...state,
        topupWithdrawList: payload,
      };
    case TYPES.GET_TOPUP_WITHDRAW_SUCCESS:
      return {
        ...state,
        topupWithdraw: payload,
      };
    case TYPES.SELECT_TOPUP_WITHDRAW_SUCCESS:
      return {
        ...state,
        detail: payload,
      };
    case TYPES.SELECT_TOPUP_WITHDRAW_ADD:
      return {
        ...state,
        detail: null,
      };
    case TYPES.TOPUP_WITHDRAW_ADD_SUCCESS:
      return {
        ...state,
        detail: null,
        status: "add",
      };
    case TYPES.GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        allow: payload,
      };
    default:
      return state;
  }
};

export default topupWithdrawReducer;
