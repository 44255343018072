import React from "react";
import { Switch, Col, Row, Checkbox } from "antd";
import { PERMISSIONS, PERMISSIONS_SUB } from "../../../store/string";

const OperatorPermission = (props) => {
  // -------------------------- VAR --------------------------
  const { disabled, permissions } = props;
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- EFFECT --------------------------
  // -------------------------- FUNCTION --------------------------
  const onChange = (id, value) => {
    props.onChange({ id, value });
  };

  const onCheck = (id, e) => {
    const value = e.target.checked;
    props.onChange({ id, value });
  };

  // -------------------------- RENDER --------------------------
  const renderMainPermission = ({ item, index }) => {
    const name = item?.name;
    const id = item?.id;
    const checked = permissions?.includes(id);
    const childs = PERMISSIONS_SUB?.filter((item) => item.parent === id);
    return (
      <Col xl={8} lg={8} md={8} xs={12} key={id}>
        <span>
          <Switch checked={checked} disabled={disabled} onChange={(value) => onChange(id, value)} />
          <span style={{ marginLeft: 10 }}>{name}</span>
        </span>
        <Row gutter={[16, 5]} style={{ paddingLeft: 20, paddingTop: 10 }}>
          {childs?.length > 0
            ? renderSubPermission({ childs, disabled: !checked || disabled })
            : null}
        </Row>
      </Col>
    );
  };

  const renderSubPermission = ({ childs, disabled }) => {
    return childs?.map((item, index) => {
      const name = item?.name;
      const id = item?.id;
      const checked = permissions?.includes(id);
      return (
        <Col xl={24} lg={24} md={24} xs={24} key={id}>
          <Checkbox checked={checked} disabled={disabled} onChange={(e) => onCheck(id, e)}>
            {name}
          </Checkbox>
        </Col>
      );
    });
  };

  // -------------------------- MAIN --------------------------
  return (
    <Row gutter={[16, 12]}>
      {PERMISSIONS?.map((item, index) => renderMainPermission({ item, index }))}
    </Row>
  );
};

export default OperatorPermission;
