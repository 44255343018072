import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import lodash from "lodash";
import { URL } from "../url";
//component
import { LoadingScreen } from "../../../component/loading";
import BannedScreen from "../banned";
// redux
import { isLoggedSelector } from "../../../store/auth/auth.selector";
import { userSelector } from "../../../store/user/user.selector";

const PrivateRoute = (props) => {
  // -------------------------- VAR --------------------------
  let location = useLocation();
  const element = props?.element;
  const require = props?.require;
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  const logged = useSelector(isLoggedSelector);
  const user = useSelector(userSelector);
  const permissions = user?.permissions;
  const diff =
    require?.length > 0 ? lodash.difference(require, permissions) : [];
  const allow = diff?.length === 0 ? true : false;
  const active = user?.active;
  // -------------------------- FUNCTION --------------------------
  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------

  if (logged === null || !user) {
    return <LoadingScreen />;
  }
  if (logged === false) {
    return <Navigate to={URL.home} exact state={{ from: location }} replace />;
  }
  if (logged && !active) {
    return <BannedScreen />;
  }
  return logged && allow ? (
    element
  ) : (
    <Navigate to={URL.home} exact state={{ from: location }} replace />
  );
};

export default PrivateRoute;
