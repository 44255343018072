import React from "react";
import { Route } from "react-router-dom";
import { URL } from "../url";
import SignInScene from "../../01_Login/login";

export const routePublict = () => {
  // -------------------------- STATE --------------------------

  // -------------------------- FUNCTION --------------------------

  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------

  return (
    <>
      <Route exact path={URL.home} element={<SignInScene />} />
    </>
  );
};
