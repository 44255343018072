import React, { useState } from "react";
import { Avatar, Divider, List, Button, Badge } from "antd";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { DeleteOutlined } from "@ant-design/icons";
import placeholder from "../../../asset/img/avatar.png";
import OperatorDialogDelete from "../dialog/operator_dialog_delete";
import { selectOperator } from "../../../store/operator/operator.action";

const OperatorList = (props) => {
  // -------------------------- VAR --------------------------
  const { data } = props;
  // -------------------------- STATE --------------------------
  const [operator, setOperator] = useState(null);
  const [dialogDelete, setDialogDelete] = useState(false);
  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  // -------------------------- EFFECT --------------------------
  // -------------------------- FUNCTION --------------------------
  const onClick = (operator) => {
    dispatch(selectOperator({ detail: operator }));
  };
  // -------------------------- RENDER --------------------------
  const renderItem = (item) => {
    const avatar = item?.avatar || placeholder;
    const fullName = `${item?.firstName} ${item?.lastName}`;
    const group = item?.group;
    const logged = item?.logged || false;
    const status = item?.active ? "Active" : "Disabled";
    const onlineAt = item?.onlineAt;
    const onlineTime =
      onlineAt &&
      onlineAt.seconds &&
      moment(onlineAt.toDate()).format("MMM DD,YYYY hh:mm A");

    const onlineIcon = (
      <Badge
        style={{ marginLeft: 10, fontSize: 24 }}
        size="default"
        status={logged ? "success" : "default"}
      />
    );

    const title = (
      <span className="title-list">
        {fullName} - <span className="role">{group}</span> {onlineIcon}
      </span>
    );

    const description = (
      <span>
        {/* Status: {status} */}
        <span className="time">Last online: {onlineTime}</span>
      </span>
    );
    return (
      <List.Item
        key={item.id}
        actions={[renderAction(item)]}
        onClick={() => onClick(item)}
        style={{ cursor: "pointer" }}
      >
        <List.Item.Meta
          avatar={renderAvatar(avatar)}
          title={title}
          description={description}
        />
      </List.Item>
    );
  };

  const renderAvatar = (avatar) => {
    return <Avatar src={avatar} />;
  };

  const renderAction = (item) => {
    return (
      <Button
        danger
        shape="circle"
        type="text"
        size="large"
        onClick={() => {
          setDialogDelete(true);
          setOperator(item);
        }}
      >
        <DeleteOutlined />
      </Button>
    );
  };

  // -------------------------- MAIN --------------------------

  return (
    <div id="scrollableDiv" style={{ overflow: "auto", padding: "0 16px" }}>
      <InfiniteScroll
        dataLength={data?.length}
        hasMore={data?.length < 50}
        endMessage={<Divider plain>All data loaded</Divider>}
        scrollableTarget="scrollableDiv"
      >
        <List dataSource={data} renderItem={(item) => renderItem(item)} />
      </InfiniteScroll>
      <OperatorDialogDelete
        operator={operator}
        visible={dialogDelete}
        handleClose={() => setDialogDelete(false)}
      />
    </div>
  );
};

export default OperatorList;
