import React, { useEffect, useState } from "react";
import { Layout, Button, Col, Row, DatePicker } from "antd";
import { CONTRACT, DURATION, SHARE_PROFIT } from "../../../store/data/ips_mgmt";
import IpsTopSearch from "./component/ips_top_search";
import IpsTopSelect from "./component/ips_top_select";

const IpsTop = (props) => {
  // -------------------------- VAR --------------------------
  const { search, setSearch } = props;
  const { type, setType } = props;
  const { duration, setDuration } = props;
  const { profit, setProfit } = props;
  const { setOpen } = props;
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  const handleClick = () => {
    setOpen(true);
  };
  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  const renderSearchByName = () => {
    const searchPlaceholder = "Search by name...";
    return (
      <Col xl={4} lg={4} md={12} xs={12}>
        <IpsTopSearch
          placeholder={searchPlaceholder}
          search={search}
          setSearch={setSearch}
        />
      </Col>
    );
  };

  const renderContractFilter = () => {
    const typeOptions = CONTRACT;
    return (
      <Col xl={4} lg={4} md={12} xs={12}>
        <IpsTopSelect option={type} setOption={setType} options={typeOptions} />
      </Col>
    );
  };

  const renderDurationFilter = () => {
    const durationOptions = DURATION;
    return (
      <Col xl={4} lg={4} md={12} xs={12}>
        <IpsTopSelect
          option={duration}
          setOption={setDuration}
          options={durationOptions}
        />
      </Col>
    );
  };

  const renderProfitFilter = () => {
    const profitOptions = SHARE_PROFIT;
    return (
      <Col xl={4} lg={4} md={12} xs={12}>
        <IpsTopSelect
          option={profit}
          setOption={setProfit}
          options={profitOptions}
        />
      </Col>
    );
  };

  const renderButton = () => {
    return (
      <Col xl={8} lg={8} md={24} xs={24} align="right">
        <Button type="primary" onClick={handleClick}>
          New IPS
        </Button>
      </Col>
    );
  };

  // -------------------------- MAIN --------------------------
  return (
    <Row gutter={[16, 12]}>
      {renderSearchByName()}
      {renderContractFilter()}
      {renderDurationFilter()}
      {renderProfitFilter()}
      {renderButton()}
    </Row>
  );
};

export default IpsTop;
