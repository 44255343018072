import moment from "moment";

export const MODEL_PARTNER = {
  model: {
    partnerId: "",
    firstName: "",
    lastName: "",
    clientId: "",
    ips: 0,
    commissionFee: "",
    oneOff: "",
    email: "",
    phone: "",
    gender: "",
    active: true,
    walletNetwork: "BEP20",
    walletAddress: "",
  },
};
