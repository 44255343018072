import React, { useEffect, useState } from "react";
import { Col, Row, Form, Typography, Button, Divider, message } from "antd";
import { useDispatch } from "react-redux";
import { MODEL_OPERATOR } from "../../../model/operator";
import { USER_GROUPS } from "../../../store/string";
import OperatorAvatar from "../component/operator_detail_avatar";
import OperatorActive from "../component/operator_detail_active";
import OperatorLastName from "../component/operator_detail_last_name";
import OperatorFirstName from "../component/operator_detail_first_name";
import OperatorPassword from "../component/operator_detail_password";
import OperatorGroup from "../component/operator_detail_group";
import OperatorPhone from "../component/operator_detail_phone";
import OperatorEmail from "../component/operator_detail_email";
import OperatorPermission from "../component/operator_detail_permission";
import {
  addOperator,
  selectOperator,
} from "../../../store/operator/operator.action";
const { Title } = Typography;

const UseDetailAdd = () => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(MODEL_OPERATOR.model);
  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    console.log({ data });
  }, [data]);
  // -------------------------- FUNCTION --------------------------
  const handleChange = ({ id, value }) => {
    setData({
      ...data,
      [id]: value,
    });
  };

  const handleGroup = (value) => {
    const group = USER_GROUPS?.find((item) => item.value === value);
    const permissions = group?.permissions;
    setData({
      ...data,
      permissions,
    });
  };

  const togglePermission = ({ id, value }) => {
    let permissions = data?.permissions || [];
    if (value) {
      permissions = [...permissions, id];
    } else {
      permissions = permissions?.filter((item) => item !== id);
    }
    setData({
      ...data,
      permissions,
    });
  };

  const onFinish = (values) => {
    console.log({ values });
    const operator = {
      ...data,
      ...values,
    };
    setLoading(true);
    dispatch(
      addOperator({ operator }, (response) => {
        setLoading(false);
        if (response.status === 200) {
          const detail = response.data;
          dispatch(selectOperator({ detail }));
          message.success("Successfully added operator");
        } else {
          const error = response.data;
          message.error(error);
        }
      })
    );
  };

  const onFinishFailed = (error) => {
    console.log({ error });
  };
  // -------------------------- RENDER --------------------------

  const renderTitle = () => {
    return (
      <Row gutter={[16, 12]}>
        <Col flex="auto">
          <Title level={4}>Operator Detail</Title>
        </Col>
        <Col flex="none">
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            style={{ width: "100%", marginBottom: 10 }}
            loading={loading}
          >
            Save
          </Button>
        </Col>
      </Row>
    );
  };

  const renderDetail = () => {
    return (
      <Row gutter={[16, 12]}>
        <Col xl={4} lg={4} md={24} xs={24}>
          {renderLeftDetail()}
        </Col>
        <Col xl={20} lg={20} md={24} xs={24}>
          {renderRightDetail()}
        </Col>
      </Row>
    );
  };

  const renderLeftDetail = () => {
    const disabled = loading;
    return (
      <Row gutter={[16, 0]}>
        <Col xl={24} lg={24} md={24} xs={24}>
          <OperatorAvatar
            disabled={disabled}
            data={data}
            onChange={handleChange}
          />
        </Col>
        <Col xl={24} lg={24} md={24} xs={24}>
          <OperatorActive
            disabled={disabled}
            data={data}
            onChange={handleChange}
          />
        </Col>
      </Row>
    );
  };

  const renderRightDetail = () => {
    const disabled = loading;
    return (
      <Row gutter={[16, 0]}>
        <Col xl={12} lg={12} md={12} xs={24}>
          <OperatorFirstName disabled={disabled} />
        </Col>
        <Col xl={12} lg={12} md={12} xs={24}>
          <OperatorLastName disabled={disabled} />
        </Col>
        <Col xl={12} lg={12} md={12} xs={24}>
          <OperatorEmail disabled={disabled} />
        </Col>
        <Col xl={12} lg={12} md={12} xs={24}>
          <OperatorPassword disabled={disabled} />
        </Col>
        <Col xl={12} lg={12} md={12} xs={24}>
          <OperatorGroup disabled={disabled} onChange={handleGroup} />
        </Col>
        <Col xl={12} lg={12} md={12} xs={24}>
          <OperatorPhone disabled={disabled} />
        </Col>
      </Row>
    );
  };

  const renderPermission = () => {
    return (
      <Row gutter={[16, 12]}>
        <Col xl={24} lg={24} md={24} xs={24}>
          <OperatorPermission
            permissions={data?.permissions}
            onChange={togglePermission}
          />
        </Col>
      </Row>
    );
  };

  // -------------------------- MAIN --------------------------
  return (
    <Row gutter={[16, 5]}>
      <Col xl={24} lg={24} md={24} xs={24}>
        <Form
          name="detail_add"
          autoComplete="off"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          {renderTitle()}
          {renderDetail()}
        </Form>
      </Col>
      <Col xl={24} lg={24} md={24} xs={24}>
        <Divider plain>Permissions</Divider>
        {renderPermission()}
      </Col>
    </Row>
  );
};

export default UseDetailAdd;
