import React from "react";
import { Select, Form } from "antd";
import { USER_GROUPS } from "../../../store/string";

const { Option } = Select;

const OperatorGroup = (props) => {
  // -------------------------- STATE --------------------------
  const { disabled } = props;
  // -------------------------- FUNCTION --------------------------

  const onChange = (value) => {
    props.onChange(value);
  };

  // -------------------------- DATA FUNCTION --------------------------

  // -------------------------- FIREBASE FUNCTION --------------------------

  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------
  // let options = [{ value: "All", name: "All Roles" }, ...USER_GROUPS];
  return (
    <Form.Item
      name="group"
      rules={[
        {
          required: true,
          message: "Please select group",
        },
        {
          whitespace: true,
          message: "Invalid",
        },
      ]}
    >
      <Select
        name="group"
        placeholder="All Roles"
        style={{ width: "100%" }}
        dropdownMatchSelectWidth={200}
        onChange={onChange}
        disabled={disabled}
      >
        {USER_GROUPS?.map((item) => (
          <Option key={item.value} value={item.value}>
            {item.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default OperatorGroup;
