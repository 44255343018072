import React from "react";
import { Breadcrumb } from "antd";

const LayoutBreadCrum = (props) => {
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- EFFECT --------------------------
  // -------------------------- FUNCTION --------------------------
  // -------------------------- MAIN --------------------------

  return (
    <Breadcrumb className="breadcrumb">
      {props?.data?.map((item) => {
        const title = item.title;
        const url = item.url;
        return (
          <Breadcrumb.Item key={title}>
            {url ? (
              <a href={url} target="_self" rel="noopener noreferrer">
                {title}
              </a>
            ) : (
              title
            )}
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};

export default LayoutBreadCrum;
