import {
  DeleteOutlined,
  EditFilled,
  EyeFilled,
  EyeOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";
import IpsDetailEdit from "../../dialog/dialog_ips_details_edit";
import IpsDetailDelete from "../../dialog/dialog_ips_details_delete";
const IpsTableAction = (props) => {
  // -------------------------- VAR --------------------------
  const { record } = props;
  // console.log({ record });
  // -------------------------- STATE --------------------------
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  const handleView = () => {
    const url = record?.ips;
    window.open(url, "_blank");
  };
  const handleEdit = () => {
    setOpenEdit(true);
  };
  const handleDelete = () => {
    setOpenDelete(true);
  };
  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  const renderViewButton = () => {
    const disabled = record?.ips ? false : true;
    return (
      <div className="btn-ips-action">
        <Button
          className="btn-ips-view"
          onClick={handleView}
          disabled={disabled}
        >
          <EyeFilled className="icon-ips-action" />
        </Button>
      </div>
    );
  };
  const renderEditButton = () => {
    return (
      <div className="btn-ips-action">
        <Button className="btn-ips-edit" onClick={handleEdit}>
          <EditFilled className="icon-ips-action" />
        </Button>
      </div>
    );
  };
  const renderDeleteButton = () => {
    return (
      <div className="btn-ips-action">
        <Button className="btn-ips-delete" onClick={handleDelete}>
          <DeleteOutlined className="icon-ips-action" />
        </Button>
      </div>
    );
  };
  // -------------------------- MAIN --------------------------
  return (
    <>
      {renderViewButton()}
      {renderEditButton()}
      {renderDeleteButton()}
      <IpsDetailEdit open={openEdit} setOpen={setOpenEdit} record={record} />
      <IpsDetailDelete
        open={openDelete}
        setOpen={setOpenDelete}
        record={record}
      />
    </>
  );
};
export default IpsTableAction;
