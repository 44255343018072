import moment from "moment/moment";

export const MODEL_SHARED_PROFIT = {
  model: {
    ipsId: "",
    clientId: "",
    datetime: moment(),
    amount: 0,
    status: "undeposited",
  },
};
