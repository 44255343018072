import { Input, Modal, Row, message } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteIps, selectIps } from "../../../store/ips/ips.action";
import { ipsSelector, detailSelector } from "../../../store/ips/ips.selector";
import { userSelector } from "../../../store/user/user.selector";

const IpsDetailDelete = (props) => {
  // -------------------------- VAR --------------------------
  const { open, setOpen, record } = props;
  // -------------------------- STATE --------------------------
  const [loading, setLoading] = useState(false);
  const [pass, setPass] = useState("");
  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  const detail = useSelector(detailSelector);
  const account = useSelector(userSelector);
  // -------------------------- FUNCTION --------------------------
  const handleCancel = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    console.log({ record });
    console.log({ detail });
    if (pass === account?.password) {
      setLoading(true);
      dispatch(
        deleteIps({ ips: record }, () => {
          setLoading(false);
          setOpen(false);
          if (record?.id === detail?.id) {
            dispatch(selectIps({ detail: null }));
            message.success("Successfully deleted ips");
          }
        })
      );
    } else {
      message.error("Wrong password!");
    }
  };
  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    dispatch(selectIps({ detail: record }));
  }, [dispatch]);
  // -------------------------- RENDER --------------------------
  const renderInput = () => {
    return (
      <Row span={24} style={{ margin: 20, marginBottom: 0 }}>
        <Input.Password
          placeholder="Please enter admin password ..."
          onChange={(e) => setPass(e?.target?.value)}
        />
      </Row>
    );
  };
  // -------------------------- MAIN --------------------------
  const ipsId = record?.ipsId.toUpperCase();
  const name = <b style={{ color: "red" }}>{ipsId}</b>;
  return (
    <Modal
      title="Confirm"
      className="confirm-modal"
      open={open}
      onCancel={handleCancel}
      onOk={handleDelete}
      cancelButtonProps={{ className: "modal-footer-cancel-button" }}
      okButtonProps={{ className: "modal-footer-delete-button" }}
      okText="Delete"
    >
      <div>Are you sure to delete {name}</div>
      {renderInput()}
    </Modal>
  );
};
export default IpsDetailDelete;
