import React from "react";
import { Route } from "react-router-dom";
import { URL } from "../url";
import { PERMISSION } from "../../../store/string";
import LayoutRoot from "../layout/layout_root";
import PrivateRoute from "../component/private_route";
import HomeScreen from "../../02_Home_Screen/home_screen";
import OperatorManagement from "../../03_Operator/operator_management";
import ClientManagement from "../../04_Client_Management/client_screen";
import IpsMgmtScreen from "../../05_IPS_Management/ips_screen";
import PartnerMgmt from "../../06_Partner_Management/partner_screen";

export const routerPrivate = () => {
  // -------------------------- STATE --------------------------
  // -------------------------- FUNCTION --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Route exact path={URL.home} element={<LayoutRoot />}>
      <Route
        path={URL.home}
        element={
          <PrivateRoute
            element={<HomeScreen />}
            require={PERMISSION.OPERATOR}
          />
        }
      />
      <Route
        path={URL.operators}
        element={
          <PrivateRoute
            element={<OperatorManagement />}
            require={PERMISSION.OPERATOR}
          />
        }
      />
      <Route
        path={URL.clientManagement}
        element={
          <PrivateRoute
            element={<ClientManagement />}
            require={PERMISSION.OPERATOR}
          />
        }
      />
      <Route
        path={URL.partnerManagement}
        element={
          <PrivateRoute
            element={<PartnerMgmt />}
            require={PERMISSION.OPERATOR}
          />
        }
      />
      <Route
        path={URL.ipsManagement}
        element={
          <PrivateRoute
            element={<IpsMgmtScreen />}
            require={PERMISSION.OPERATOR}
          />
        }
      />
      {/* <Route
        path={URL.settings}
        element={
          <PrivateRoute
            element={<SettingScreen />}
            require={PERMISSION.OPERATOR}
          />
        }
      /> */}
    </Route>
  );
};
