import { Card, Col, Row, Table, Button, message } from "antd";
import React, { useEffect } from "react";
import moment from "moment";
import { Timestamp } from "firebase/firestore";
import { CONTRACT, DURATION, SHARE_PROFIT } from "../../../store/data/ips_mgmt";
import { convertNumber } from "../../../util/number.function";
import IpsTableAction from "./component/ips_table_action";
import { useSelector } from "react-redux";
import { partnerListSelector } from "../../../store/partner/partner.selector";
import {
  snapPartnerList,
  unSnapPartnerList,
} from "../../../store/partner/partner.action";
import { useDispatch } from "react-redux";
import {
  addProfitHistory,
  selectProfitHistory,
} from "../../../store/shared_profit_history/history.action";
import {
  addCommissionHistory,
  selectCommissionHistory,
} from "../../../store/commission_fee_history/history.action";
// import { ipsListSelector } from "../../../store/ips/ips.selector";
const IpsTable = (props) => {
  // -------------------------- VAR --------------------------
  const { data } = props;
  console.log({ data });
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // const ipsList = useSelector(ipsListSelector);
  const dispatch = useDispatch();
  const partnerList = useSelector(partnerListSelector);
  // console.log({ partnerList });
  // -------------------------- FUNCTION --------------------------
  const handleDateTime = (date) => {
    const jsDate = date.toDate();

    return Timestamp.fromDate(jsDate);
  };

  const getNextSaturday = () => {
    const now = moment();
    let daysUntilSaturday = 6 - now.day();
    if (daysUntilSaturday <= 0) {
      daysUntilSaturday = 7;
    }
    const nextSaturday = now
      .clone()
      .add(daysUntilSaturday, "days")
      .startOf("day");

    return nextSaturday;
  };

  const addHistory = () => {
    data?.forEach((ipsDoc) => {
      const fund = ipsDoc.fund;
      if (fund > 0) {
        const interestRate = ipsDoc.interest;
        const amount = (fund * interestRate) / 100;
        let interestPerWeek = 0;
        switch (ipsDoc?.interestRatePer) {
          case "week":
            interestPerWeek = amount;
            break;
          case "month":
            interestPerWeek = amount / 4;
            break;
          default:
            interestPerWeek = amount / 52;
            break;
        }
        const historyData = {
          datetime: handleDateTime(getNextSaturday()),
          ipsId: ipsDoc.id,
          backupId: ipsDoc.ipsId,
          clientId: ipsDoc.clientId,
          amount: interestPerWeek,
          status: "undeposited",
        };

        dispatch(
          addProfitHistory({ profitHistory: historyData }, (response) => {
            if (response.status === 200) {
              const detail = response.data;
              dispatch(selectProfitHistory({ detail }));
              // message.success("Successfully added transaction");
            } else {
              const error = response.data;
              console.error(error);
            }
          })
        );
      }
    });

    const hasPartnerData = data?.filter((ips) => ips?.partner?.partnerId);
    hasPartnerData?.forEach((ipsDoc) => {
      const commisionFee = partnerList?.find(
        (partner) => partner.partnerId === ipsDoc?.partner?.partnerId
      )?.commissionFee;
      const fund = ipsDoc.fund;
      const interestRate = ipsDoc.interest;
      const historyData = {
        datetime: handleDateTime(getNextSaturday()),
        ipsId: ipsDoc.id,
        backupIpsId: ipsDoc.ipsId,
        partnerId: ipsDoc.partner.partnerId,
        amount: (((fund * interestRate) / 100) * commisionFee) / 100,
        status: "undeposited",
        type: "Commission",
      };

      dispatch(
        addCommissionHistory({ commissionHistory: historyData }, (response) => {
          if (response.status === 200) {
            const detail = response.data;
            dispatch(selectCommissionHistory({ detail }));
            // message.success("Successfully added transaction");
          } else {
            const error = response.data;
            console.error(error);
          }
        })
      );
    });
  };

  const getColumns = () => {
    const columns = [
      {
        key: "ipsId",
        dataIndex: "ipsId",
        title: "ID",
        align: "center",
        width: "5%",
        sorter: (a, b) => a.ipsId.localeCompare(b.ipsId),
        render: (value) => alignLeft(value?.toUpperCase()),
      },
      {
        key: "client",
        dataIndex: "clientName",
        title: "Client",
        align: "center",
        width: "11%",
      },
      {
        key: "fund",
        dataIndex: "fund",
        title: "Fund",
        align: "center",
        width: "4%",
        sorter: (a, b) => b - a,
        render: (value) => alignRight(convertNumber(value)),
      },
      {
        key: "fundCurrency",
        dataIndex: "fundCurrency",
        title: "Currency",
        align: "center",
        width: "2%",
        render: (value) => value?.toUpperCase(),
        // render: (value, record) => {
        //   let currency = "";
        //   value === "bank"
        //     ? (currency = record?.bank?.currency)
        //     : (currency = "USDT");
        //   return alignLeft(currency.toUpperCase());
        // },
      },
      {
        key: "partner",
        dataIndex: "partner",
        title: "Partner",
        align: "center",
        width: "11%",
        render: (value) => (
          <div>
            {value && value.partnerId && partnerList ? (
              <>
                <div>{value?.partnerName}</div>
                <div>
                  {
                    partnerList?.find(
                      (partner) => partner.partnerId === value?.partnerId
                    )?.commissionFee
                  }
                  %
                </div>
              </>
            ) : null}
          </div>
        ),
      },
      {
        key: "type",
        dataIndex: "type",
        title: "Type",
        align: "center",
        width: "14%",
        render: (value) => alignLeft(value),
      },
      {
        key: "interest",
        dataIndex: "interest",
        title: "Interest",
        align: "center",
        width: "4%",
        render: (value) => alignLeft(value),
      },
      {
        key: "startAt",
        dataIndex: "startAt",
        title: "Start Date",
        align: "center",
        width: "11%",
        sorter: (a, b) => b.startAt - a.startAt,
        render: (value) =>
          alignLeft(moment(value?.toDate()).format("MMM DD, YYYY")),
      },
      {
        key: "endDate",
        dataIndex: "endDate",
        title: "End Date",
        align: "center",
        width: "11%",
        sorter: (a, b) => b.endDate - a.endDate,
        render: (value) =>
          alignLeft(moment(value?.toDate()).format("MMM DD, YYYY")),
      },
      {
        key: "duration",
        dataIndex: "duration",
        title: "Term Deposit",
        align: "center",
        width: "7%",
        render: (value) => alignLeft(value),
      },
      {
        key: "profit",
        dataIndex: "profit",
        title: "Share Profit",
        align: "center",
        width: "6%",
        render: (value) => alignLeft(value),
      },
      {
        key: "ips",
        dataIndex: "ips",
        title: "Action",
        align: "center",
        width: "14%",
        render: (value, record) => renderAction(record),
      },
    ];
    return columns;
  };

  const getData = (data) => {
    const allContract = CONTRACT;
    const allDuration = DURATION;
    const allProfit = SHARE_PROFIT;

    const sortData = data
      ?.slice()
      .sort((a, b) => a.ipsId.localeCompare(b.ipsId));

    const finalData = sortData?.map((item) => {
      //type
      const type = allContract?.find((c) => c?.value === item?.type)?.label;
      //end-date
      const endDate = moment(item?.startAt?.toDate()).add(
        item?.duration,
        "months"
      );
      //duration
      const duration = allDuration?.find(
        (d) => d?.value === item?.duration
      )?.label;
      //profit
      const profit = allProfit?.find(
        (p) => p?.value === item?.shareProfit
      )?.label;

      const row = {
        id: item?.id,
        ipsId: item?.ipsId,
        client: item?.clientId,
        clientName: item?.clientName,
        fund: item?.fund,
        fundCurrency: item?.fundCurrency,
        partner: item?.partner,
        type,
        startAt: item?.startAt,
        endDate,
        duration,
        profit,
        interest: item?.interest + "%",
        interestRatePer: item?.interestRatePer,
        ips: item?.pdfFile,
        method: item?.method,
        wallet: item?.wallet,
        bank: item?.bank,
        onusAccountType: item?.onusAccountType,
        onus: item?.onus,
        notes: item?.notes,
      };
      return row;
    });
    return finalData;
  };
  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    dispatch(snapPartnerList());
    return () => dispatch(unSnapPartnerList());
  }, [dispatch]);
  // -------------------------- RENDER --------------------------
  const renderTotal = () => {
    const total = data?.length || 0;
    return (
      <>
        {/* {data && (
          <Button type="primary" span={12} align="left" onClick={addHistory}>
            Add History
          </Button>
        )} */}
        <Col span={24} align="right">
          Total IPS: {total}
        </Col>
      </>
    );
  };
  const renderTable = () => {
    const columns = getColumns();
    const dataSource = getData(data);
    return (
      <Col span={24}>
        <Card size="small">
          <Table
            dataSource={dataSource}
            rowKey={(record) => record?.id}
            scroll={{ x: 768 }}
            columns={columns?.map((col) => ({
              ...col,
              onCell: () => ({
                style: {
                  fontSize: 12,
                },
              }),
            }))}
          />
        </Card>
      </Col>
    );
  };

  const renderAction = (record) => {
    return (
      <Row className="btn-ips-action">
        <IpsTableAction record={record} />
      </Row>
    );
  };
  // -------------------------- MAIN --------------------------
  return (
    <Row gutter={[16, 12]}>
      {renderTotal()}
      {renderTable()}
    </Row>
  );
};
export default IpsTable;

//function-alignment
const alignLeft = (value) => {
  return (
    <div
      style={{
        textAlign: "left",
        paddingLeft: 20,
      }}
    >
      {value}
    </div>
  );
};

const alignRight = (value) => {
  return (
    <div
      style={{
        textAlign: "right",
        paddingLeft: 20,
      }}
    >
      {value}
    </div>
  );
};
