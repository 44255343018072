export const TYPES = {
  LOADING: "ips/LOADING",
  IPS_LIST_SUCCESS: "ips/IPS_LIST_SUCCESS",
  IPS_LIST_SUCCESS_BY_CLIENT: "ips/IPS_LIST_SUCCESS_BY_CLIENT",
  GET_PERMISSIONS_SUCCESS: "ips/GET_PERMISSIONS_SUCCESS",
  GET_IPS_SUCCESS: "ips/GET_IPS_SUCCESS",
  SELECT_IPS_SUCCESS: "ips/SELECT_IPS_SUCCESS",
  SELECT_IPS_ADD: "ips/SELECT_IPS_ADD",
  IPS_ADD_SUCCESS: "ips/IPS_ADD_SUCCESS",
};
