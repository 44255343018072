import { Form, Input } from "antd";
import React from "react";

const IpsDetailNotes = (props) => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="notes"
      name="notes"
      label="Notes"
      rules={[
        // {
        //   required: true,
        //   message: "required field",
        // },
        {
          whitespace: true,
          message: "Invalid",
        },
        {
          max: 300,
          message: "Max length must be 300 characters",
        },
      ]}
    >
      <Input.TextArea size="large" />
    </Form.Item>
  );
};
export default IpsDetailNotes;
