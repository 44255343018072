import { Col, DatePicker, Form, Input, Row } from "antd";
import moment from "moment";
import React, { useState } from "react";
import DatePickerMoment from "../../../../component/datepicker_moment";
import { CalendarOutlined, FlagOutlined } from "@ant-design/icons";

const PartnerStartAt = (props) => {
  // -------------------------- VAR --------------------------
  const { disabled } = props;
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------

  return (
    <Row gutter={[2, 0]}>
      <Col xl={3} lg={3} md={3} xs={3}>
        <Input
          prefix={<FlagOutlined className="form-item-icon" />}
          disabled={true}
        />
      </Col>
      <Col xl={21} lg={21} md={21} xs={21}>
        <Form.Item
          id="startAt"
          name="startAt"
          rules={[
            {
              required: true,
              message: "required field",
            },
          ]}
        >
          <DatePickerMoment
            style={{ width: "100%" }}
            format={"MMM DD, YYYY"}
            placeholder="Start Date"
            disabled={disabled}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};
export default PartnerStartAt;
