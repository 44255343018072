import { Input, Modal, Row, message } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { partnerSelector } from "../../../store/partner/partner.selector";
import { detailSelector } from "../../../store/partner/partner.selector";
import {
  deletePartner,
  selectPartner,
} from "../../../store/partner/partner.action";
import { userSelector } from "../../../store/user/user.selector";

const DeleteConfirm = (props) => {
  // -------------------------- VAR --------------------------
  const { open, setOpen, data } = props;
  // -------------------------- STATE --------------------------
  const [loading, setLoading] = useState(false);
  const [pass, setPass] = useState("");
  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  const detail = useSelector(detailSelector);
  const account = useSelector(userSelector);
  // -------------------------- FUNCTION --------------------------
  const handleCancel = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    if (pass === account?.password) {
      setLoading(true);
      dispatch(
        deletePartner({ partner: data }, () => {
          setLoading(false);
          setOpen(false);
          if (data?.id === detail?.id) {
            dispatch(selectPartner({ detail: null }));
            message.success("Successfully deleted partner");
          }
        })
      );
    } else {
      message.error("Wrong password!");
    }
  };
  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  const renderInput = () => {
    return (
      <Row span={24} style={{ margin: 20, marginBottom: 0 }}>
        <Input.Password
          placeholder="Please enter admin password ..."
          onChange={(e) => setPass(e?.target?.value)}
        />
      </Row>
    );
  };
  // -------------------------- MAIN --------------------------
  const fullName = `${data?.firstName} ${data?.lastName}`;
  const name = <b style={{ color: "red" }}>{fullName}</b>;
  return (
    <Modal
      title="Confirm"
      className="confirm-modal"
      open={open}
      onCancel={handleCancel}
      onOk={handleDelete}
      cancelButtonProps={{ className: "modal-footer-cancel-button" }}
      okButtonProps={{ className: "modal-footer-delete-button" }}
      okText="Delete"
    >
      <div>You are about to delete {name}</div>
      {renderInput()}
    </Modal>
  );
};
export default DeleteConfirm;
