import { Form, InputNumber } from "antd";
import React from "react";

const ClientRequestAmount = (props) => {
  // -------------------------- VAR --------------------------
  const { method, ipsData } = props;
  const currency = method === "bank" ? ipsData?.bank?.currency : "USDT";
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="amount"
      name="amount"
      label="Amount"
      rules={[
        {
          required: true,
          message: "required field",
        },
        {
          pattern: /^[0-9]+$/,
          message: "Number only",
        },
      ]}
    >
      <InputNumber
        style={{ width: "100%" }}
        addonAfter={currency.toUpperCase()}
      />
    </Form.Item>
  );
};
export default ClientRequestAmount;
