import React from "react";
import { Form, Input } from "antd";
import { LockOutlined } from "@ant-design/icons";

const OperatorPassword = (props) => {
  // -------------------------- VAR --------------------------
  const { disabled } = props;
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- EFFECT --------------------------
  // -------------------------- FUNCTION --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      name="password"
      rules={[
        {
          required: true,
          message: "Please input password",
        },
        {
          whitespace: true,
          message: "Invalid",
        },
        {
          min: 6,
          message: "Min length must be at least 6 characters",
        },
        {
          max: 16,
          message: "Max length must be 16 characters",
        },
      ]}
    >
      <Input.Password
        showCount
        maxLength={16}
        minLength={6}
        prefix={<LockOutlined className="form-item-icon" />}
        placeholder="Password"
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default OperatorPassword;
