export const TYPES = {
  LOADING: "partner/LOADING",
  PARTNER_LIST_SUCCESS: "partner/PARTNER_LIST_SUCCESS",
  PARTNER_LIST_SUCCESS_BY_ID: "partner/PARTNER_LIST_SUCCESS_BY_ID",
  GET_PERMISSIONS_SUCCESS: "partner/GET_PERMISSIONS_SUCCESS",
  GET_PARTNER_SUCCESS: "partner/GET_PARTNER_SUCCESS",
  SELECT_PARTNER_SUCCESS: "partner/SELECT_PARTNER_SUCCESS",
  SELECT_PARTNER_ADD: "partner/SELECT_PARTNER_ADD",
  PARTNER_ADD_SUCCESS: "partner/PARTNER_ADD_SUCCESS",
};
