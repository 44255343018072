import { Col, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
// import PartnerDetailIpsCard from "./component/partner_detail_ips_card";
// import ClientTopupWithdrawCreate from "../../dialog/dialog_client_topup_withdraw_create";
import PartnerDetailTxHistory from "./component/partner_detail_tx_history";
const { Title } = Typography;

const PartnerDetailTx = (props) => {
  // -------------------------- VAR --------------------------
  const { ipsList } = props;
  console.log("ipsList tx", ipsList);
  const ipsOptions = ipsList?.map((ips) => ({
    label: ips?.ipsId,
    value: ips?.id,
  }));
  // console.log(ipsList.find((ips) => ips?.id));
  // -------------------------- STATE --------------------------
  const [ips, setIps] = useState("");
  const [partner, setPartner] = useState(null);
  // -------------------------- REDUX --------------------------

  // -------------------------- FUNCTION --------------------------
  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    if (ipsList.length > 0) {
      setPartner(ipsList[0]?.partner.partnerId);
      if (ipsOptions.length > 0) {
        setIps(ipsOptions[0]?.value);
      } else {
        setIps("No IPS yet");
      }
    }
  }, [ipsList]);
  // -------------------------- RENDER --------------------------
  // const renderTop = () => {
  //   const options = ipsOptions;
  //   return (
  //     <Col xl={24} lg={24} md={24} xs={24}>
  //       <Row align="middle">
  //         <Col span={2}>IPS:</Col>
  //         <Col span={17}>
  //           {options.length > 0 ? (
  //             <Select options={options} onSelect={setIps} value={ips} />
  //           ) : (
  //             <Text style={{ fontSize: 15, fontStyle: "italic" }}>{ips}</Text>
  //           )}
  //         </Col>
  //         <Col span={5} align="right">
  //           <Button type="primary" onClick={handleClick}>
  //             Add Transaction
  //           </Button>
  //         </Col>
  //       </Row>
  //     </Col>
  //   );
  // };

  // const renderTxCard = () => {
  //   const itemData = data.find((item) => item.id === ips);
  //   return (
  //     <>
  //       {itemData ? (
  //         <Col span={24}>
  //           <PartnerDetailIpsCard data={itemData} />
  //         </Col>
  //       ) : null}
  //     </>
  //   );
  // };

  const renderHistory = () => {
    return (
      <>
        <Col span={24}>
          <Title style={{ fontSize: 16 }}>History</Title>
        </Col>
        <Col xl={24} lg={24} md={24} xs={24}>
          <PartnerDetailTxHistory
            ips={ips}
            partner={partner}
            method={ipsList?.find((ipsDetail) => ipsDetail?.id === ips)?.method}
            ipsList={ipsList}
          />
        </Col>
      </>
    );
  };
  // -------------------------- MAIN --------------------------
  return (
    <Row gutter={[16, 16]}>
      {/* {renderTop()}
      {renderTxCard()} */}
      {renderHistory()}
    </Row>
  );
};
export default PartnerDetailTx;
