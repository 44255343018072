import { getAuth, signOut } from "firebase/auth";
import {
  collection,
  where,
  getFirestore,
  getDocs,
  query,
  onSnapshot,
  setDoc,
  serverTimestamp,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { app } from "../../config/config";
import { TYPES } from "./history.type";
import { REF } from "../ref";
import { FULL_PERMISSION } from "../../store/string";
import { MASTER_EMAIL } from "../../config/config";

const firestore = getFirestore(app);
const auth = getAuth(app);

// -------------------------- Snap List  --------------------------
const subWithdrawRequest = [];
export const snapWithdrawRequest = () => (dispatch) => {
  const uid = auth?.currentUser?.uid;
  const email = auth?.currentUser?.email;
  const cRef = collection(firestore, REF.WITHDRAW_REQUEST);
  const dRef = doc(cRef, uid);
  if (email === MASTER_EMAIL) {
    const data = {
      firstName: "Master",
      lastName: "Account",
      permissions: FULL_PERMISSION,
      active: true,
    };
    getWithdrawRequestDataSuccess(dispatch, data);
    // getPermissionsSuccess(dispatch, data);
    return;
  }
  const unSub = onSnapshot(dRef, (snapshot) => {
    if (snapshot) {
      const data = snapshot.data();
      if (data === undefined) {
        signOut(auth);
      } else {
        getWithdrawRequestDataSuccess(dispatch, data);
        // getPermissionsSuccess(dispatch, data);
      }
    }
  });
  subWithdrawRequest.push(unSub);
};

export const unSnapWithdrawRequest = () => (dispatch) => {
  subWithdrawRequest.forEach((subscriber) => {
    subscriber();
  });
  subWithdrawRequest.length = 0;
};

export const resetWithdrawRequest = (callback) => (dispatch) => {
  getWithdrawRequestDataSuccess(dispatch, null);
};
// -------------------------- Operation List  --------------------------
// Lấy dữ liệu topup withdraw
export const firestoreGetWithdrawRequestList = (callback) => (dispatch) => {
  const cRef = collection(firestore, REF.WITHDRAW_REQUEST);
  getDocs(cRef).then((snapshot) => {
    if (snapshot) {
      const data = snapshot.docs.map((doc) => doc.data());
      console.log("withdrawRequest", data);
      getWithdrawRequestListSuccess(dispatch, data);
    }
    if (callback) {
      callback();
    }
  });
};

// Theo dõi dữ liệu withdrawRequest
const subList = [];
export const snapWithdrawRequestList = (callback) => (dispatch) => {
  const cRef = collection(firestore, REF.WITHDRAW_REQUEST);
  const unSub = onSnapshot(cRef, (snapshot) => {
    if (snapshot) {
      const data = snapshot.docs.map((doc) => doc.data());
      getWithdrawRequestListSuccess(dispatch, data);
      if (callback) {
        callback();
      }
    }
  });
  subList.push(unSub);
};

export const unSnapWithdrawRequestList = () => (dispatch) => {
  subList.forEach((subscriber) => {
    subscriber();
  });
  subList.length = 0;
};

const withdrawRequestListByClientAndIps = [];
export const snapWithdrawRequestListByClientAndIps =
  ({ client }, { ips }, callback) =>
  (dispatch) => {
    const cRef = collection(firestore, REF.WITHDRAW_REQUEST);
    const clientId = client?.id;
    const ipsId = ips?.id;
    const c1 = where("clientId", "==", clientId);
    const c2 = where("ipsId", "==", ipsId);
    const qRef = query(cRef, c1, c2);
    const unSub = onSnapshot(qRef, (snapshot) => {
      if (snapshot) {
        const data = snapshot.docs.map((doc) => doc.data());
        getWithdrawRequestListSuccess(dispatch, data);
        if (callback) {
          callback(data);
        }
      }
    });
    withdrawRequestListByClientAndIps.push(unSub);
  };

export const unSnapWithdrawRequestListByClientAndIps = () => (dispatch) => {
  subList.forEach((subscriber) => {
    subscriber();
  });
  withdrawRequestListByClientAndIps.length = 0;
};

// -------------------------- Operation Add  --------------------------

export const addWithdrawRequest =
  ({ withdrawRequest }, callback) =>
  (dispatch) => {
    // console.log({ withdrawRequest });
    const cRef = collection(firestore, REF.WITHDRAW_REQUEST);
    getDocs(cRef)
      .then((snapshot) => {
        if (snapshot) {
          snapshot.docs.map((doc) => doc.data());
          const id = doc(cRef).id;
          const data = {
            ...withdrawRequest,
            id,
            createAt: serverTimestamp(),
          };

          const dRef = doc(cRef, id);
          setDoc(dRef, data, { merge: true }).then(() => {
            if (callback) {
              callback({
                status: 200,
                data,
              });
            }
          });
        }
      })
      .catch((error) => {
        if (callback) {
          callback({
            status: 500,
            data: error.code,
          });
        }
      });
  };

// -------------------------- Operation Update  --------------------------
// Update withdrawRequest
export const updateWithdrawRequest =
  ({ withdrawRequest, update }, callback) =>
  (dispatch) => {
    const cRef = collection(firestore, REF.WITHDRAW_REQUEST);
    const id = withdrawRequest?.id;
    // const email = update.email;
    // const c1 = where("role", "==", "operator");
    const c2 = where("id", "==", withdrawRequest?.id);
    const qRef = query(cRef, c2);
    getDocs(qRef)
      .then((snapshot) => {
        if (snapshot) {
          const withdrawRequestList = snapshot.docs
            .map((doc) => doc.data())
            .filter((item) => item.id !== id);
          if (withdrawRequestList.length === 0) {
            const dRef = doc(cRef, id);
            updateDoc(dRef, update).then(() => {
              selectWithdrawRequestSuccess(dispatch, update);
              if (callback) {
                callback({
                  status: 200,
                  data: update,
                });
              }
            });
          } else {
            if (callback) {
              callback({
                status: 500,
                data: "Duplicate username",
              });
            }
          }
        }
      })
      .catch((error) => {
        if (callback) {
          callback({
            status: 500,
            data: error.code,
          });
        }
      });
  };

// -------------------------- Operation Delete  --------------------------

export const deleteWithdrawRequest =
  ({ withdrawRequest }, callback) =>
  (dispatch) => {
    console.log({ withdrawRequest });
    const cRef = collection(firestore, REF.WITHDRAW_REQUEST);
    const dRef = doc(cRef, withdrawRequest.id);
    deleteDoc(dRef).then(() => {
      if (callback) {
        callback();
      }
    });
  };

// -------------------------- Update info --------------------------

export const firebaseUpdateLoginTime = () => (dispatch) => {
  const cRef = collection(firestore, REF.WITHDRAW_REQUEST);
  const email = auth.currentUser?.email;
  const uid = auth.currentUser?.uid;
  if (email === MASTER_EMAIL) {
    return;
  } else {
    const dRef = doc(cRef, uid);
    const update = {
      onlineAt: serverTimestamp(),
    };
    updateDoc(dRef, update);
  }
};

// -------------------------- Operation Select  --------------------------

export const selectWithdrawRequest =
  ({ detail }, callback) =>
  (dispatch) => {
    console.log("detail action", detail);
    selectWithdrawRequestSuccess(dispatch, detail);
    if (callback) {
      callback();
    }
  };

export const selectAddWithdrawRequest = (callback) => (dispatch) => {
  selectAddSuccess(dispatch);
  if (callback) {
    callback();
  }
};

export const successAddWithdrawRequest = (callback) => (dispatch) => {
  withdrawRequestAddSuccess(dispatch);
  if (callback) {
    callback();
  }
};

export const successDeleteWithdrawRequest = (callback) => (dispatch) => {
  withdrawRequestAddSuccess(dispatch);
  if (callback) {
    callback();
  }
};

// -------------------------- Dispatch  --------------------------

const getWithdrawRequestDataSuccess = (dispatch, withdrawRequest) => {
  dispatch({
    type: TYPES.GET_WITHDRAW_REQUEST_SUCCESS,
    payload: withdrawRequest,
  });
};

const getWithdrawRequestListSuccess = (dispatch, data) => {
  dispatch({
    type: TYPES.WITHDRAW_REQUEST_LIST_SUCCESS,
    payload: data,
  });
};

const selectWithdrawRequestSuccess = (dispatch, data) => {
  dispatch({
    type: TYPES.SELECT_WITHDRAW_REQUEST_SUCCESS,
    payload: data,
  });
};

const withdrawRequestAddSuccess = (dispatch) => {
  dispatch({
    type: TYPES.WITHDRAW_REQUEST_ADD_SUCCESS,
  });
};

const selectAddSuccess = (dispatch) => {
  dispatch({
    type: TYPES.SELECT_WITHDRAW_REQUEST_ADD,
  });
};
