import {
  Col,
  Divider,
  Form,
  Radio,
  Row,
  Tabs,
  Typography,
  Button,
  message,
} from "antd";
import moment from "moment";
import { Timestamp } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import React, { useEffect, useState } from "react";
import PartnerAvatar from "./component/partner_detail_avatar";
import PartnerActive from "./component/partner_detail_active";
import PartnerEmail from "./component/partner_detail_email";
import PartnerPhone from "./component/partner_detail_phone";
import PartnerDetailIps from "./component/partner_detail_ips";
import PartnerDetailTx from "./component/partner_detail_tx";
import PartnerName from "./component/partner_detail_name";
import { ipsListSelector } from "../../../store/ips/ips.selector";
import {
  snapIpsList,
  snapIpsListByClient,
  unSnapIpsList,
  unSnapIpsListByClient,
} from "../../../store/ips/ips.action";
import { useSelector, useDispatch } from "react-redux";
import { MODEL_PARTNER } from "../../../model/partner";
import PartnerId from "./component/partner_detail_id";
import PartnerStartAt from "./component/partner_detail_start_date";
import PartnerEndAt from "./component/partner_detail_end_date";
import PartnerCommissionFee from "./component/partner_detail_commission_fee";
import PartnerCommissionAmount from "./component/partner_detail_commission_amount";
import PartnerCryptoWallet from "./component/partner_detail_crypto_wallet";
import PartnerDetailEdit from "./component/partner_detail_edit";
import { updatePartner } from "../../../store/partner/partner.action";
import PartnerDetailClient from "./component/partner_detail_client";
import PartnerDetailFile from "./component/partner_detail_file";

const { Title } = Typography;
const PartnerDetail = (props) => {
  // -------------------------- VAR --------------------------
  const { details } = props;
  // console.log({ details });
  const storage = getStorage();
  // -------------------------- STATE --------------------------
  const [form] = Form.useForm();
  const [data, setData] = useState(MODEL_PARTNER.model);
  const [tab, setTab] = useState("ips");
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  // -------------------------- REDUX --------------------------
  const ipsList = useSelector(ipsListSelector);
  // console.log({ ipsList });
  const ipsListByPartner = ipsList?.filter(
    (ips) => ips.partner.partnerId === details?.partnerId
  );
  // console.log({ ipsListByPartner });
  const dispatch = useDispatch();
  // -------------------------- FUNCTION --------------------------
  const handleDateTime = (date) => {
    const jsDate = date.toDate();

    // Create a Firestore Timestamp from the JavaScript Date object
    return Timestamp.fromDate(jsDate);
  };

  const handleChange = ({ id, value }) => {
    setData({
      ...data,
      [id]: value,
    });
  };

  const handleSelectTab = (e) => {
    setTab(e.target.value);
  };

  const onFinish = async (values) => {
    let fileURL = details?.pdfFile;
    if (file) {
      const uniqueFileName = `${Date.now()}_${file?.name}`;
      const storageRef = ref(storage, uniqueFileName);
      try {
        await uploadBytes(storageRef, file);
        fileURL = await getDownloadURL(storageRef);
      } catch (error) {
        console.log(error);
      }
    }
    if (fileURL) {
      const update = {
        ...data,
        ...values,
        commissionAmount: values.commissionAmount || "",
        oneOff: values.oneOff || 0,
        pdfFile: fileURL,
        startAt: handleDateTime(values.startAt),
        endAt: handleDateTime(values.endAt),
      };
      console.log({ update });
      setLoading(true);
      dispatch(
        updatePartner({ partner: details, update }, (response) => {
          setLoading(false);
          if (response.status === 200) {
            message.success("Successfully update partner");
            setDisabled(true);
          } else {
            const error = response.data;
            message.error(error);
          }
        })
      );
    }
  };

  const onFinishFailed = (error) => {
    console.log({ error });
  };
  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    // console.log({ details });
    if (!Array.isArray(details) && details) {
      dispatch(snapIpsList());
    }
    return () => dispatch(unSnapIpsList);
  }, [dispatch, details]);

  // useEffect(() => {

  // }, [ipsListByPartner]);

  useEffect(() => {
    const totalFund = ipsListByPartner?.reduce((sum, ipsItem) => {
      return sum + ipsItem.fund;
    }, 0);
    // console.log({ totalFund });
    // console.log({ details });
    setData(details);
    const formData = {
      email: details?.email,
      phone: details?.telephone || details?.phone,
      partnerId: details?.partnerId,
      clientId: details?.clientId,
      startAt: details?.startAt,
      endAt: details?.endAt,
      commissionFee: details?.commissionFee,
      commissionAmount: (totalFund * parseFloat(details?.commissionFee)) / 100,
      walletNetwork: details?.walletNetwork,
      walletAddress: details?.walletAddress,
      pdfFile: details?.pdfFile,
    };
    form.setFieldsValue(formData);
  }, [details, form, ipsListByPartner]);

  // -------------------------- RENDER --------------------------
  const renderBody = () => {
    return (
      <>
        <Col xl={24} lg={24} md={24} xs={24}>
          <Form
            name="detail_edit"
            autoComplete="off"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            {renderTitle()}
            {renderDetail()}
          </Form>
        </Col>
        {/* <Col xl={24} lg={24} md={24} xs={24}>
          <Divider plain></Divider>
        </Col> */}
        <Col xl={24} lg={24} md={24} xs={24}>
          {renderHistory()}
        </Col>
        <Col xl={24} lg={24} md={24} xs={24}>
          {renderSelectedTab()}
        </Col>
      </>
    );
  };

  const renderTitle = () => {
    const registeredTime = moment(details?.createdAt?.toDate()).format(
      "MMM DD, YYYY"
    );
    return (
      <Row gutter={[16, 12]}>
        <Col span={8}>
          <Title level={4}>Partner Detail</Title>
        </Col>
        <Col span={10}>
          <Title level={4} style={{ fontSize: 14, paddingTop: 10 }}>
            Registered on: {registeredTime}
          </Title>
        </Col>
        <Col span={6} style={{ textAlign: "right" }}>
          <PartnerDetailEdit setDisabled={setDisabled} disabled={disabled} />
        </Col>
      </Row>
    );
  };
  const renderDetail = () => {
    return (
      <Row gutter={[16, 12]}>
        <Col xl={4} lg={4} md={24} xs={24}>
          {renderLeftDetail()}
        </Col>
        <Col xl={20} lg={20} md={24} xs={24}>
          {renderRightDetail()}
        </Col>
      </Row>
    );
  };

  const renderLeftDetail = () => {
    return (
      <Row gutter={[4, 0]}>
        <Col xl={24} lg={24} md={24} xs={24}>
          <PartnerAvatar data={data} />
        </Col>
        <Col xl={24} lg={24} md={24} xs={24}>
          <PartnerActive
            disabled={disabled}
            data={data}
            onChange={handleChange}
          />
        </Col>
      </Row>
    );
  };

  const renderRightDetail = () => {
    return (
      <Row gutter={[16, 0]}>
        <Col xl={12} lg={12} md={24} xs={24}>
          <PartnerDetailClient disabled={disabled} />
        </Col>
        <Col xl={12} lg={12} md={24} xs={24}>
          <PartnerId />
        </Col>
        <Col xl={12} lg={12} md={24} xs={24}>
          <PartnerPhone disabled={disabled} />
        </Col>
        <Col xl={12} lg={12} md={24} xs={24}>
          <PartnerDetailFile
            disabled={disabled}
            file={file}
            setFile={setFile}
          />
        </Col>
        <Col xl={12} lg={12} md={24} xs={24}>
          <PartnerStartAt disabled={disabled} />
        </Col>
        <Col xl={12} lg={12} md={24} xs={24}>
          <PartnerEndAt disabled={disabled} />
        </Col>
        <Col xl={12} lg={12} md={24} xs={24}>
          <PartnerCommissionFee disabled={disabled} />
        </Col>
        <Col xl={12} lg={12} md={24} xs={24}>
          <PartnerCommissionAmount />
        </Col>
        <Col xl={24} lg={24} md={24} xs={24}>
          <PartnerCryptoWallet disabled={disabled} />
        </Col>
      </Row>
    );
  };

  const renderHistory = () => {
    return (
      <Radio.Group
        defaultValue="ips"
        buttonStyle="solid"
        style={{ width: "100%", textAlign: "center" }}
        onChange={handleSelectTab}
        size="large"
      >
        <Radio.Button
          value="ips"
          className="radio-group-tabs"
          style={{ width: "50%" }}
        >
          Referral IPS
        </Radio.Button>
        <Radio.Button
          value="tx"
          className="radio-group-tabs"
          style={{ width: "50%" }}
        >
          Transaction History
        </Radio.Button>
      </Radio.Group>
    );
  };

  const renderSelectedTab = () => {
    if (tab === "ips") {
      return ipsList ? (
        <PartnerDetailIps ipsList={ipsListByPartner} partnerDetail={details} />
      ) : (
        <PartnerDetailIps ipsList={[]} partnerDetail={details} />
      );
    } else {
      return ipsList ? (
        <PartnerDetailTx ipsList={ipsListByPartner} />
      ) : (
        <PartnerDetailTx ipsList={[]} />
      );
    }
  };
  // -------------------------- MAIN --------------------------
  return <Row gutter={[16, 12]}>{renderBody()}</Row>;
};
export default PartnerDetail;
