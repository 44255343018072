export const APP_NAME = "Admin Portal"; // App Name
export const ALL_GROUP = "All Groups";
export const ROLE_OPERATOR = "operator";

export const FULL_PERMISSION = ["p0", "p1", "p2"];

export const PERMISSION = {
  OPERATOR: "p0",
  CLIENT: "p1",
  DASHBOARD: "p2",
};

export const SUB_PERMISSION = {
  OPERATOR: {
    // PASSWORD: "p2s0",
    // SERVICE: "p2s1",
  },
  // CLIENT: {
  // IMMIGRATION: "p6s1",
  // },
};

export const PERMISSIONS = [{ id: PERMISSION.DASHBOARD, name: "Dashboard" }];

export const PERMISSIONS_SUB = [
  // {
  //   id: SUB_PERMISSION.OPERATOR.PASSWORD,
  //   name: "View Account Password",
  //   parent: "p2",
  // },
  // {
  //   id: SUB_PERMISSION.OPERATOR.SERVICE,
  //   name: "Edit Service",
  //   parent: "p2",
  // },
];

export const LANGUAGE = [
  { label: "English", value: "en" },
  { label: "Tiếng Việt", value: "vi" },
];

export const USER_GROUPS = [
  {
    name: "Operator",
    value: "operator",
    permissions: [PERMISSION.OPERATOR, PERMISSION.DASHBOARD],
  },
  // {
  //   name: "Client",
  //   value: "client",
  //   permissions: [PERMISSION.CLIENT, PERMISSION.DASHBOARD],
  // },
  // {
  //   name: "Trader",
  //   value: "trader",
  //   permissions: [PERMISSION.TRADER_MONITOR],
  // },
];

// ---------- TRADER MANAGEMENT -----------//

export const OPTIONS_SORT_BY = [
  {
    value: "total",
    label: "Total orders",
  },
  {
    value: "skipped",
    label: "Skipped orders",
  },
  {
    value: "missed",
    label: "Missed orders",
  },
];

export const COLOR_OPTIONS = [
  {
    value: "coppied",
    color: "#5B8FF9",
  },
  {
    value: "skipped",
    color: "#5AD8A6",
  },
  {
    value: "missed",
    color: "#5D7092",
  },
];

// "#5B8FF9", "#5AD8A6", "#5D7092", "#F6BD16", "#6F5EF9"

export const STATUS_TRADER = [
  {
    label: "Copied",
    value: "copied",
  },
  {
    label: "Skipped",
    value: "skipped",
  },
  {
    label: "Missed",
    value: "missed",
  },
];

export const FORMAT_TIMESTAME = "MMM DD hh:mm a";
