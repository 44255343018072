import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import lodash from "lodash";
import { newItems, newMenus, newAuthMenu, newDashboard } from "../screen/00_Nav/url";
import { isLoggedSelector } from "../store/auth/auth.selector";
import { userSelector } from "../store/user/user.selector";

export const useMenu = () => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------
  const [items, setItems] = useState({});
  const [menu, setMenu] = useState([]);
  const [authMenu, setAuthMenu] = useState([]);
  const [dashboard, setDashboard] = useState([]);
  // -------------------------- REDUX --------------------------
  const logged = useSelector(isLoggedSelector);
  const user = useSelector(userSelector);
  // -------------------------- FUNCTION --------------------------
  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    const permissions = user?.permissions;
    const active = user?.active;
    if (active) {
      const items = newItems();
      const byPermissions = filterPermission(items, permissions);
      const menu = newMenus(byPermissions);
      const authMenu = newAuthMenu(byPermissions, logged);
      const dashboard = newDashboard(byPermissions);
      setItems(byPermissions);
      setMenu(menu);
      setAuthMenu(authMenu);
      setDashboard(dashboard);
    } else {
      setMenu([]);
      setDashboard([]);
    }
  }, [logged, user]);
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return { items, menu, authMenu, dashboard };
};

const filterPermission = (items, permissions) => {
  const list = Object.entries(items)
    .map((item) => {
      const key = item[0];
      const value = item[1];
      const require = value?.require;
      const diff = require?.length > 0 ? lodash.difference(require, permissions) : [];
      const valid = diff?.length === 0 ? true : false;
      return { key, value, valid };
    })
    .filter((item) => item?.valid)
    .reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});
  return list;
};
