import React from "react";
import { Form, Radio } from "antd";
const ClientTopupWithdrawTxType = (props) => {
  // -------------------------- VAR --------------------------
  const TYPE = [
    { label: "Topup", value: "topup" },
    { label: "Withdraw", value: "withdraw" },
  ];
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  const typeOptions = TYPE;
  // -------------------------- FUNCTION --------------------------
  // -------------------------- EFFECT --------------------------

  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="txType"
      name="txType"
      label="Transaction Type"
      initialValue="topup"
      rules={[
        {
          required: true,
          message: "required field",
        },
      ]}
    >
      <Radio.Group options={typeOptions} />
    </Form.Item>
  );
};
export default ClientTopupWithdrawTxType;
