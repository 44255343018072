import React, { useState } from "react";
import { Avatar, Divider, List, Button, Col, Row } from "antd";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import placeholder from "../../../../asset/img/avatar.png";
import { DeleteOutlined } from "@ant-design/icons";
import DeleteConfirm from "../../dialog/dialog_delete_confirm";
import { convertNumber } from "../../../../util/number.function";

const PartnerListListing = (props) => {
  // -------------------------- VAR --------------------------
  const { data, setDetails } = props;
  // console.log({ data });
  // -------------------------- STATE --------------------------
  const [client, setClient] = useState(null);
  const [dialogDelete, setDialogDelete] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  // -------------------------- REDUX --------------------------

  // -------------------------- FUNCTION --------------------------
  const isItemSelected = (item) => item.id === selectedItem;

  const handleClick = (item) => {
    setDetails(item);
    setSelectedItem(item.id);
  };
  // -------------------------- EFFECT --------------------------

  // -------------------------- RENDER --------------------------
  const renderItem = (item) => {
    console.log({ item });
    const avatar = item?.avatar || placeholder;
    const fullName = `${item?.firstName} ${item?.lastName}`;
    const ips = item?.totalIps;
    const createAt = item?.createdAt;
    const funds = convertNumber(item?.totalFund);
    const registeredTime = moment(createAt?.toDate()).format("MMM DD, YYYY");
    const description = (
      <Row span={24} style={{ color: "black" }}>
        <Col span={10} style={{ paddingLeft: 8 }}>
          <Row span={24} className="client-list" style={{ color: "blue" }}>
            {fullName}
          </Row>
          <Row span={24} className="client-list">
            IPS: {ips}
          </Row>
        </Col>
        <Col span={14} style={{ paddingLeft: 10, color: "black" }}>
          <Row span={24} className="client-list">
            Registered on: {registeredTime}
          </Row>
          <Row span={24} className="client-list">
            Total funds: {funds} USD
          </Row>
        </Col>
      </Row>
    );
    return (
      <List.Item
        key={item.id}
        actions={[renderAction(item)]}
        onClick={() => handleClick(item)}
        style={{
          cursor: "pointer",
          boxShadow: isItemSelected(item)
            ? "0px 0px 5px 5px rgba(0, 0, 0, 0.5)"
            : "none",
        }}
      >
        <List.Item.Meta
          // avatar={renderAvatar(avatar)}
          //   title={title}
          description={description}
        />
      </List.Item>
    );
  };

  const renderAvatar = (avatar) => {
    return <Avatar src={avatar} />;
  };

  const renderAction = (item) => {
    return (
      <Button
        danger
        shape="circle"
        type="text"
        size="large"
        onClick={() => {
          setDialogDelete(true);
          setClient(item);
        }}
      >
        <DeleteOutlined />
      </Button>
    );
  };

  // -------------------------- MAIN --------------------------
  return (
    <div id="scrollableDiv" style={{ overflow: "auto", padding: "0 8px" }}>
      <InfiniteScroll
        dataLength={data?.length}
        hasMore={data?.length < 50}
        endMessage={<Divider plain>All data loaded</Divider>}
        scrollableTarget="scrollableDiv"
      >
        <List dataSource={data} renderItem={(item) => renderItem(item)} />
      </InfiniteScroll>
      <DeleteConfirm
        open={dialogDelete}
        setOpen={setDialogDelete}
        data={client}
      />
    </div>
  );
};
export default PartnerListListing;
