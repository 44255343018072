import React from "react";
import { Form, Select } from "antd";
const ClientTopupWithdrawWalletNetwork = (props) => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  const NETWORK = [
    { label: "BEP20", value: "bep20" },
    { label: "TRC20", value: "trc20" },
  ];
  const networkOptions = NETWORK;
  // -------------------------- FUNCTION --------------------------

  // -------------------------- EFFECT --------------------------

  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="walletNetwork"
      name="walletNetwork"
      label="Network"
      initialValue="bep20"
      rules={[
        {
          required: true,
          message: "required field",
        },
      ]}
    >
      <Select options={networkOptions} />
    </Form.Item>
  );
};
export default ClientTopupWithdrawWalletNetwork;
