import { Card, Table, Button } from "antd";
import moment from "moment";
import numeral from "numeral";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { withdrawRequestListSelector } from "../../../../../store/withdraw_request_history/history.selector";
import {
  snapWithdrawRequestList,
  unSnapWithdrawRequestList,
} from "../../../../../store/withdraw_request_history/history.action";
import { useEffect } from "react";
import ClientDetailRequestHistoryUpdate from "./component/client_detail_request_history_update";

const ClientDetailRequestHistory = (props) => {
  // -------------------------- VAR --------------------------
  const { ips, client, ipsData } = props;
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  const withdrawRequestHistory = useSelector(withdrawRequestListSelector);
  const data = withdrawRequestHistory?.filter(
    (history) => history?.ipsId === ips && history?.clientId === client
  );
  const sortedData = data?.sort((a, b) => b.requestTime - a.requestTime);
  // -------------------------- FUNCTION --------------------------
  const renderNumber = (value) => {
    const valuex1000 = (value / 1000).toFixed(1) || 0;
    return valuex1000;
  };

  const getColumns = () => {
    const columns = [
      {
        key: "date",
        dataIndex: "requestTime",
        title: "Date",
        align: "center",
        sorter: (a, b) => b.createAt - a.createAt,
        render: (values) => (
          <div style={{ textAlign: "left", paddingLeft: 10 }}>
            {moment(values.toDate()).format("MMM DD, YYYY")}
          </div>
        ),
        width: "25%",
      },
      {
        key: "amount",
        dataIndex: "amount",
        title: "Amount",
        align: "center",
        width: "25%",
        render: (value) =>
          ipsData?.method === "bank" ? (
            value > 1000 ? (
              <div style={{ textAlign: "right", paddingRight: "30%" }}>
                {numeral(renderNumber(value)).format("0,0")}K{" "}
                {ipsData?.bank?.currency?.toUpperCase()}
              </div>
            ) : (
              <div style={{ textAlign: "right", paddingRight: "30%" }}>
                {numeral(value).format("0,0")}{" "}
                {ipsData?.bank?.currency?.toUpperCase()}
              </div>
            )
          ) : value > 1000 ? (
            <div style={{ textAlign: "right", paddingRight: "30%" }}>
              {numeral(renderNumber(value)).format("0,0")}K USDT
            </div>
          ) : (
            <div style={{ textAlign: "right", paddingRight: "30%" }}>
              {numeral(value).format("0,0")} USDT
            </div>
          ),
      },
      {
        key: "status",
        dataIndex: "status",
        title: "Status",
        align: "center",
        width: "25%",
        render: (value) => (
          <div style={{ textAlign: "left", paddingLeft: "20%" }}>{value}</div>
        ),
      },
      {
        key: "action",
        dataIndex: "status",
        title: "Action",
        align: "center",
        width: "25%",
        render: (status, record) => (
          <div style={{ textAlign: "left", paddingLeft: "20%" }}>
            {status !== "Deposited" && (
              <ClientDetailRequestHistoryUpdate record={record} />
            )}
          </div>
        ),
      },
    ];
    return columns;
  };

  const handleData = (tempData) => {
    return tempData.filter((item) => item.ipsId === ips);
  };
  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    dispatch(snapWithdrawRequestList());
    return () => unSnapWithdrawRequestList();
  }, [dispatch]);
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  const columns = getColumns();
  // const useData = handleData(data);
  return (
    <Card size="small">
      <Table
        columns={columns}
        size="small"
        dataSource={sortedData}
        className="history-table"
        rowKey={(record) => record.id}
        scroll={{ x: 512 }}
      />
    </Card>
  );
};
export default ClientDetailRequestHistory;
