import { TYPES } from "./nav.types";

const initState = {
  expansed: true,
  collapsed: true,
  hightlight: [],
};

const navReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case TYPES.EXPANSE:
      return {
        ...state,
        expansed: !state.expansed,
      };
    case TYPES.COLLAPSE:
      return {
        ...state,
        error: payload,
        collapsed: !state.collapsed,
      };
    case TYPES.HIGHTLIGHT:
      return {
        ...state,
        hightlight: payload,
      };
    default:
      return state;
  }
};

export default navReducer;
