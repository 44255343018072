import React from "react";
import { Col, Row, Typography } from "antd";

const PartnerListTotal = (props) => {
  // --------------------------- VAR --------------------------
  const { Title } = Typography;
  const { data } = props;
  const totalPartners = data?.length;
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------

  // ------------------------- FUNCTION --------------------------
  // -------------------------- EFFECT --------------------------

  // -------------------------- RENDER --------------------------
  // --------------------------- MAIN --------------------------
  return (
    <Row gutter={[16, 12]}>
      <Col xl={12} lg={12} md={24} xs={24}>
        <Title level={5}>Total Partners: {totalPartners}</Title>
      </Col>
    </Row>
  );
};

export default PartnerListTotal;
