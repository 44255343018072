import { Form, Input } from "antd";
import React from "react";

const ClientDetailId = (props) => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="username"
      name="username"
      label={
        <span>
          Username&nbsp;
          <span style={{ color: "red" }}>*</span>
        </span>
      }
      rules={[
        {
          required: true,
          message: "required field",
        },
        {
          whitespace: true,
          message: "Invalid",
        },
        {
          pattern: /^[A-Za-z0-9]+$/,
          message: "Alphabets and number only",
        },
        {
          max: 32,
          message: "Max length must be 32 characters",
        },
      ]}
    >
      <Input />
    </Form.Item>
  );
};
export default ClientDetailId;
