import React, { useState } from "react";
import { message, Upload, Button, Form } from "antd";

const PartnerDetailsFile = (props) => {
  // -------------------------- VAR --------------------------

  const { file, setFile } = props;
  // -------------------------- STATE --------------------------

  // -------------------------- REDUX --------------------------

  // -------------------------- EFFECT --------------------------

  // -------------------------- FUNCTION --------------------------
  const beforeUpload = (file) => {
    const isPdf = file.type === "application/pdf";
    if (!isPdf) {
      message.error("You can only upload PDF file!");
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error("Image must smaller than 5MB!");
    }
    setFile(file);
    return isPdf && isLt5M;
  };
  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="pdfFile"
      name="pdfFile"
      label={
        <span>
          Partner Document
          <span style={{ color: "red" }}>*</span>
        </span>
      }
      rules={[
        {
          required: true,
          message: "required field",
        },
      ]}
    >
      <Upload
        name="pdfFile"
        accept=".pdf"
        showUploadList={true}
        maxCount={1}
        beforeUpload={beforeUpload}
      >
        <Button type="primary">Upload Business Partner Document</Button>
      </Upload>
    </Form.Item>
  );
};

export default PartnerDetailsFile;
