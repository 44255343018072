export const TYPES = {
  LOADING: "commissionHistory/LOADING",
  COMMISSION_HISTORY_LIST_SUCCESS:
    "commissionHistory/COMMISSION_HISTORY_LIST_SUCCESS",
  GET_PERMISSIONS_SUCCESS: "commissionHistory/GET_PERMISSIONS_SUCCESS",
  GET_COMMISSION_HISTORY_SUCCESS:
    "commissionHistory/GET_COMMISSION_HISTORY_SUCCESS",
  SELECT_COMMISSION_HISTORY_SUCCESS:
    "commissionHistory/SELECT_COMMISSION_HISTORY_SUCCESS",
  SELECT_COMMISSION_HISTORY_ADD:
    "commissionHistory/SELECT_COMMISSION_HISTORY_ADD",
  COMMISSION_HISTORY_ADD_SUCCESS:
    "commissionHistory/COMMISSION_HISTORY_ADD_SUCCESS",
};
