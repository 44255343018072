import { Form, Input } from "antd";
import React from "react";

const IpsDetailsOnusAccountId = (props) => {
  // -------------------------- VAR --------------------------
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="onusAccountId"
      name="onusAccountId"
      label=" "
      colon={false}
      rules={[
        {
          required: true,
          message: "Please input account ID",
        },
        {
          whitespace: true,
          message: "Invalid",
        },
        {
          pattern: /^[a-zA-Z0-9]+$/,
          message: "Alphabets and number only",
        },
        // {
        //   min: 9,
        //   message: "Min length must be at least 9 characters",
        // },
      ]}
    >
      <Input style={{ width: "100%" }} placeholder="Account ID" />
    </Form.Item>
  );
};
export default IpsDetailsOnusAccountId;
