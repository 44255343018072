import { Col, Form, Modal, message, Button, Divider, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Timestamp } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { MODEL_PARTNER } from "../../../model/partner";
import PartnerDetailCommissionFee from "./component/component/dialog_partner_list_commission_fee";
import PartnerDetailEmail from "./component/component/dialog_partner_list_email";
import PartnerDetailPhone from "./component/component/dialog_partner_list_phone";
import PartnerDetailFirstName from "./component/component/dialog_partner_list_firstname";
import PartnerDetailLastName from "./component/component/dialog_partner_list_lastname";
import {
  addPartner,
  selectPartner,
} from "../../../store/partner/partner.action";
import PartnerDetailsClient from "./component/component/dialog_partner_list_client";
import PartnerDetailCountry from "./component/component/dialog_partner_list_country";
import PartnerDetailSex from "./component/component/dialog_partner_list_sex";
import PartnerDetailOneOff from "./component/component/dialog_partner_list_one_off";
import PartnerDetailId from "./component/component/dialog_partner_list_id";
import PartnerDetailsFile from "./component/component/dialog_partner_list_file";
import PartnerDetailsCryptoWallet from "./component/component/dialog_partner_list_crypto_wallet";
import PartnerDetailsStartAt from "./component/component/dialog_partner_list_start_date";
import PartnerDetailsEndAt from "./component/component/dialog_partner_list_end_date";

const PartnerListCreate = (props) => {
  // -------------------------- VAR --------------------------
  const { open, setOpen } = props;
  const storage = getStorage();
  // -------------------------- STATE --------------------------
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [partnerInfo, setPartnerInfo] = useState("");
  const [file, setFile] = useState(null);
  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  const data = MODEL_PARTNER.model;
  // -------------------------- FUNCTION --------------------------
  const handleDateTime = (date) => {
    const jsDate = date.toDate();

    // Create a Firestore Timestamp from the JavaScript Date object
    return Timestamp.fromDate(jsDate);
  };

  const handleReset = () => {
    form.setFieldsValue(MODEL_PARTNER.model);
  };

  const handleCancel = () => {
    handleReset();
    setOpen(false);
  };

  const onFinish = async (values) => {
    console.log({ values });
    const uniqueFileName = `${Date.now()}_${file.name}`;
    const storageRef = ref(storage, uniqueFileName);
    let fileURL = null;
    try {
      await uploadBytes(storageRef, file);
      fileURL = await getDownloadURL(storageRef);
    } catch (error) {
      console.log(error);
    }
    if (fileURL) {
      const partner = {
        ...data,
        ...values,
        oneOff: values.oneOff || 0,
        pdfFile: fileURL,
        phone: values.phone || "",
        email: values.email || "",
        startAt: handleDateTime(values.startAt),
        endAt: handleDateTime(values.endAt),
      };
      console.log({ partner });
      setLoading(true);
      dispatch(
        addPartner({ partner }, (response) => {
          setLoading(false);
          handleReset();
          setOpen(false);
          if (response.status === 200) {
            const detail = response.data;
            dispatch(selectPartner({ detail }));
            message.success("Successfully added client");
          } else {
            const error = response.data;
            message.error(error);
          }
        })
      );
    }
  };

  const onFinishFailed = (error) => {
    console.log({ error });
  };

  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    if (open) {
      form.setFieldsValue(MODEL_PARTNER.model);
    }
  }, [open, data]);

  // -------------------------- RENDER --------------------------
  const renderPartnerId = () => {
    return <PartnerDetailId />;
  };

  const renderPartnerInfo = () => {
    return <PartnerDetailsClient setPartnerInfo={setPartnerInfo} />;
  };

  const renderEmail = () => {
    return <PartnerDetailEmail form={form} partnerInfo={partnerInfo} />;
  };

  const renderPhone = () => {
    return <PartnerDetailPhone form={form} partnerInfo={partnerInfo} />;
  };

  const renderFirstname = () => {
    return <PartnerDetailFirstName form={form} partnerInfo={partnerInfo} />;
  };

  const renderLastname = () => {
    return <PartnerDetailLastName form={form} partnerInfo={partnerInfo} />;
  };

  const renderGender = () => {
    return <PartnerDetailSex form={form} partnerInfo={partnerInfo} />;
  };

  const renderCountry = () => {
    return <PartnerDetailCountry form={form} partnerInfo={partnerInfo} />;
  };

  const renderCommissionFee = () => {
    return <PartnerDetailCommissionFee />;
  };

  const renderOneOff = () => {
    return <PartnerDetailOneOff />;
  };

  const renderCryptoWallet = () => {
    return <PartnerDetailsCryptoWallet />;
  };

  const renderUpload = () => {
    return <PartnerDetailsFile file={file} setFile={setFile} />;
  };

  const renderStartDate = () => {
    return <PartnerDetailsStartAt />;
  };

  const renderEndDate = () => {
    return <PartnerDetailsEndAt />;
  };

  // -------------------------- MAIN --------------------------
  const layout = {
    labelAlign: "left",
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      footer={null}
      confirmLoading={loading}
      width={1000}
    >
      <Col span={24} style={{ paddingTop: 30 }}>
        <Form
          requiredMark={false}
          size="small"
          autoComplete="off"
          form={form}
          {...layout}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={[10, 0]}>
            <Col xl={11} lg={11} md={24} xs={24}>
              {renderPartnerId()}
              {renderPartnerInfo()}
              {renderFirstname()}
              {renderLastname()}
              {renderGender()}
              {renderEmail()}
              {renderCountry()}
            </Col>
            <Col
              xl={2}
              lg={2}
              md={0}
              xs={0}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Divider type="vertical" style={{ height: "100%" }} />
            </Col>
            <Col xl={11} lg={11} md={24} xs={24}>
              {renderPhone()}
              {renderStartDate()}
              {renderEndDate()}
              {renderCommissionFee()}
              {renderCryptoWallet()}
              {renderUpload()}
            </Col>
          </Row>
          <div style={{ paddingTop: 20 }}>
            <Row gutter={[40, 0]}>
              <Col xl={6} lg={6} md={0} sm={0}></Col>
              <Col xl={6} lg={6} md={12} sm={12}>
                <Button
                  className="login-form-button"
                  style={{ width: "100%" }}
                  loading={loading}
                  size="medium"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Col>
              <Col xl={6} lg={6} md={12} sm={12}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  size="medium"
                  style={{ width: "100%" }}
                  loading={loading}
                >
                  Submit
                </Button>
              </Col>
              <Col xl={6} lg={6} md={0} sm={0}></Col>
            </Row>
          </div>
        </Form>
      </Col>
    </Modal>
  );
};
export default PartnerListCreate;
