import { TYPES } from "./partner.type";

const initState = {
  partner: null,
  partnerList: null,
  partnerListById: null,
  status: "add",
  allow: null,
  detail: null,
};

const partnerReducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case TYPES.PARTNER_LIST_SUCCESS:
      return {
        ...state,
        partnerList: payload,
      };
    case TYPES.PARTNER_LIST_SUCCESS_BY_ID:
      return {
        ...state,
        partnerListById: payload,
      };
    case TYPES.GET_PARTNER_SUCCESS:
      return {
        ...state,
        partner: payload,
      };
    case TYPES.SELECT_PARTNER_SUCCESS:
      return {
        ...state,
        detail: payload,
      };
    case TYPES.SELECT_PARTNER_ADD:
      return {
        ...state,
        detail: null,
      };
    case TYPES.PARTNER_ADD_SUCCESS:
      return {
        ...state,
        detail: null,
        status: "add",
      };
    case TYPES.GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        allow: payload,
      };
    default:
      return state;
  }
};

export default partnerReducer;
