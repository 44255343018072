import React from "react";
import { Form, Row, Col } from "antd";
import { useSelector } from "react-redux";
// component
import LoginFormEmail from "./login_form_email";
import LoginFormPassword from "./login_form_password";
import LoginFormRemember from "./login_form_remember";
import LoginFormButton from "./login_form_button";
// redux
import { loadingSelector } from "../../../store/login/login.selector";

const LoginForm = () => {
  // -------------------------- STATE --------------------------
  const [form] = Form.useForm();
  // -------------------------- REDUX --------------------------
  const loading = useSelector(loadingSelector);
  // -------------------------- FUNCTION --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Form name="normal_login" initialValues={{ remember: true }} autoComplete="off" form={form}>
      <Row gutter={[16, 0]}>
        <Col xl={24} lg={24} md={24} xs={24}>
          <LoginFormEmail disabled={loading} />
        </Col>
        <Col xl={24} lg={24} md={24} xs={24}>
          <LoginFormPassword disabled={loading} />
        </Col>
        <Col xl={24} lg={24} md={24} xs={24}>
          <LoginFormRemember disabled={loading} />
        </Col>
        <Col xl={24} lg={24} md={24} xs={24}>
          <LoginFormButton disabled={loading} />
        </Col>
      </Row>
    </Form>
  );
};

export default LoginForm;
