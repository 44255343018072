import React, { useEffect, useState } from "react";
import { Layout, Col, Row } from "antd";
import { useDispatch } from "react-redux";
import { URL } from "../00_Nav/url";
import LayoutHeader from "../00_Nav/layout/layout_header";
import LayoutBreadCrum from "../00_Nav/layout/layout_bread_crum";
import { setHighlight } from "../../store/nav/nav.action";
import PartnerDetail from "./component/partner_detail";
import PartnerList from "./component/partner_list";
import {
  snapPartnerList,
  unSnapPartnerList,
} from "../../store/partner/partner.action";
import { partnerListSelector } from "../../store/partner/partner.selector";
import { useSelector } from "react-redux";

const { Content } = Layout;

const PartnerScreen = () => {
  // -------------------------- VAR --------------------------
  const breadCrumb = [
    { title: "Home", url: null },
    { title: "Partner Management", url: URL.partnerManagement },
  ];
  // -------------------------- STATE --------------------------
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState([]);
  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  const partnerData = useSelector(partnerListSelector);
  // -------------------------- EFFECT --------------------------
  useEffect(() => {
    dispatch(snapPartnerList());
    return () => dispatch(unSnapPartnerList());
  }, [dispatch]);

  useEffect(() => {
    console.log({ partnerData });
    if (Array.isArray(partnerData) && partnerData.length > 0) {
      setLoading(false);
      setDetails(partnerData[0]);
    }
  }, [partnerData]);

  useEffect(() => {
    dispatch(setHighlight(["5"]));
  }, [dispatch]);
  // -------------------------- RENDER --------------------------
  const renderBody = () => {
    // console.log({details})
    return (
      <Row gutter={[16, 12]}>
        <Col xl={9} lg={9} md={12} xs={24}>
          {partnerData?.length > 0 ? (
            <PartnerList
              loading={loading}
              partnerData={partnerData}
              setDetails={setDetails}
              // ipsList={ipsList}
            />
          ) : (
            <PartnerList
              loading={false}
              partnerData={[]}
              setDetails={setDetails}
              // ipsList={ipsList}
            />
          )}
        </Col>
        <Col xl={15} lg={15} md={12} xs={24} className="box">
          <PartnerDetail details={details} />
        </Col>
      </Row>
    );
  };
  // -------------------------- MAIN --------------------------
  return (
    <Layout className="site-layout full">
      <LayoutHeader title="Partner Management" />
      <LayoutBreadCrum data={breadCrumb} />
      <Content className="content">{renderBody()}</Content>
    </Layout>
  );
};

export default PartnerScreen;
