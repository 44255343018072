import { Button, message } from "antd";
import { Timestamp } from "firebase/firestore";
import moment from "moment";
import numeral from "numeral";
import React, { useState } from "react";
import { MODEL_SHARED_PROFIT } from "../../../../../../model/shared_profit";
import {
  addProfitHistory,
  selectProfitHistory,
  updateProfitHistory,
} from "../../../../../../store/shared_profit_history/history.action";
import { useDispatch } from "react-redux";
import { updateWithdrawRequest } from "../../../../../../store/withdraw_request_history/history.action";

const ClientDetailRequestHistoryUpdate = (props) => {
  // -------------------------- VAR --------------------------
  const { record } = props;
  //   console.log("ipsList", ipsList);
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  // -------------------------- FUNCTION --------------------------
  const handleClick = () => {
    const update = {
      ...record,
      status: "Deposited",
    };

    dispatch(
      updateWithdrawRequest({ withdrawRequest: record, update }, (response) => {
        if (response.status === 200) {
          const detail = response.data;
          console.log({ detail });
        } else {
          const error = response.data;
          message.error(error);
        }
      })
    );
  };

  // -------------------------- EFFECT --------------------------

  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Button type="primary" onClick={handleClick} size="small">
      Mark as deposit
    </Button>
  );
};
export default ClientDetailRequestHistoryUpdate;
