import React from "react";
//outsource
import { Row, Col, Layout } from "antd";
import LayoutHeader from "../00_Nav/layout/layout_header";
import LayoutBreadCrum from "../00_Nav/layout/layout_bread_crum";
import { URL } from "../00_Nav/url";

import "./style.css";

const { Content } = Layout;
//components

const HomeScreen = () => {
  // -------------------------- VAR --------------------------
  const title = `Home Screen`;
  const breadCrumb = [
    { title: "Home", url: null },
    { title: title, url: URL.report },
  ];
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX -------------------------
  // -------------------------- FUNCTION ---------------------
  // -------------------------- EFFECT ------------------------
  // -------------------------- RENDER ------------------------
  const renderBody = () => {
    return <>This is body</>;
  };
  // -------------------------- MAIN ---------------------------

  // -------------------------- MAIN --------------------------
  return (
    <Layout className="site-layout full">
      <LayoutHeader title={title} />
      <LayoutBreadCrum data={breadCrumb} />
      <Content id="dev-management" className="content">
        {renderBody()}
      </Content>
    </Layout>
  );
};

export default HomeScreen;
