import React from "react";
import { AiOutlineHome } from "react-icons/ai";
import { BsChevronDown, BsPerson, BsPersonPlusFill } from "react-icons/bs";
import { PERMISSION } from "../../store/string";
import { SyncOutlined, UserOutlined } from "@ant-design/icons";
import { TbFileCertificate, TbUsers } from "react-icons/tb";
import { SettingOutlined } from "@ant-design/icons";

export const URL = {
  // base
  home: "/",
  // signin
  signin: "/signin",
  // portfolio
  portfolio: "/portfolio",
  //transaction
  operators: "/operators",
  //profile
  // profile: "/profile",
  // client
  clientManagement: "/client-management",
  //ips
  ipsManagement: "/ips-management",
  // partner
  partnerManagement: "/partner-management",
  //settings
  settings: "/settings",
};

// -------------------------- ITEM MAPPING --------------------------
export const newItems = () => {
  const items = {
    home: {
      title: "Home",
      url: URL.home,
      key: "1",
      hightlight: ["1"],
      require: [PERMISSION.OPERATOR],
      arrow: false,
      icon: <AiOutlineHome />,
    },
    // portfolio: {
    //   title: "Portfolio",
    //   url: URL.portfolio,
    //   key: "2",
    //   hightlight: ["2"],
    //   require: [PERMISSION.OPERATOR],
    //   arrow: false,
    //   icon: <TbChecklist />,
    // },
    // transaction: {
    //   title: "Topup/Withdraw",
    //   url: URL.transaction,
    //   key: "4",
    //   hightlight: ["4"],
    //   require: [PERMISSION.OPERATOR],
    //   arrow: false,
    //   icon: <SyncOutlined />,
    // },
    operators: {
      title: "Operator",
      url: URL.operators,
      key: "2",
      hightlight: ["2"],
      require: [PERMISSION.OPERATOR],
      arrow: false,
      icon: <UserOutlined />,
    },
    login: {
      title: "Login",
      url: URL.home,
      key: "1",
      hightlight: ["1"],
      require: [PERMISSION.OPERATOR],
      arrow: false,
    },
    clientManagement: {
      title: "Client Management",
      url: URL.clientManagement,
      key: "6",
      hightlight: ["6"],
      require: [PERMISSION.OPERATOR],
      arrow: false,
      icon: <BsPerson />,
    },
    ipsManagement: {
      title: "IPS Management",
      url: URL.ipsManagement,
      key: "9",
      hightlight: ["9"],
      require: [PERMISSION.OPERATOR],
      arrow: false,
      icon: <TbFileCertificate />,
    },
    partnerManagement: {
      title: "Partner Management",
      url: URL.partnerManagement,
      key: "8",
      hightlight: ["6"],
      require: [PERMISSION.OPERATOR],
      arrow: false,
      icon: <BsPersonPlusFill />,
    },
    settings: {
      title: "Settings",
      url: URL.settings,
      key: "22",
      hightlight: ["9"],
      require: [PERMISSION.OPERATOR],
      arrow: false,
      icon: <SettingOutlined />,
    },
  };
  return items;
};

// -------------------------- BREAD CRUMB --------------------------
// -------------------------- MENU --------------------------
export const setMenuItem = (props) => {
  if (props?.title) {
    const { title, url, key, arrow, icon, children } = props;
    const href = url;
    let label = href ? (
      <>
        <a href={href} target="_self" rel="noopener noreferrer">
          {title}
        </a>
        {arrow ? (
          <BsChevronDown
            style={{ fontSize: 10, marginLeft: 5 }}
            className="arrow-down"
          />
        ) : null}
      </>
    ) : (
      title
    );
    return { key, children, label, icon };
  }
  return null;
};

export const newMenus = (items) => {
  const menu = [
    setMenuItem({ ...items?.home }),
    setMenuItem({ ...items?.operators }),
    // setMenuItem({ ...items?.transaction }),
    // setMenuItem({ ...items?.profile }),
    setMenuItem({ ...items?.clientManagement }),
    setMenuItem({ ...items?.ipsManagement }),
    setMenuItem({ ...items?.partnerManagement }),
    //
    setMenuItem({ ...items?.settings }),
  ];
  return menu;
};

export const newAuthMenu = (items, logged) => {
  const loggedin = [setMenuItem({ ...items?.account })];
  const anonymous = [
    setMenuItem({ ...items?.register }),
    setMenuItem({ ...items?.login }),
  ];
  const menu = logged === null ? [] : logged === true ? loggedin : anonymous;
  return menu;
};

export const newDashboard = (items) => {
  const dashboard = [
    // items?.operators,
  ];
  return dashboard;
};

// export const newDashboard = (items) => {
//   const dashboard = [
//     items?.operators,
//     items?.traderManagement,
//     items?.clientManagement,
//     items?.controlPanelMgmt,
//     items?.botManagement,
//   ];
//   return dashboard;
// };
