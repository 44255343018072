import { getAuth, signOut } from "firebase/auth";
import {
  collection,
  where,
  getFirestore,
  getDocs,
  query,
  onSnapshot,
  setDoc,
  serverTimestamp,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { app } from "../../config/config";
import { TYPES } from "./history.type";
import { REF } from "../ref";
import { FULL_PERMISSION } from "../../store/string";
import { MASTER_EMAIL } from "../../config/config";

const firestore = getFirestore(app);
const auth = getAuth(app);

// -------------------------- Snap List  --------------------------
const subProfitHistory = [];
export const snapProfitHistory = () => (dispatch) => {
  const uid = auth?.currentUser?.uid;
  const email = auth?.currentUser?.email;
  const cRef = collection(firestore, REF.PROFIT_HISTORY);
  const dRef = doc(cRef, uid);
  if (email === MASTER_EMAIL) {
    const data = {
      firstName: "Master",
      lastName: "Account",
      permissions: FULL_PERMISSION,
      active: true,
    };
    getProfitHistoryDataSuccess(dispatch, data);
    // getPermissionsSuccess(dispatch, data);
    return;
  }
  const unSub = onSnapshot(dRef, (snapshot) => {
    if (snapshot) {
      const data = snapshot.data();
      if (data === undefined) {
        signOut(auth);
      } else {
        getProfitHistoryDataSuccess(dispatch, data);
        // getPermissionsSuccess(dispatch, data);
      }
    }
  });
  subProfitHistory.push(unSub);
};

export const unSnapProfitHistory = () => (dispatch) => {
  subProfitHistory.forEach((subscriber) => {
    subscriber();
  });
  subProfitHistory.length = 0;
};

export const resetProfitHistory = (callback) => (dispatch) => {
  getProfitHistoryDataSuccess(dispatch, null);
};
// -------------------------- Operation List  --------------------------
// Lấy dữ liệu profit history
export const firestoreGetProfitHistoryList = (callback) => (dispatch) => {
  const cRef = collection(firestore, REF.PROFIT_HISTORY);
  getDocs(cRef).then((snapshot) => {
    if (snapshot) {
      const data = snapshot.docs.map((doc) => doc.data());
      console.log("profit history", data);
      getProfitHistoryListSuccess(dispatch, data);
    }
    if (callback) {
      callback();
    }
  });
};

// Theo dõi dữ liệu profit history
const subList = [];
export const snapProfitHistoryList = (callback) => (dispatch) => {
  const cRef = collection(firestore, REF.PROFIT_HISTORY);
  // const c1 = where("role", "==", "operator");
  // const qRef = query(cRef, c1);
  const unSub = onSnapshot(cRef, (snapshot) => {
    if (snapshot) {
      const data = snapshot.docs.map((doc) => doc.data());
      getProfitHistoryListSuccess(dispatch, data);
      if (callback) {
        callback();
      }
    }
  });
  subList.push(unSub);
};

export const unSnapProfitHistoryList = () => (dispatch) => {
  subList.forEach((subscriber) => {
    subscriber();
  });
  subList.length = 0;
};

// -------------------------- Operation Add  --------------------------

export const addProfitHistory =
  ({ profitHistory }, callback) =>
  (dispatch) => {
    console.log({ profitHistory });
    // const email = profitHistory?.email;
    // const clientId = profitHistory?.clientId;
    const cRef = collection(firestore, REF.PROFIT_HISTORY);
    // const c1 = where("role", "==", "operator");
    // const c2 = where("username", "==", username);
    // const qRef = query(cRef, c2);
    getDocs(cRef)
      .then((snapshot) => {
        if (snapshot) {
          const profitHistoryList = snapshot.docs.map((doc) => doc.data());
          console.log({ profitHistoryList });
          // if (profitHistoryList.length === 0) {
          const id = doc(cRef).id;
          const data = {
            ...profitHistory,
            id,
            createdAt: serverTimestamp(),
            // role: "operator",
          };

          const dRef = doc(cRef, id);
          setDoc(dRef, data, { merge: true }).then(() => {
            if (callback) {
              callback({
                status: 200,
                data,
              });
            }
          });
          // } else {
          //   if (callback) {
          //     callback({
          //       status: 500,
          //       data: "Duplicate id",
          //     });
          //   }
          // }
        }
      })
      .catch((error) => {
        if (callback) {
          callback({
            status: 500,
            data: error.code,
          });
        }
      });
  };

// -------------------------- Operation Update  --------------------------
// Update profitHistory
export const updateProfitHistory =
  ({ profitHistory, update }, callback) =>
  (dispatch) => {
    const cRef = collection(firestore, REF.PROFIT_HISTORY);
    const id = profitHistory?.id;
    // const email = update.email;
    // const c1 = where("role", "==", "operator");
    const c2 = where("id", "==", profitHistory?.id);
    const qRef = query(cRef, c2);
    getDocs(qRef)
      .then((snapshot) => {
        if (snapshot) {
          const profitHistoryList = snapshot.docs
            .map((doc) => doc.data())
            .filter((item) => item.id !== id);
          if (profitHistoryList.length === 0) {
            const dRef = doc(cRef, id);
            updateDoc(dRef, update).then(() => {
              selectProfitHistorySuccess(dispatch, update);
              if (callback) {
                callback({
                  status: 200,
                  data: update,
                });
              }
            });
          } else {
            if (callback) {
              callback({
                status: 500,
                data: "Duplicate id",
              });
            }
          }
        }
      })
      .catch((error) => {
        if (callback) {
          callback({
            status: 500,
            data: error.code,
          });
        }
      });
  };

// -------------------------- Operation Delete  --------------------------

export const deleteProfitHistory =
  ({ profitHistory }, callback) =>
  (dispatch) => {
    console.log({ profitHistory });
    const cRef = collection(firestore, REF.PROFIT_HISTORY);
    const dRef = doc(cRef, profitHistory.id);
    deleteDoc(dRef).then(() => {
      if (callback) {
        callback();
      }
    });
  };

// -------------------------- Update info --------------------------

export const firebaseUpdateLoginTime = () => (dispatch) => {
  const cRef = collection(firestore, REF.PROFIT_HISTORY);
  const email = auth.currentUser?.email;
  const uid = auth.currentUser?.uid;
  if (email === MASTER_EMAIL) {
    return;
  } else {
    const dRef = doc(cRef, uid);
    const update = {
      onlineAt: serverTimestamp(),
    };
    updateDoc(dRef, update);
  }
};

// -------------------------- Operation Select  --------------------------

export const selectProfitHistory =
  ({ detail }, callback) =>
  (dispatch) => {
    console.log("detail action", detail);
    selectProfitHistorySuccess(dispatch, detail);
    if (callback) {
      callback();
    }
  };

export const selectAddProfitHistory = (callback) => (dispatch) => {
  selectAddSuccess(dispatch);
  if (callback) {
    callback();
  }
};

export const successAddProfitHistory = (callback) => (dispatch) => {
  profitHistoryAddSuccess(dispatch);
  if (callback) {
    callback();
  }
};

export const successDeleteProfitHistory = (callback) => (dispatch) => {
  profitHistoryAddSuccess(dispatch);
  if (callback) {
    callback();
  }
};

// -------------------------- Dispatch  --------------------------

const getProfitHistoryDataSuccess = (dispatch, profitHistory) => {
  dispatch({
    type: TYPES.GET_PROFIT_HISTORY_SUCCESS,
    payload: profitHistory,
  });
};

const getProfitHistoryListSuccess = (dispatch, data) => {
  dispatch({
    type: TYPES.PROFIT_HISTORY_LIST_SUCCESS,
    payload: data,
  });
};

const selectProfitHistorySuccess = (dispatch, data) => {
  dispatch({
    type: TYPES.SELECT_PROFIT_HISTORY_SUCCESS,
    payload: data,
  });
};

const profitHistoryAddSuccess = (dispatch) => {
  dispatch({
    type: TYPES.PROFIT_HISTORY_ADD_SUCCESS,
  });
};

const selectAddSuccess = (dispatch) => {
  dispatch({
    type: TYPES.SELECT_PROFIT_HISTORY_ADD,
  });
};
