import { Form, Input, Checkbox, Switch, Row, Col } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

const PartnerDetailOneOff = (props) => {
  // -------------------------- VAR --------------------------
  //   const { form, partnerInfo } = props;
  // -------------------------- STATE --------------------------
  const [disabled, setDisabled] = useState(true);
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  const handleCheck = () => {
    setDisabled(!disabled);
  };
  // -------------------------- EFFECT --------------------------
  //   useEffect(() => {
  //     form.setFieldValue("firstName", partnerInfo?.firstName);
  //   }, [partnerInfo]);
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="oneOff"
      name="oneOff"
      label="One Off"
      rules={[
        {
          required: false,
          message: "required field",
        },
        {
          whitespace: true,
          message: "Invalid",
        },
        {
          pattern: /^[0-9]+$/,
          message: "",
        },
      ]}
    >
      <Row gutter={[24, 0]}>
        <Col xl={2} lg={2} md={8} xs={8}>
          <Checkbox onChange={handleCheck} />
        </Col>
        <Col xl={22} lg={22} md={16} xs={16}>
          <Input
            id="oneOff"
            name="oneOff"
            placeholder="One Off"
            disabled={disabled}
            addonAfter="%"
            // addonBefore={
            //   <span style={{ marginRight: 20 }}>
            //     <Switch style={{ width: "100%" }} onChange={handleCheck}></Switch>
            //   </span>
            // }
          />
        </Col>
      </Row>
    </Form.Item>
  );
};
export default PartnerDetailOneOff;
