import React from "react";
import { Form, Radio } from "antd";
const ClientDetailSex = (props) => {
  // -------------------------- VAR --------------------------
  const GENDER = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  const genderOptions = GENDER;
  // -------------------------- FUNCTION --------------------------

  // -------------------------- EFFECT --------------------------

  // -------------------------- RENDER --------------------------

  // -------------------------- MAIN --------------------------
  return (
    <Form.Item
      id="gender"
      name="gender"
      label={
        <span>
          Gender&nbsp;
          <span style={{ color: "red" }}>*</span>
        </span>
      }
      rules={[
        {
          required: true,
          message: "required field",
        },
      ]}
    >
      <Radio.Group
        options={genderOptions}
        // allowClear
        // showSearch
        // optionFilterProp="label"
      />
    </Form.Item>
  );
};
export default ClientDetailSex;
