import { TYPES } from "./nav.types";

// -------------------------- Snapshot --------------------------
export const toggleExpanse = () => (dispatch) => {
  toggleExpanseSuccess(dispatch);
};

export const toggleCollapse = () => (dispatch) => {
  toggleCollapseSuccess(dispatch);
};

export const setHighlight = (keys) => (dispatch) => {
  setHightLightSuccess(dispatch, keys);
};

// -------------------------- Dispatch --------------------------
const toggleExpanseSuccess = (dispatch) => {
  dispatch({
    type: TYPES.EXPANSE,
  });
};

const toggleCollapseSuccess = (dispatch) => {
  dispatch({
    type: TYPES.COLLAPSE,
  });
};

const setHightLightSuccess = (dispatch, data) => {
  dispatch({
    type: TYPES.HIGHTLIGHT,
    payload: data,
  });
};
