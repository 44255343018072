export const MODEL_OPERATOR = {
  model: {
    avatar: "",
    active: true,
    firstName: "",
    lastName: "",
    password: "",
    group: null,
    phone: "",
    email: "",
    address: "",
    requestType: "",
  },
  inputRegex: {
    password: /^.{0,16}$/,
    phone: /^[0-9.+\-,()\s]{0,15}$/,
    firstName: /^([^0-9]*){0,32}$/,
    lastName: /^([^0-9]*){0,32}$/,
  },
};
