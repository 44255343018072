import { Select } from "antd";
import React from "react";

const IpsTopSelect = (props) => {
  // -------------------------- VAR --------------------------
  const { option, setOption, options } = props;
  // -------------------------- STATE --------------------------
  // -------------------------- REDUX --------------------------
  // -------------------------- FUNCTION --------------------------
  const handleSelect = (value) => {
    console.log(value);
    setOption(value);
  };
  // -------------------------- EFFECT --------------------------
  // -------------------------- RENDER --------------------------
  // -------------------------- MAIN --------------------------
  return (
    <Select
      value={option}
      options={options}
      onSelect={handleSelect}
      style={{ width: "100%" }}
    />
  );
};
export default IpsTopSelect;
