import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Form,
  Typography,
  Button,
  message,
  Modal,
  Divider,
} from "antd";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useDispatch } from "react-redux";
import { MODEL_IPS } from "../../../model/ips";
import { addIps, selectIps } from "../../../store/ips/ips.action";
import IpsDetailsBankAccount from "./component/component/dialog_ips_details_bank_account";
import IpsDetailsBankName from "./component/component/dialog_ips_details_bank_name";
import IpsDetailsCurrency from "./component/component/dialog_ips_details_currency";
import IpsDetailsDuration from "./component/component/dialog_ips_details_duration";
import IpsDetailsFund from "./component/component/dialog_ips_details_initial_fund";
import IpsDetailsInterest from "./component/component/dialog_ips_details_interest";
import IpsDetailsType from "./component/component/dialog_ips_details_ips_details_type";
import IpsDetailsNetwork from "./component/component/dialog_ips_details_network";
import IpsDetailsNumber from "./component/component/dialog_ips_details_number";
import IpsDetailsReceiveInterest from "./component/component/dialog_ips_details_receive_interest";
import IpsDetailsShareProfit from "./component/component/dialog_ips_details_share_profit";
import IpsDetailsstartAt from "./component/component/dialog_ips_details_start_date";
import IpsDetailsWalletAddress from "./component/component/dialog_ips_details_wallet_address";
import IpsDetailsBankAccountName from "./component/component/dialog_ips_details_bank_account_name";
import { Timestamp } from "firebase/firestore";
import IpsDetailsOnusPhone from "./component/component/dialog_ips_details_onus_phone";
import IpsDetailsOnusEmail from "./component/component/dialog_ips_details_onus_email";
import IpsDetailsClient from "./component/component/dialog_ips_details_client";
import IpsDetailNotes from "./component/component/dialog_ips_details_notes";
import IpsDetailsOnusAccountType from "./component/component/dialog_ips_details_onus_account_type";
import { useSelector } from "react-redux";
import { clientListSelector } from "../../../store/client/client.selector";
import { MODEL_CLIENT } from "../../../model/client";
import { updateClient } from "../../../store/client/client.action";
import {
  addProfitHistory,
  selectProfitHistory,
} from "../../../store/shared_profit_history/history.action";
import moment from "moment";
import IpsDetailsPartnerRef from "./component/component/dialog_ips_details_partner_ref";
import IpsDetailsFile from "./component/component/dialog_ips_details_file";
import IpsDetailOneOff from "./component/component/dialog_ips_details_one_off";
import IpsDetailsOnusAccountId from "./component/component/dialog_ips_details_onus_account_id";
const { Title } = Typography;

const IpsDetailCreate = (props) => {
  // -------------------------- VAR --------------------------
  const { open, setOpen } = props;
  const storage = getStorage();
  // ------------------------- STATE --------------------------
  const [form] = Form.useForm();
  const [receivedBy, setReceiveBy] = useState("wallet");
  const [onusAccountType, setOnusAccountType] = useState("onusPhone");
  const [interestRate, setInterestRate] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [data, setData] = useState(MODEL_IPS.model);
  const [clientData, setClientData] = useState(MODEL_CLIENT.model);
  const [clientId, setClientId] = useState(null);
  const [clientName, setClientName] = useState(null);
  const [partnerId, setPartnerId] = useState(null);
  const [partnerName, setPartnerName] = useState(null);
  const [file, setFile] = useState(null);
  const [timeValue, setTimeValue] = useState("week");
  const [currency, setCurrency] = useState("usd");
  const [fundCurrency, setFundCurrency] = useState("usd");
  // ------------------------- REDUX --------------------------
  const dispatch = useDispatch();
  const allClientData = useSelector(clientListSelector);

  // ------------------------ EFFECT --------------------------
  useEffect(() => {
    form.setFieldValue("interest", interestRate);
  }, [interestRate]);

  useEffect(() => {
    setClientId(form.getFieldValue("clientId"));
    const clientRecord = allClientData?.find(
      (item) => item.username === form.getFieldValue("clientId")
    );
    setClientData(clientRecord);
  }, [clientId]);

  useEffect(() => {
    form.setFieldValue("partnerId", partnerId);
    // console.log({ partnerId });
    // const clientRecord = allClientData?.find(
    //   (item) => item.username === form.getFieldValue("partnerId")
    // );
    // setClientData(clientRecord);
  }, [partnerId]);

  useEffect(() => {
    form.setFieldsValue(MODEL_IPS.model);
    console.log({ open });
  }, [open]);
  // ----------------------- FUNCTION --------------------------
  const handleDateTime = (date) => {
    const jsDate = date.toDate();

    // Create a Firestore Timestamp from the JavaScript Date object
    return Timestamp.fromDate(jsDate);
  };

  //  ---- temporary hide ----

  // const getNextSaturday = (inputTimestamp) => {
  //   const givenDate = moment.unix(inputTimestamp?.seconds);
  //   const daysUntilSaturday = 6 - givenDate.day();
  //   if (daysUntilSaturday <= 0) {
  //     daysUntilSaturday += 7;
  //   }
  //   const nextSaturday = givenDate
  //     .clone()
  //     .add(daysUntilSaturday, "days")
  //     .startOf("day");

  //   return nextSaturday;
  // };

  const getNextSaturday = () => {
    const now = moment();
    let daysUntilSaturday = 6 - now.day();
    if (daysUntilSaturday <= 0) {
      daysUntilSaturday = 7;
    }
    const nextSaturday = now
      .clone()
      .add(daysUntilSaturday, "days")
      .startOf("day");

    return nextSaturday;
  };

  const handleAmount = (ipsDetail) => {
    const interest = ipsDetail?.interest;
    const fund = ipsDetail?.fund;
    const amount = (interest * fund) / 100;
    let interestPerWeek = 0;
    switch (ipsDetail?.interestRatePer) {
      case "week":
        interestPerWeek = amount;
        break;
      case "month":
        interestPerWeek = amount / 4;
        break;
      default:
        interestPerWeek = amount / 52;
        break;
    }
    return interestPerWeek;
  };

  // const handleChange = ({ id, value }) => {
  //   setData({
  //     ...data,
  //     [id]: value,
  //   });
  // };

  const onFinish = async (values) => {
    let fileURL = null;
    if (file) {
      const uniqueFileName = `${Date.now()}_${file.name}`;
      const storageRef = ref(storage, uniqueFileName);

      try {
        await uploadBytes(storageRef, file);
        fileURL = await getDownloadURL(storageRef);
      } catch (error) {
        console.log(error);
      }
    }

    // if (fileURL) {
    const newClientName = clientName?.split(" - ");
    const newPartnerName = partnerName?.split(" - ");
    console.log({ newPartnerName, partnerName });
    const ips = {
      clientId: values.clientId,
      clientName: newClientName?.[0],
      partner: {
        partnerId: values.partnerId || "",
        partnerName: newPartnerName?.[0] || "",
      },
      duration: values.duration,
      interest: values.interest || 0,
      interestRatePer: timeValue,
      ipsId: values.ipsId,
      pdfFile: fileURL || "",
      startAt: handleDateTime(values.startAt),
      fund: values.fund,
      fundCurrency: fundCurrency,
      shareProfit: values.profit,
      method: values.receiveInterest,
      type: values.type,
      oneOff: values.oneOff || "",
      // interest: interestRate || values.interest,
      wallet: {
        network: values.network || "",
        walletAddress: values.walletAddress || "",
      },
      bank: {
        accountId: values.accountId || "",
        accountName: values.accountName || "",
        bankName: values.bankName || "",
        currency: values.currency || "",
      },
      onusAccountType: values.onusAccountType || "",
      onus: {
        email: values.onusMail || "",
        phone: values.onusPhone || "",
      },
      notes: values.notes || "",
    };

    const newIps = clientData?.totalIps + 1;
    const newFund =
      parseFloat(clientData?.totalFund?.split(" ")[0]) + values.fund;
    // const fundCurrency =
    //   values.receiveInterest === "bank" ? values.currency : "USDT";
    const clientUpdate = {
      ...clientData,
      totalIps: newIps,
      totalFund: newFund + " " + fundCurrency,
    };
    // console.log({ clientUpdate });

    setLoading(true);
    dispatch(
      addIps({ ips }, (response) => {
        setLoading(false);
        handleReset();
        setOpen(false);
        if (response.status === 200) {
          const detail = response.data;
          // console.log({ detail });
          dispatch(selectIps({ detail }));
          message.success("Successfully added ips");

          const transaction = {
            clientId: detail?.clientId,
            ipsId: detail?.id,
            datetime: handleDateTime(getNextSaturday()),
            amount: handleAmount(detail),
            backupId: detail?.ipsId,
            status: "undeposited",
          };
          dispatch(
            addProfitHistory({ profitHistory: transaction }, (response) => {
              if (response.status === 200) {
                const detailProfit = response.data;
                dispatch(selectProfitHistory({ detailProfit }));
                message.success("Successfully added transaction");
              } else {
                const error = response.data;
                message.error(error);
              }
            })
          );
          dispatch(
            updateClient(
              { client: clientData, update: clientUpdate },
              (response) => {
                setLoading(false);
                // handleReset();
                setOpen(false);
                if (response.status === 200) {
                  message.success("Successfully update ips in client");
                  // console.log(response.data);
                } else {
                  const error = response.data;
                  // message.error(error);
                  console.error(error);
                }
              }
            )
          );
        } else {
          const error = response.data;
          // message.error(error);
          console.error(error);
        }
      })
    );
    // }
  };

  const onFinishFailed = (error) => {
    console.log({ error });
  };

  const handleReset = () => {
    form.setFieldsValue(MODEL_IPS.model);
    form.setFieldValue("interest", "");
    setInterestRate("");
    setClientId("");
    setPartnerId("");
    setFile("");
    setFundCurrency("usd");
  };

  const handleCancel = () => {
    handleReset();
    setOpen(false);
  };
  // ------------------------ RENDER --------------------------
  const renderIpsId = () => {
    return <IpsDetailsNumber />;
  };

  const renderInitialFund = () => {
    return (
      <IpsDetailsFund
        fundCurrency={fundCurrency}
        setFundCurrency={setFundCurrency}
      />
    );
  };

  const renderType = () => {
    return <IpsDetailsType />;
  };

  const renderstartAt = () => {
    return <IpsDetailsstartAt />;
  };

  const renderDuration = () => {
    return <IpsDetailsDuration />;
  };

  const renderShareProfit = () => {
    return <IpsDetailsShareProfit />;
  };

  const renderInterest = () => {
    return (
      <IpsDetailsInterest
        form={form}
        setInterestRate={setInterestRate}
        interestRate={interestRate}
        timeValue={timeValue}
        setTimeValue={setTimeValue}
      />
    );
  };

  const renderReceiveInterest = () => {
    return <IpsDetailsReceiveInterest setReceiveBy={setReceiveBy} />;
  };

  const renderNetwork = () => {
    return <IpsDetailsNetwork />;
  };

  const renderWalletAddress = () => {
    return <IpsDetailsWalletAddress />;
  };

  const renderBankName = () => {
    return <IpsDetailsBankName />;
  };

  const renderBankAccountId = () => {
    return <IpsDetailsBankAccount />;
  };

  const renderBankAccountName = () => {
    return <IpsDetailsBankAccountName />;
  };

  const renderCurrency = () => {
    return <IpsDetailsCurrency setCurrency={setCurrency} />;
  };

  const renderOnusAccountType = () => {
    return (
      <IpsDetailsOnusAccountType setOnusAccountType={setOnusAccountType} />
    );
  };

  const renderOnusPhone = () => {
    return <IpsDetailsOnusPhone />;
  };

  const renderOnusEmail = () => {
    return <IpsDetailsOnusEmail />;
  };

  const renderOnusAccountId = () => {
    return <IpsDetailsOnusAccountId />;
  };

  const renderClient = () => {
    return (
      <IpsDetailsClient
        setClientId={setClientId}
        setClientName={setClientName}
        form={form}
      />
    );
  };

  const renderPartnerRef = () => {
    return (
      <IpsDetailsPartnerRef
        form={form}
        setPartnerId={setPartnerId}
        setPartnerName={setPartnerName}
      />
    );
  };

  const renderOneOff = () => {
    return <IpsDetailOneOff form={form} />;
  };

  const renderNotes = () => {
    return <IpsDetailNotes />;
  };

  const renderIpsFile = () => {
    return <IpsDetailsFile file={file} setFile={setFile} />;
  };
  // ------------------------- MAIN --------------------------
  const layout = {
    labelAlign: "left",
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const initialValues = {
    ...MODEL_IPS.model,
  };

  return (
    <Modal
      width={1000}
      open={open}
      onCancel={handleCancel}
      footer={null}
      confirmLoading={loading}
    >
      <Col span={24} style={{ paddingTop: 30 }}>
        <Form
          requiredMark={false}
          size="small"
          autoComplete="off"
          form={form}
          initialValues={initialValues}
          {...layout}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={[10, 0]}>
            <Col xl={11} lg={11} md={24} xs={24}>
              {renderIpsId()}
              {renderPartnerRef()}
              {renderOneOff()}
              {renderClient()}
              {renderInitialFund()}
              {renderType()}
              {renderInterest()}
              {renderstartAt()}
              {receivedBy === "bank" && renderDuration()}
            </Col>
            <Col
              xl={2}
              lg={2}
              md={0}
              xs={0}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Divider type="vertical" style={{ height: "100%" }} />
            </Col>
            <Col xl={11} lg={11} md={24} xs={24}>
              {receivedBy !== "bank" && renderDuration()}
              {renderShareProfit()}
              {renderReceiveInterest()}
              {receivedBy === "wallet" && renderNetwork()}
              {receivedBy === "wallet" && renderWalletAddress()}
              {receivedBy === "bank" && renderBankName()}
              {receivedBy === "bank" && renderBankAccountId()}
              {receivedBy === "bank" && renderBankAccountName()}
              {receivedBy === "bank" && renderCurrency()}
              {receivedBy === "onus" && renderOnusAccountType()}
              {receivedBy === "onus" &&
                onusAccountType === "onusPhone" &&
                renderOnusPhone()}
              {receivedBy === "onus" &&
                onusAccountType === "onusMail" &&
                renderOnusEmail()}
              {receivedBy === "onus" &&
                onusAccountType === "onusAccountId" &&
                renderOnusAccountId()}
              {renderNotes()}
              {renderIpsFile()}
            </Col>
          </Row>
          <div style={{ paddingTop: 20 }}>
            <Row gutter={[40, 0]}>
              <Col xl={6} lg={6} md={0} sm={0}></Col>
              <Col xl={6} lg={6} md={12} sm={12}>
                <Button
                  className="login-form-button"
                  style={{ width: "100%" }}
                  loading={loading}
                  size="medium"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Col>
              <Col xl={6} lg={6} md={12} sm={12}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  size="medium"
                  style={{ width: "100%" }}
                  loading={loading}
                >
                  Submit
                </Button>
              </Col>
              <Col xl={6} lg={6} md={0} sm={0}></Col>
            </Row>
          </div>
        </Form>
      </Col>
    </Modal>
  );
};

export default IpsDetailCreate;
